import { useEffect, useState } from 'react';
import toast from 'react-hot-toast';

import { BaseInput } from '../../atoms/BaseInput';
import { BaseLabel } from '../../atoms/BaseLabel';
import { BaseSelect } from '../../atoms/BaseSelect';
import { SubmitButton } from '../../atoms/SubmitButton';
import { AvatarHandler } from '../AvatarHandler';

import { api } from '../../../services/api';

import { IOptions } from '../../../interfaces/IOptions';
import { AddVehicleTypeFormProps } from './interfaces';
import {
  AddVehicleTypeFormContainer,
  CapacityWrapper,
  FormDividers,
  FormWrapper,
  TypeMeter,
  WarningText,
} from './styles';

export const AddVehicleTypeForm = ({
  register,
  onSubmit,
  isSubmitting,
  errors,
}: AddVehicleTypeFormProps) => {
  const [bodyWorkTypesOptions, setBodyWorkTypesOptions] = useState(
    [] as IOptions[],
  );
  const [vehicleClassesOptions, setVehicleClassesOptions] = useState(
    [] as IOptions[],
  );
  const [vehicleWeightOptions, setVehicleWeightOptions] = useState(
    [] as IOptions[],
  );
  const [vehicleVolumeOptions, setVehicleVolumeOptions] = useState(
    [] as IOptions[],
  );

  const ensureBodyWorkOptions = async () => {
    try {
      const response = await api.get('/bodywork-types');
      const bodyWorks = response?.data;

      setBodyWorkTypesOptions(() => {
        const newState = bodyWorks?.map((bodyworkType: any) => ({
          value: bodyworkType.id,
          label: bodyworkType.name,
        }));

        newState.unshift({
          label: 'Selecione',
          value: '',
        });

        return newState;
      });
    } catch (error: any) {
      toast.error(
        error?.response?.data?.message || error?.response?.messageString,
      );
    }
  };

  const ensureVehicleClassOptions = async () => {
    try {
      const response = await api.get('/vehicle-classes');
      const vehicleClasses = response?.data?.results;

      setVehicleClassesOptions(() => {
        const newState = vehicleClasses?.map((vehicleClass: any) => ({
          value: vehicleClass.id,
          label: vehicleClass.name,
        }));

        newState.unshift({
          label: 'Selecione',
          value: '',
        });

        return newState;
      });
    } catch (error: any) {
      toast.error(
        error?.response?.data?.message || error?.response?.messageString,
      );
    }
  };

  const ensureVehicleWeightOptions = async () => {
    try {
      const response = await api.get('/vehicle-weights');
      const vehicleWeights = response?.data?.results;

      setVehicleWeightOptions(() => {
        const newState = vehicleWeights?.map((vehicleWeight: any) => ({
          value: vehicleWeight.id,
          label: vehicleWeight.name,
        }));
        newState.unshift({
          label: 'Selecione',
          value: '',
        });

        return newState;
      });
    } catch (error: any) {
      toast.error(
        error?.response?.data?.message || error?.response?.messageString,
      );
    }
  };

  const ensureVehicleVolumeOptions = async () => {
    try {
      const response = await api.get('/vehicle-volumes');
      const vehicleVolumes = response?.data?.results;

      setVehicleVolumeOptions(() => {
        const newState = vehicleVolumes?.map((vehicleVolume: any) => ({
          value: vehicleVolume.id,
          label: vehicleVolume.name,
        }));
        newState.unshift({
          label: 'Selecione',
          value: '',
        });
        return newState;
      });
    } catch (error: any) {
      toast.error(
        error?.response?.data?.message || error?.response?.messageString,
      );
    }
  };

  useEffect(() => {
    ensureBodyWorkOptions();
    ensureVehicleClassOptions();
    ensureVehicleWeightOptions();
    ensureVehicleVolumeOptions();
  }, []);

  return (
    <AddVehicleTypeFormContainer onSubmit={onSubmit}>
      <FormWrapper>
        <FormDividers>
          {/* <BaseLabel className="add-vehicle__main-label">
            Tipo do Veículo
          </BaseLabel>
          <BaseSelect
            options={vehicleClassesOptions}
            {...register('vehicle_class_id', { required: true })}
          />
          <WarningText>{errors?.vehicle_class_id?.message}</WarningText> */}
          <BaseLabel className="add-vehicle__main-label">
            Tipo do Veículo
          </BaseLabel>
          <BaseInput
            type="text"
            error={!!errors?.name}
            {...register('name', { required: true })}
          />
          <WarningText>{errors?.name?.message}</WarningText>
          <BaseLabel className="add-vehicle__main-label">
            Tipo de carroceria
          </BaseLabel>
          <BaseSelect
            options={bodyWorkTypesOptions}
            {...register('bodywork_type_id')}
          />
          <WarningText>{errors?.bodywork_type_id?.message}</WarningText>
          <BaseLabel className="add-vehicle__main-label">
            Total de Eixos
          </BaseLabel>
          <BaseInput
            type="number"
            max={99}
            min={1}
            className="add-vehicle__half-input"
            error={!!errors?.axis_number}
            {...register('axis_number', { required: true })}
          />
          <WarningText>{errors?.axis_number?.message}</WarningText>
          <BaseLabel className="add-vehicle__main-label">
            Capacidade de Carga
          </BaseLabel>
          <CapacityWrapper>
            {/* <BaseInput
              type="number"
              step="0.01"
              max={9999}
              min={0}
              className="add-vehicle__half-input"
              error={!!errors?.cargo_weight}
              {...register('cargo_weight', { required: true })}
            />
            <TypeMeter>
              ton
            </TypeMeter> */}
            <BaseSelect
              options={vehicleWeightOptions}
              {...register('cargo_weight_id')}
            />
          </CapacityWrapper>
          <CapacityWrapper>
            {/* <BaseInput
              type="number"
              step="0.01"
              max={9999}
              min={0}
              className="add-vehicle__half-input"
              error={!!errors?.cargo_volume}
              {...register('cargo_volume', { required: true })}
            />
            <TypeMeter>
              m³
            </TypeMeter> */}
            <BaseSelect
              className="capacity-wrapper__margin-left"
              options={vehicleVolumeOptions}
              {...register('cargo_volume_id')}
            />
          </CapacityWrapper>
          <WarningText>
            {errors?.cargo_weight_id?.message}{' '}
            {errors?.cargo_volume_id?.message}
          </WarningText>
          <BaseLabel className="add-vehicle__main-label">
            Porcentagem do Transportador
          </BaseLabel>
          <BaseInput
            type="number"
            max={100}
            min={0}
            className="add-vehicle__half-input"
            error={!!errors?.transporter_percentage}
            {...register('transporter_percentage', { required: true })}
          />
          <TypeMeter>%</TypeMeter>
          <WarningText>{errors?.percentage_per_cte?.message}</WarningText>
        </FormDividers>
        <FormDividers>
          <BaseLabel className="add-vehicle__main-label">
            Porcentagem Vagoo por CTE Emitida
          </BaseLabel>
          <BaseInput
            type="number"
            max={100}
            min={0}
            className="add-vehicle__half-input"
            error={!!errors?.percentage_per_cte}
            {...register('percentage_per_cte', { required: true })}
          />
          <TypeMeter>%</TypeMeter>
          <WarningText>{errors?.percentage_per_cte?.message}</WarningText>
          {/* <BaseLabel className="add-vehicle__main-label">
            Tempo Mínimo de Cancelamento
          </BaseLabel>
          <BaseInput
            type="number"
            step="0.01"
            max={9999}
            min={0}
            className="add-vehicle__half-input"
            error={!!errors?.minimal_time_for_cancelation}
            {...register('minimal_time_for_cancelation', { required: true })}
          />
          <TypeMeter>min</TypeMeter>
          <WarningText>
            {errors?.minimal_time_for_cancelation?.message}
          </WarningText> */}
          <BaseLabel className="add-vehicle__main-label">
            Porcentagem Vagoo por Cancelamento do Embarcador
          </BaseLabel>
          <BaseInput
            type="number"
            step="0.01"
            max={100}
            min={0}
            className="add-vehicle__half-input"
            error={!!errors?.shipper_cancelation_fee}
            {...register('shipper_cancelation_fee', { required: true })}
          />
          <TypeMeter>%</TypeMeter>
          <WarningText>{errors?.shipper_cancelation_fee?.message}</WarningText>
          <BaseLabel className="add-vehicle__main-label">
            Porcentagem Vagoo por Cancelamento do Transportador
          </BaseLabel>
          <BaseInput
            type="number"
            step="0.01"
            max={100}
            min={0}
            className="add-vehicle__half-input"
            error={!!errors?.transporter_cancelation_fee}
            {...register('transporter_cancelation_fee', { required: true })}
          />
          <TypeMeter>%</TypeMeter>
          <WarningText>
            {errors?.transporter_cancelation_fee?.message}
          </WarningText>
          {/* <BaseLabel className="add-vehicle__main-label">
            Raio Mínimo
          </BaseLabel>
          <BaseInput
            type="number"
            step="0.01"
            max={9999}
            min={0}
            className="add-vehicle__half-input"
            error={!!errors?.minimal_radius}
            {...register('minimal_radius', { required: true })}
          />
          <TypeMeter>
            Km
          </TypeMeter>
          <WarningText>
            {errors?.minimal_radius?.message}
          </WarningText> */}
          <BaseLabel className="add-vehicle__main-label">
            Valor fixo da viagem
          </BaseLabel>
          <BaseInput
            type="number"
            step="0.01"
            max={9999}
            min={0}
            className="add-vehicle__half-input"
            error={!!errors?.fixed_fee}
            {...register('fixed_fee', { required: true })}
          />
          <TypeMeter>reais</TypeMeter>
          <WarningText>{errors?.fixed_fee?.message}</WarningText>
          <BaseLabel className="add-vehicle__main-label">
            Valor do Km rodado neste veículo
          </BaseLabel>
          <BaseInput
            type="number"
            step="0.01"
            max={9999}
            min={0}
            className="add-vehicle__half-input"
            error={!!errors?.fee}
            {...register('fee', { required: true })}
          />
          <TypeMeter>reais/km</TypeMeter>
          <WarningText>{errors?.fee?.message}</WarningText>
          <BaseLabel className="add-vehicle__main-label">
            Porcentagem do seguro
          </BaseLabel>
          <BaseInput
            type="number"
            step="0.01"
            max={100}
            min={0}
            className="add-vehicle__half-input"
            error={!!errors?.insurance_percentage}
            {...register('insurance_percentage', { required: true })}
          />
          <TypeMeter>%</TypeMeter>
          <WarningText>
            {errors?.insurance_percentage?.message}
          </WarningText>
        </FormDividers>
        <AvatarHandler
          {...{
            register,
            errors: {
              photo: errors?.photo,
              photo_url: errors?.photo_url,
            },
          }}
        />
      </FormWrapper>
      <SubmitButton
        type="submit"
        className="add-vehicle__button"
        loading={!!isSubmitting}
      >
        Salvar
      </SubmitButton>
    </AddVehicleTypeFormContainer>
  );
};
