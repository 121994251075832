import { DeliveryCard } from '../DeliveryCard';

import { IListDeliveriesProps } from './interfaces';
import { DeliveryItem, EmptyMessage, ListDeliveriesContainer } from './styles';

export const ListDeliveries = ({
  deliveries,
  handleShowDetails,
}: IListDeliveriesProps) => (
  <ListDeliveriesContainer>
    {deliveries?.length > 0 ? (deliveries?.map((delivery) => (
      <DeliveryItem key={delivery?.id}>
        <DeliveryCard delivery={delivery} onClick={handleShowDetails} />
      </DeliveryItem>
    ))) : (
      <EmptyMessage>Nenhuma corrida encontrada!</EmptyMessage>
    )}
  </ListDeliveriesContainer>
);
