import styled from 'styled-components';

import { NavLink } from 'react-router-dom';
import { ReactComponent as ArrowIconRaw } from '../../../assets/images/nav-bar/nav-arrow-icon.svg';
import { ReactComponent as MenuIconRaw } from '../../../assets/images/nav-bar/nav-menu-icon.svg';
import { ReactComponent as AddAdmIconRaw } from '../../../assets/images/nav-bar/nav-add-adm-icon.svg';
import { ReactComponent as DocIconRaw } from '../../../assets/images/nav-bar/nav-doc-icon.svg';
import { ReactComponent as TruckIconRaw } from '../../../assets/images/nav-bar/nav-truck-icon.svg';
import { ReactComponent as MarkerIconRaw } from '../../../assets/images/nav-bar/nav-marker-icon.svg';
import { ReactComponent as DollarIconRaw } from '../../../assets/images/nav-bar/nav-dollar-icon.svg';

interface INavProps {
  open: boolean;
}

export const MenuItemsWrapper = styled.div`
  height: 5rem;
  padding: 1.5rem 2rem 0;

  border-radius: 1rem 1rem 0 0;
  background-color: #fff;
  transition: width 0.2s ease;

  overflow: hidden;

  @media (min-width:632px) and (min-height: 500px){
    height: 85vh;
    max-height: 49.5625rem;
    padding: 3.4375rem 0 0 2.15625rem;

    border-radius: 0 3rem 3rem 0;
  }
`;

export const ArrowIcon = styled(ArrowIconRaw)`
  width: 0.57875rem;
  height: 1.0125rem;

  transition: transform 0.2s ease;
`;

export const NavBarContainer = styled.nav<INavProps>`
  width: 100%;
  height: 5rem;

  position: fixed;
  bottom: 0;
  left: 0;
  right: 0;
  z-index: 19;

  filter: drop-shadow(0 -3px 6px rgba(0, 0, 0, 0.16));
  transition: width 0.2s ease;

  ${MenuItemsWrapper}{
    width: 100%;
  }

  ${ArrowIcon}{
    ${({ open }) => (open
    ? 'transform: rotate(0deg);'
    : 'transform: rotate(180deg);')}
  }

  @media (min-width:632px)  and (min-height: 500px){
    ${({ open }) => (open
    ? 'width: 22.4375rem;'
    : 'width: 6.8125rem;'
  )}
    height: 85vh;
    max-height: 49.5625rem;

    top: 5.671875rem;
    left: 0;

    filter: drop-shadow(0 3px 6px rgba(0, 0, 0, 0.16));

    ${MenuItemsWrapper}{
      ${({ open }) => (open
    ? 'width: 22.4375rem;'
    : 'width: 6.8125rem;')}
    }
  }
`;

export const ToggleViewButton = styled.button`
  width: 2.5625rem;
  height: 2.5625rem;

  display: none;

  position: absolute;
  right: -0.8rem;
  top: 47%;

  background-color: #fff;
  border-radius: 50%;
  cursor: pointer;

  font-size: 2rem;

  @media (min-width:632px)  and (min-height: 500px){
    display: flex;
    align-items: center;
    justify-content: center;
  }
`;

export const MenuContainer = styled.div`
  width: 18.095625rem;

  display: none;

  @media (min-width:632px)  and (min-height: 500px){
    display: flex;
    align-items: center;
    justify-content: space-between;
  }
`;

export const MenuIcon = styled(MenuIconRaw)`
  width: 2.441875rem;
  height: 2.441875rem;
`;

export const AddAdminButton = styled(NavLink)`
  width: fit-content;
  height: 2.0625rem;
  padding: 1.4rem;

  display: flex;
  align-items: center;
  justify-content: space-between;

  background-color: var(--darkPurple);
  border-radius: 0.625rem;
  cursor: pointer;
  transition: filter 0.2s ease;

  text-decoration: none;

  &:hover{
    filter: brightness(0.9);
  }

  @media (min-width:632px)  and (min-height: 500px){
    width: 11.6875rem;
    padding: 0 1.4rem;
  }
`;

export const AddAdmIcon = styled(AddAdmIconRaw)`
  width: 1.125rem;
  height: 1rem;

  display: inline-block;
`;

export const AddAdmText = styled.h2`
  display: inline-block;

  color: #fff;
  font-size: 0.875rem;
  font-weight: bold;
`;

export const MenuList = styled.ul`
  display: flex;
  align-items: center;
  justify-content: space-between;

  list-style-type: none;

  @media (min-width:632px)  and (min-height: 500px){
    // margin-top: 6.46875rem;
    margin-top: min(10vh, 6.46875rem);

    display: inherit;
  }
`;

export const MenuIconContainer = styled.span`
  width: 2.5rem;
  height: fit-content;

  position: relative;
`;

export const MenuListItem = styled.li`
  width: fit-content;

  display: flex;
  align-items: center;
  justify-content: space-between;

  @media (min-width:632px)  and (min-height: 500px){
    width: 18.095625rem;

    &:not(:first-child){
      margin-top: min(10vh, 4.01875rem);
    }

    &:last-child{
      display: none;
    }
  }
`;

export const MenuLink = styled(NavLink)`
  width: fit-content;

  position: relative;

  text-decoration: none;

  &.active{
    ${MenuIconContainer}::before{
      content: '';
      width: 100%;
      height: 0.22rem;

      position: absolute;
      right: 0;
      bottom: -0.6rem;

      display: block;

      border-radius: 30rem;
      background-color: #0ff;
      animation: fade-in 0.8s ease;
    }
  }

  @media (min-width:632px)  and (min-height: 500px){
    width: 100%;

    display: flex;
    justify-content: space-between;
    align-items: center;
  }
`;

export const MenuAnchorText = styled.h2`
  width: 13rem;

  display: none;

  color: var(--darkPurple);
  font-size: 0.875rem;

  @media (min-width:632px)  and (min-height: 500px){
    display: inline-block;
  }
`;

export const DocIcon = styled(DocIconRaw)`
  width: 1.584375rem;
  height: 1.980625rem;
  margin: 0 auto;

  display: block;
`;

export const TruckIcon = styled(TruckIconRaw)`
  width: 1.875rem;
  height: 1.59375rem;
  margin: 0 auto;

  display: block;
`;

export const MarkerIcon = styled(MarkerIconRaw)`
  width: 1.18875rem;
  height: 1.675625rem;
  margin: 0 auto;

  display: block;
`;

export const DollarIcon = styled(DollarIconRaw)`
  width: 1.875rem;
  height: 1.59375rem;
  margin: 0 auto;

  display: block;
`;
