import {
  createContext, FC,
  useEffect,
  useLayoutEffect, useState,
} from 'react';

import { api } from '../../services/api';
import { MaskPhone } from '../../utils';

import {
  IAuthContext, ISignInData,
  IUser, IUserCredentials,
} from './interfaces';

export const AuthContext = createContext<IAuthContext>({} as IAuthContext);

export const AuthProvider: FC = ({ children }) => {
  const [user, setUser] = useState(() => {
    const jsonUser = localStorage.getItem('@VagooMaster: User');

    if (jsonUser) {
      return JSON.parse(jsonUser) as IUser;
    }

    return {} as IUser;
  });

  const [accessToken, setAccessToken] = useState<string>(() => {
    const access_token = localStorage.getItem('@VagooMaster: AccessToken');

    if (access_token) {
      return access_token;
    }

    return '';
  });

  const [refreshToken, setRefreshToken] = useState(() => {
    const refresh_token = localStorage.getItem('@VagooMaster: RefreshToken');

    if (refresh_token) {
      return refresh_token;
    }

    return '';
  });

  const [saveUser, setSaveUser] = useState(() => {
    const save_user = localStorage.getItem('@VagooMaster: SaveUser');

    if (save_user) {
      return !!save_user;
    }

    return false;
  });

  const handleSignIn = async (payload: ISignInData) => {
    const response = await api.post('/sessions', payload);

    const newUser = response?.data?.user;

    if (newUser?.role_id !== 1) {
      throw new Error('Usuario não é administrador!');
    }

    const access_token = response?.data?.access_token;
    const refresh_token = response?.data?.refresh_token;

    if (payload.saveUser) {
      handleUserCredentials({
        newUser,
        access_token,
        refresh_token,
      });
    }

    handleSaveUser(payload.saveUser);

    setUser(newUser);
    setAccessToken(access_token);
    setRefreshToken(refresh_token);

    handleToken(access_token);
  };

  const handleSignOut = () => {
    setUser({} as IUser);
    setAccessToken('');
    setRefreshToken('');

    handleSaveUser(false);

    localStorage.removeItem('@VagooMaster: User');
    localStorage.removeItem('@VagooMaster: AccessToken');
    localStorage.removeItem('@VagooMaster: RefreshToken');
    localStorage.removeItem('@VagooMaster: SaveUser');

    api.delete('/sessions/logout');
  };

  const handleToken = (token: string) => {
    api.defaults.headers.common.Authorization = `Bearer ${token}`;
  };

  const handleRefreshToken = async () => {
    try {
      const response = await api.put('/sessions/refresh-token', {
        refresh_token: refreshToken,
      });

      const newUser = response?.data?.user;
      const access_token = response?.data?.access_token;
      const refresh_token = response?.data?.refresh_token;

      if (saveUser) {
        handleUserCredentials({
          newUser,
          access_token,
          refresh_token,
        });
      }

      handleToken(access_token);

      return true;
    } catch {
      handleSignOut();

      return false;
    }
  };

  const handleUserCredentials = ({
    newUser, access_token, refresh_token,
  }: IUserCredentials) => {
    const ensuredUser = ensureUser(newUser);

    setUser(ensuredUser);
    setAccessToken(access_token);
    setRefreshToken(refresh_token);

    localStorage.setItem('@VagooMaster: User', JSON.stringify(ensuredUser));
    localStorage.setItem('@VagooMaster: AccessToken', access_token);
    localStorage.setItem('@VagooMaster: RefreshToken', refresh_token);
  };

  const handleSaveUser = (save: boolean) => {
    setSaveUser(save);

    if (save) {
      localStorage.setItem('@VagooMaster: SaveUser', 'true');
    }
  };

  const ensureUser = (newUser: IUser) => ({
    ...newUser,
    cellphone: MaskPhone(newUser?.cellphone),
    individual_person: {
      ...newUser?.individual_person,
    },
    legal_person: {
      ...newUser?.legal_person,
    },
  });

  const ensureTokenOnHeader = () => {
    if (accessToken) {
      handleToken(accessToken);
    }
  };

  const ensureLocalStorageUser = () => {
    if (user.id) {
      const ensuredUser = ensureUser(user);

      localStorage.setItem('@VagooMaster: User', JSON.stringify(ensuredUser));
    }
  };

  useLayoutEffect(() => {
    ensureTokenOnHeader();
  }, []);

  useEffect(() => {
    ensureLocalStorageUser();
  }, [user]);

  return (
    <AuthContext.Provider value={{
      user,
      setUser,
      accessToken,
      setAccessToken,
      refreshToken,
      setRefreshToken,
      handleSignIn,
      handleSignOut,
      handleToken,
      handleRefreshToken,
    }}
    >
      {children}
    </AuthContext.Provider>
  );
};
