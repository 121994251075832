import { ActivityIcon, TimelineIcon, TrophyIcon } from './styles';

export const operationalDashboardContent = {
  firstSectionTitle: {
    label: 'Número de viagens realizadas',
    icon: <TimelineIcon />,
  },
  secondSectionTitle: {
    label: 'Viagens realizadas + canceladas',
    icon: <TrophyIcon />,
    note: undefined,
  },
  chartSectionTitle: {
    label: 'Número de Viagens Realizadas X Viagens Canceladas',
    icon: <ActivityIcon />,
  },
};
