/* eslint-disable react/prop-types */
import { forwardRef } from 'react';
import { ICargoVolume, ICargoWeight } from '../../../pages/Vehicles/interface';

import { IVehicleDetailsProps } from './interfaces';

import {
  CloseButton,
  CloseIcon,
  ContentContainer,
  DetailsContainer,
  DetailsTitle,
  DetailText,
  DetailValue,
  DetailWrapper,
  DotsIcon,
  VehicleDetailContainer,
  VehicleDetailHeader,
  VehicleImage,
} from './styles';

const formatVehicleWeightVolume = (
  weight?: ICargoWeight,
  volume?: ICargoVolume,
) => {
  if (!weight && !volume) {
    return '-';
  }

  if (!weight) {
    return `${volume?.max || '-'} ${volume?.unit || ''}`;
  }

  if (!volume) {
    return `${
      weight?.max
        ? (weight.max / 1000).toLocaleString('pt-BR', {
          maximumFractionDigits: 1,
        })
        : '-'
    } ${weight?.unit ? 'ton' : ''}`;
  }

  return `${(weight.max / 1000).toLocaleString('pt-BR', {
    maximumFractionDigits: 1,
  })} ton; ${volume?.max} ${volume.unit}`;
};

export const VehicleDetails = forwardRef<HTMLElement, IVehicleDetailsProps>(
  (
    {
      vehicle: {
        vehicle_image_url,
        vehicle_class,
        bodywork,
        // minimal_radius,
        // minimal_time_for_cancelation,
        name,
        percentage_per_cte,
        shipper_cancelation_fee,
        transporter_cancelation_fee,
        volume,
        weight,
        axis_number,
        fee,
        fixed_fee,
        transporter_percentage,
        insurance_percentage,
      },
      closeModal,
    },
    ref,
  ) => (
    <VehicleDetailContainer ref={ref}>
      <VehicleDetailHeader>
        <DotsIcon />
        <DetailsTitle>{name}</DetailsTitle>
        <CloseButton type="button" onClick={closeModal}>
          <CloseIcon />
        </CloseButton>
      </VehicleDetailHeader>
      <ContentContainer>
        <VehicleImage src={vehicle_image_url} alt={name} />
        <DetailsContainer>
          {/* <DetailWrapper>
          <DetailText>
            Tipo de Veículo:
          </DetailText>
          <DetailValue>{vehicleClassName}</DetailValue>
        </DetailWrapper> */}
          <DetailWrapper>
            <DetailText>Tipo de Veículo:</DetailText>
            <DetailValue>{name}</DetailValue>
          </DetailWrapper>
          <DetailWrapper>
            <DetailText>Tipo de Carroceria:</DetailText>
            <DetailValue>{bodywork?.name || '-'}</DetailValue>
          </DetailWrapper>
          <DetailWrapper>
            <DetailText>Total de Eixos:</DetailText>
            <DetailValue>{axis_number}</DetailValue>
          </DetailWrapper>
          <DetailWrapper>
            <DetailText>Capacidade de Carga:</DetailText>
            <DetailValue>
              {formatVehicleWeightVolume(weight, volume)}
            </DetailValue>
          </DetailWrapper>
          <DetailWrapper>
            <DetailText>Porcentagem Vagoo por CTE Emitida:</DetailText>
            <DetailValue>{percentage_per_cte}%</DetailValue>
          </DetailWrapper>
          {/* <DetailWrapper>
            <DetailText>Tempo Mínimo para Cancelamento:</DetailText>
            <DetailValue>{minimal_time_for_cancelation} minutos</DetailValue>
          </DetailWrapper> */}
          <DetailWrapper>
            <DetailText>% Vagoo por Cancelamento do Embarcador:</DetailText>
            <DetailValue>{shipper_cancelation_fee}%</DetailValue>
          </DetailWrapper>
          <DetailWrapper>
            <DetailText>% Vagoo por Cancelamento do Transportador:</DetailText>
            <DetailValue>{transporter_cancelation_fee}%</DetailValue>
          </DetailWrapper>
          <DetailWrapper>
            <DetailText>% Seguro:</DetailText>
            <DetailValue>{insurance_percentage}%</DetailValue>
          </DetailWrapper>
          {/* <DetailWrapper>
          <DetailText>
            Raio Mínimo:
          </DetailText>
          <DetailValue>{minimal_radius.toLocaleString('pt-BR', {
            maximumFractionDigits: 1,
          })} km
          </DetailValue>
        </DetailWrapper> */}
          <DetailWrapper>
            <DetailText>Porcentagem do Transportador:</DetailText>
            <DetailValue>
              {transporter_percentage.toLocaleString()}%
            </DetailValue>
          </DetailWrapper>
          <DetailWrapper>
            <DetailText>Valor por Km rodado:</DetailText>
            <DetailValue>
              {fee.toLocaleString('pt-BR', {
                style: 'currency',
                currency: 'BRL',
                maximumFractionDigits: 2,
              })}{' '}
              /km
            </DetailValue>
          </DetailWrapper>
          <DetailWrapper>
            <DetailText>Valor fixo da viagem:</DetailText>
            <DetailValue>
              {fixed_fee.toLocaleString('pt-BR', {
                style: 'currency',
                currency: 'BRL',
                maximumFractionDigits: 2,
              })}
            </DetailValue>
          </DetailWrapper>
        </DetailsContainer>
      </ContentContainer>
    </VehicleDetailContainer>
  ),
);
