import { Dispatch } from 'react';
import {
  DashboardReducerActions,
  DashboardState,
  TimeSpan,
} from '../../../pages/Dashboard/reducer';
import { FiltersSelector } from '../../molecules/FiltersSelector';
import { TotalChart } from '../../molecules/TotalChart';
import { RevenueChart } from '../RevenueChart';
import { RevenuePizzaCharts } from '../RevenuePizzaCharts';
import { financialDashboardContent } from './content';
import {
  ChartContainer,
  DashboardRow,
  PiesChartsContainer,
  SectionTitle,
  SectionTitleContainer,
  SectionTitleNote,
  TotalRevenueContainer,
} from './styles';

interface IFinancialDashboardProps {
  state: DashboardState;
  dispatch: Dispatch<DashboardReducerActions>;
}

export const FinancialDashboard = ({
  state,
  dispatch,
}: IFinancialDashboardProps) => {
  const { firstSectionTitle, chartSectionTitle, secondSectionTitle } = financialDashboardContent;

  const { filters, financial } = state;

  return (
    <>
      <DashboardRow>
        <PiesChartsContainer>
          <SectionTitleContainer>
            {firstSectionTitle.icon}
            <SectionTitle>{firstSectionTitle.label}</SectionTitle>
          </SectionTitleContainer>
          <FiltersSelector
            value={filters.financial.vagooIncome}
            onChange={(value) => dispatch({
              type: 'CHANGE_SECTION_TIME_SPAN',
              payload: {
                type: 'financial',
                section: 'vagooIncome',
                timespan: value as TimeSpan,
              },
            })}
            customClassName="pies-charts-container__filters-selector"
          />
          <RevenuePizzaCharts
            data={financial[filters.financial.vagooIncome]?.vagooIncome}
          />
        </PiesChartsContainer>
        <TotalRevenueContainer>
          <SectionTitleContainer>
            {secondSectionTitle.icon}
            <SectionTitle>{secondSectionTitle.label}</SectionTitle>
            {secondSectionTitle.note && (
              <SectionTitleNote>{secondSectionTitle.note}</SectionTitleNote>
            )}
          </SectionTitleContainer>
          <FiltersSelector
            value={filters.financial.totalIncome}
            onChange={(value) => dispatch({
              type: 'CHANGE_SECTION_TIME_SPAN',
              payload: {
                type: 'financial',
                section: 'totalIncome',
                timespan: value as TimeSpan,
              },
            })}
            customClassName="total-revenue-container__filters-selector"
          />
          <TotalChart
            type="financial"
            unit="reais"
            data={
              financial[filters.financial.totalIncome]?.totalIncome.income_total
            }
          />
        </TotalRevenueContainer>
      </DashboardRow>
      <DashboardRow>
        <ChartContainer>
          <SectionTitleContainer>
            {chartSectionTitle.icon}
            <SectionTitle>{chartSectionTitle.label}</SectionTitle>
          </SectionTitleContainer>
          <RevenueChart
            onChangeTimespan={(value: TimeSpan) => {
              dispatch({
                type: 'CHANGE_SECTION_TIME_SPAN',
                payload: {
                  type: 'financial',
                  section: 'incomeGraph',
                  timespan: value,
                },
              });
            }}
            timespan={filters.financial.incomeGraph}
            totalIncome={
              financial[filters.financial.incomeGraph]?.incomeGraph?.totalIncome
            }
            vagooIncome={
              financial[filters.financial.incomeGraph]?.incomeGraph?.vagooIncome
            }
          />
        </ChartContainer>
      </DashboardRow>
    </>
  );
};
