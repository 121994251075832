import { useEffect, useState } from 'react';
import toast from 'react-hot-toast';

import { useParams } from 'react-router-dom';
import { SubmitHandler, useForm } from 'react-hook-form';
import { BaseInput } from '../../atoms/BaseInput';
import { BaseLabel } from '../../atoms/BaseLabel';
import { BaseSelect } from '../../atoms/BaseSelect';
import { SubmitButton } from '../../atoms/SubmitButton';
import { AvatarHandler } from '../AvatarHandler';

import { api } from '../../../services/api';

import { IOptions } from '../../../interfaces/IOptions';
import {
  AddVehicleTypeFormContainer,
  BackUpInput,
  CapacityWrapper,
  FormDividers,
  FormWrapper,
  TypeMeter,
  WarningText,
} from './styles';
import {
  IEditVehicleTypeFormData,
  IEditVehicleTypeFormDataKeys,
} from '../../../pages/EditVehicleType/interfaces';
import { IPaginatedResult } from '../../../interfaces/IPaginatedResult';
import { ICargoVolume, ICargoWeight } from '../../../pages/Vehicles/interface';
import { Bodywork } from '../../../pages/FollowDelivery/interfaces';

export const EditVehicleTypeForm = () => {
  // const [vehicleClassId, setVehicleClassId] = useState<string>();
  const [bodyTypesId, setBodyTypesId] = useState<string>();

  const [bodyWorkTypesOptions, setBodyWorkTypesOptions] = useState<IOptions[]>();
  // const [vehicleClassesOptions, setVehicleClassesOptions] = useState([] as IOptions[]);
  const [vehicleWeightOptions, setVehicleWeightOptions] = useState<IOptions[]>();
  const [vehicleVolumeOptions, setVehicleVolumeOptions] = useState<IOptions[]>();

  const { vehicleId } = useParams();

  const {
    register,
    handleSubmit,
    setValue,
    getValues,
    formState: { errors, isSubmitting, isDirty },
  } = useForm<IEditVehicleTypeFormData>();

  const [defaultImage, setDefaultImage] = useState<string>();

  const onSubmit: SubmitHandler<IEditVehicleTypeFormData> = async (data) => {
    try {
      const formData = new FormData();

      Object.keys(data).forEach((key) => {
        if (key !== 'image' && key !== 'vehicle_pattern') {
          if (data[key as IEditVehicleTypeFormDataKeys]) {
            formData.append(
              key,
              data[key as IEditVehicleTypeFormDataKeys] as string,
            );
          }
        }
      });

      const photo = data.image[0];

      if (photo) {
        formData.append('photo', photo);
      }

      formData.set('vehicle_class_id', '1');

      await api.put(`/vehicle-types/${vehicleId}`, formData);

      toast.success('Tipo de veículo atualizado com sucesso!');
    } catch (error: any) {
      toast.error(error.response?.data?.message);
    }
  };

  const ensureBodyWorkOptions = async () => {
    try {
      const response = await api.get<Bodywork[]>('/bodywork-types');
      const bodyWorks = response?.data;

      setBodyWorkTypesOptions(() => {
        const newState = bodyWorks?.map((bodyworkType: any) => ({
          value: bodyworkType.id,
          label: bodyworkType.name,
        }));

        newState.unshift({
          label: 'Selecione',
          value: '',
        });

        return newState;
      });

      setBodyTypesId(`${getValues('bodywork_type_id')}`);
    } catch (error: any) {
      toast.error(error.response?.data?.message);
    }
  };

  /* const ensureVehicleClassOptions = async () => {
    try {
      const response = await api.get('/vehicle-classes');
      const vehicleClasses = response?.data?.results;

      setVehicleClassesOptions(() => {
        const newState = vehicleClasses?.map((vehicleClass: any) => ({
          value: vehicleClass.id,
          label: vehicleClass.name,
        }));

        newState.unshift({
          label: 'Selecione', value: '',
        });

        return newState;
      });

      setVehicleClassId(`${getValues('vehicle_class_id')}`);
    } catch (error: any) {
      toast.error(error?.response?.data?.message || error?.response?.messageString);
    }
  }; */

  const ensureVehicleWeightOptions = async () => {
    try {
      const response = await api.get<IPaginatedResult<ICargoWeight>>(
        '/vehicle-weights',
      );
      const vehicleWeights = response?.data?.results;

      setVehicleWeightOptions(() => {
        const newState = vehicleWeights?.map((vehicleWeight: any) => ({
          value: vehicleWeight.id,
          label: vehicleWeight.name,
        }));

        newState.unshift({
          label: 'Selecione',
          value: '',
        });

        return newState;
      });
    } catch (error: any) {
      toast.error(
        error?.response?.data?.message || error?.response?.messageString,
      );
    }
  };

  const ensureVehicleVolumeOptions = async () => {
    try {
      const response = await api.get<IPaginatedResult<ICargoVolume>>(
        '/vehicle-volumes',
      );
      const vehicleVolumes = response?.data?.results;

      setVehicleVolumeOptions(() => {
        const newState = vehicleVolumes?.map((vehicleVolume: any) => ({
          value: vehicleVolume.id,
          label: vehicleVolume.name,
        }));
        newState.unshift({
          label: 'Selecione',
          value: '',
        });
        return newState;
      });
    } catch (error: any) {
      toast.error(
        error?.response?.data?.message || error?.response?.messageString,
      );
    }
  };

  const ensureVehicleData = async () => {
    try {
      const results = await api.get(`/vehicle-types/${vehicleId}`);

      const {
        vehicle_class,
        bodywork,
        id,
        created_at,
        updated_at,
        vehicle_image_url,
        filename,
        vehicle_pattern,
        volume,
        weight,
        ...vehicleType
      } = results?.data as any;

      Object.keys(vehicleType).forEach((key) => {
        if (!vehicleType[key]) return;
        setValue(key as IEditVehicleTypeFormDataKeys, `${vehicleType[key]}`);
      });

      setDefaultImage(vehicle_image_url);
    } catch (error: any) {
      toast.error(error.response?.data?.message);
    }
  };

  useEffect(() => {
    ensureBodyWorkOptions();
    // ensureVehicleClassOptions();
    ensureVehicleWeightOptions();
    ensureVehicleVolumeOptions();
  }, []);

  useEffect(() => {
    // Só rodar essa chamada depois de preencher as options, selects estavam ficando vazios
    if (vehicleVolumeOptions && bodyWorkTypesOptions && vehicleWeightOptions) {
      ensureVehicleData();
    }
  }, [vehicleVolumeOptions, bodyWorkTypesOptions, vehicleWeightOptions]);

  return (
    <AddVehicleTypeFormContainer onSubmit={handleSubmit(onSubmit)}>
      <FormWrapper>
        <FormDividers>
          {/* <BaseLabel className="edit-vehicle__main-label">
            Tipo do Veículo
          </BaseLabel>
          {vehicleClassId ? (
            <BaseSelect
              options={vehicleClassesOptions}
              defaultValue={vehicleClassId}
              {...register('vehicle_class_id', { required: true })}
            />
          ) : (
            <BackUpInput
              type="text"
              disabled
            />
          )}
          <WarningText>
            {errors?.vehicle_class_id?.message}
          </WarningText> */}
          <BaseLabel className="edit-vehicle__main-label">
            Tipo do Veículo
          </BaseLabel>
          <BaseInput
            type="text"
            error={!!errors?.name}
            {...register('name', { required: true })}
          />
          <WarningText>{errors?.name?.message}</WarningText>
          <BaseLabel className="edit-vehicle__main-label">
            Tipo de carroceria
          </BaseLabel>
          {bodyTypesId ? (
            <BaseSelect
              options={bodyWorkTypesOptions || []}
              defaultValue={bodyTypesId}
              {...register('bodywork_type_id')}
            />
          ) : (
            <BackUpInput type="text" disabled />
          )}
          <WarningText>{errors?.bodywork_type_id?.message}</WarningText>
          <BaseLabel className="edit-vehicle__main-label">
            Total de Eixos
          </BaseLabel>
          <BaseInput
            type="number"
            max={99}
            min={1}
            className="edit-vehicle__half-input"
            error={!!errors?.axis_number}
            {...register('axis_number', { required: true })}
          />
          <WarningText>{errors?.axis_number?.message}</WarningText>
          <BaseLabel className="edit-vehicle__main-label">
            Capacidade de Carga
          </BaseLabel>
          <CapacityWrapper>
            {/* <BaseInput
              type="number"
              step="0.01"
              max={9999}
              min={0}
              className="edit-vehicle__half-input"
              error={!!errors?.cargo_weight}
              {...register('cargo_weight', { required: true })}
            />
            <TypeMeter>
              ton
            </TypeMeter> */}
            <BaseSelect
              options={vehicleWeightOptions || []}
              {...register('cargo_weight_id')}
            />
          </CapacityWrapper>
          <CapacityWrapper>
            {/* <BaseInput
              type="number"
              step="0.01"
              max={9999}
              min={0}
              className="edit-vehicle__half-input"
              error={!!errors?.cargo_volume}
              {...register('cargo_volume', { required: true })}
            />
            <TypeMeter>
              m³
            </TypeMeter> */}
            <BaseSelect
              className="capacity-wrapper__margin-left"
              options={vehicleVolumeOptions || []}
              {...register('cargo_volume_id')}
            />
          </CapacityWrapper>
          <WarningText>
            {errors?.cargo_weight_id?.message}{' '}
            {errors?.cargo_volume_id?.message}
          </WarningText>
          <BaseLabel className="edit-vehicle__main-label">
            Porcentagem do Transportador
          </BaseLabel>
          <BaseInput
            type="number"
            max={100}
            min={0}
            className="edit-vehicle__half-input"
            error={!!errors?.transporter_percentage}
            {...register('transporter_percentage', { required: true })}
          />
          <TypeMeter>%</TypeMeter>
          <WarningText>{errors?.percentage_per_cte?.message}</WarningText>
        </FormDividers>
        <FormDividers>
          <BaseLabel className="edit-vehicle__main-label">
            Porcentagem Vagoo por CTE Emitida
          </BaseLabel>
          <BaseInput
            type="number"
            max={100}
            min={0}
            className="edit-vehicle__half-input"
            error={!!errors?.percentage_per_cte}
            {...register('percentage_per_cte', { required: true })}
          />
          <TypeMeter>%</TypeMeter>
          <WarningText>{errors?.percentage_per_cte?.message}</WarningText>
          {/* <BaseLabel className="edit-vehicle__main-label">
            Tempo Mínimo de Cancelamento
          </BaseLabel>
          <BaseInput
            type="number"
            step="0.01"
            max={9999}
            min={0}
            className="edit-vehicle__half-input"
            error={!!errors?.minimal_time_for_cancelation}
            {...register('minimal_time_for_cancelation', { required: true })}
          />
          <TypeMeter>
            min
          </TypeMeter>
          <WarningText>
            {errors?.minimal_time_for_cancelation?.message}
          </WarningText> */}
          <BaseLabel className="edit-vehicle__main-label">
            Porcentagem Vagoo por Cancelamento do Embarcador
          </BaseLabel>
          <BaseInput
            type="number"
            step="0.01"
            max={100}
            min={0}
            className="edit-vehicle__half-input"
            error={!!errors?.shipper_cancelation_fee}
            {...register('shipper_cancelation_fee', { required: true })}
          />
          <TypeMeter>%</TypeMeter>
          <WarningText>{errors?.shipper_cancelation_fee?.message}</WarningText>
          <BaseLabel className="edit-vehicle__main-label">
            Porcentagem Vagoo por Cancelamento do Transportador
          </BaseLabel>
          <BaseInput
            type="number"
            step="0.01"
            max={100}
            min={0}
            className="edit-vehicle__half-input"
            error={!!errors?.transporter_cancelation_fee}
            {...register('transporter_cancelation_fee', { required: true })}
          />
          <TypeMeter>%</TypeMeter>
          <WarningText>
            {errors?.transporter_cancelation_fee?.message}
          </WarningText>
          {/* <BaseLabel className="edit-vehicle__main-label">
            Raio Mínimo
          </BaseLabel>
          <BaseInput
            type="number"
            step="0.01"
            max={9999}
            min={0}
            className="edit-vehicle__half-input"
            error={!!errors?.minimal_radius}
            {...register('minimal_radius', { required: true })}
          />
          <TypeMeter>
            Km
          </TypeMeter>
          <WarningText>
            {errors?.minimal_radius?.message}
          </WarningText> */}
          <BaseLabel className="edit-vehicle__main-label">
            Valor fixo da viagem
          </BaseLabel>
          <BaseInput
            type="number"
            step="0.01"
            max={9999}
            min={0}
            className="edit-vehicle__half-input"
            error={!!errors?.fixed_fee}
            {...register('fixed_fee', { required: true })}
          />
          <TypeMeter>reais</TypeMeter>
          <WarningText>{errors?.fixed_fee?.message}</WarningText>
          <BaseLabel className="edit-vehicle__main-label">
            Valor do Km rodado neste veículo
          </BaseLabel>
          <BaseInput
            type="number"
            step="0.01"
            max={9999}
            min={0}
            className="edit-vehicle__half-input"
            error={!!errors?.fee}
            {...register('fee', { required: true })}
          />
          <TypeMeter>reais/km</TypeMeter>
          <WarningText>{errors?.fee?.message}</WarningText>
          <BaseLabel className="edit-vehicle__main-label">
            Porcentagem do seguro
          </BaseLabel>
          <BaseInput
            type="number"
            step="0.01"
            max={100}
            min={0}
            className="edit-vehicle__half-input"
            error={!!errors?.insurance_percentage}
            {...register('insurance_percentage', { required: true })}
          />
          <TypeMeter>%</TypeMeter>
          <WarningText>
            {errors?.insurance_percentage?.message}
          </WarningText>
        </FormDividers>
        <AvatarHandler
          {...{
            register,
            defaultUrl: defaultImage,
            errors: {
              photo: errors?.photo,
              photo_url: errors?.photo_url,
            },
          }}
        />
      </FormWrapper>
      <SubmitButton
        type="submit"
        className="edit-vehicle__button"
        loading={!!isSubmitting}
        disabled={!isDirty}
      >
        Salvar
      </SubmitButton>
    </AddVehicleTypeFormContainer>
  );
};
