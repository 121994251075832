import { ICargoVolume, ICargoWeight } from '../Vehicles/interface';

export interface IGetTripsResponse {
  tripList: {
    results: IDelivery[];
    page: number;
    limit: number;
    total: number;
  };
}

// eslint-disable-next-line no-shadow
export enum TripStatus {
  Pending = 1, // Aguardando transportadora aceitar
  Displacement, // Entrega em deslocamento
  Collecting, // Motorista indo coletar entrega
  OnGoing, // OnGoing = Aguardando motorista começar
  waitingPayment, // Aguardando pagamento
  Finished, // Motorista finalizou
  Canceled,
}

export interface IDocument {
  created_at: string;
  document: string;
  document_url: string;
  id: string;
  trip_id: string;
  updated_at: string;
}

export interface IDelivery {
  id: string;
  status_id: TripStatus;
  order: string;
  driver_id: string;
  vehicle_id: string;
  vehicle_class_id: number;
  shipper_id: string;
  responsible_id: string;
  initial_address_id: string;
  delivery_address_id: string;
  isPaid: boolean;
  plate: string;
  cargo_type: string;
  cargo_value: number;
  cargo_bodywork_id: number;
  transport_cost: number;
  toll_cost: number;
  insurance_cost: number;
  total_cost: number;
  vagoo_cost: number;
  cte: boolean;
  payment_method: null | string;
  observation: string;
  trip_distance: number;
  displacement_distance: number;
  driver: Driver;
  vehicle: Vehicle;
  document: null | IDocument;
  initial_address: Address;
  delivery_address: Address;
  status_marks: StatusMark[];
  responsible: Responsible;
  created_at: string;
  updated_at: string;
}

export interface Address {
  id: string;
  address_id: string;
  geolocation: Geolocation;
  address: AddressClass;
  created_at: string;
  updated_at: string;
}

export interface AddressClass {
  id: string;
  name: null;
  street: string;
  neighborhood: string;
  complement: null;
  number: string;
  cep: string;
  city_id: number;
  city: City;
  created_at: string;
  updated_at: string;
}

export interface City {
  id: number;
  name: string;
  state_id: number;
  ibge_code: string;
  state: State;
  created_at: string;
  updated_at: string;
}

export interface State {
  id: number;
  name: string;
  uf: string;
  created_at: string;
  updated_at: string;
}

export interface Geolocation {
  type: string;
  coordinates: number[];
}

export interface Driver {
  id: string;
  status_id: number;
  user_id: string;
  cnh: string;
  email: string;
  driver_types_id: number[];
  user: Responsible;
  created_at: string;
  updated_at: string;
}

export interface Responsible {
  id: string;
  email: string;
  avatar: null;
  role_id: number;
  status_id: number;
  cellphone: string;
  accepted_terms: boolean;
  recipient_id: null | string;
  individual_person: IndividualPerson | null;
  created_at: string;
  updated_at: string;
  avatar_url: string;
  legal_person?: LegalPerson;
}

export interface IndividualPerson {
  id: string;
  name: string;
  cpf: string;
  birth_date: string;
  user_id: string;
  address_id: string;
  created_at: string;
  updated_at: string;
}

export interface LegalPerson {
  id: string;
  cnpj: string;
  corporate_name: string;
  trade_name: string;
  responsible_name: string;
  address_id: string;
  user_id: string;
  created_at: string;
  updated_at: string;
}

export interface StatusMark {
  id: string;
  trip_id: string;
  status_id: TripStatus;
  created_at: string;
}

export interface Vehicle {
  id: string;
  vehicle_type_id: string;
  plate: string;
  has_tracker: boolean;
  has_mopp: boolean;
  geolocation: Geolocation;
  vehicle_type: VehicleType;
  transporter_vehicle: TransporterVehicle;
  created_at: string;
  updated_at: string;
}

export interface TransporterVehicle {
  id: string;
  vehicle_id: string;
  transporter_id: string;
  driver_id: string;
  created_at: string;
}

export interface VehicleType {
  id: string;
  bodywork_type_id?: number;
  vehicle_class_id?: number;
  percentage_per_cte: number;
  transporter_percentage: number;
  minimal_time_for_cancelation: string;
  transporter_cancelation_fee: string;
  shipper_cancelation_fee: string;
  description: string;
  name: string;
  cargo_weight_id?: number;
  cargo_volume_id?: number;
  axis_number: number;
  fee: string;
  fixed_fee: string;
  filename: string;
  bodywork?: Bodywork;
  vehicle_class?: Bodywork;
  created_at: string;
  updated_at: string;
  vehicle_image_url: string;
  weight?: ICargoWeight;
  volume?: ICargoVolume;
}

export interface Bodywork {
  id: number;
  name: string;
  created_at: string;
  updated_at?: string;
}
