/* eslint-disable react/require-default-props */
import React from 'react';
import {
  Spinner, SpinnerContainer, SpinnerDot,
} from './styles';

interface Props {
  color?: string;
}

export const LoadingSpinner: React.FC<Props> = ({ color = '' }) => (
  <SpinnerContainer>
    <Spinner>
      <SpinnerDot color={color} />
      <SpinnerDot color={color} />
      <SpinnerDot color={color} />
      <SpinnerDot color={color} />
      <SpinnerDot color={color} />
      <SpinnerDot color={color} />
    </Spinner>
  </SpinnerContainer>
);
