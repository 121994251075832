import styled from 'styled-components';

export const ConfirmationModalContainer = styled.article`
  width: 100%;
  max-width: 27.8125rem;
  height: fit-content;
  overflow: hidden;

  border-radius: 1.375rem;
  background-color: #fff;

  position: relative;

  box-shadow: 0px 3px 6px rgba(0, 0, 0, 0.16);
`;

export const CloseButton = styled.button`
  background-color: transparent;
  border: none;

  position: absolute;
  top: 0.5rem;
  right: 1rem;

  color: var(--darkPurple);

  display: flex;
  align-items: center;
  justify-content: center;

  font-size: 1.5rem;

  cursor: pointer;
`;

export const ModalBody = styled.div`
  width: 100%;
  height: 100%;
  padding: 3rem 3.75rem;

  display: flex;
  flex-direction: column;
  gap: 3rem;
`;

export const Message = styled.p`
  color: var(--darkPurple);
  font-weight: 700;
  font-size: 1.6875rem;
  text-align: center;

  line-height: 2.3125rem;
`;

export const ButtonsContainer = styled.div`
  display: flex;
  align-items: center;
  gap: 1rem;

  align-items: center;
  justify-content: center;
`;

export const OutlinedButton = styled.button`
  color: var(--darkPurple);
  border: 2px solid var(--darkPurple);
  background: #fff;

  border-radius: 0.5rem;

  min-height: 3rem;

  padding: 0.625rem;

  width: 8.75rem;

  font-size: 1.6875rem;
  text-transform: uppercase;

  display: flex;
  align-items: center;
  justify-content: center;

  transition: all 0.2s;

  cursor: pointer;

  &:hover {
    color: var(--lightGreen);
    border: 2px solid var(--lightGreen);
  }
`;
