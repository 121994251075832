import { createGlobalStyle } from 'styled-components';

export default createGlobalStyle`
  :root {
    --lightGreen: #18E3CF;
    --softBlue: #1FC1CB;
    --blue: #1C5995;
    --softPurple: #CFCBF1;
    --darkPurple: #221551;
    --softGray: #F5F5F5;
    --lightGray: #DBDBDB;
    --gray: #868E96;
  }

  * {
    margin: 0;
    padding: 0;
    box-sizing: border-box;
    border: 0;
    outline: 0;
  }

  body, input, button, textarea{
    font-family: 'Roboto', sans-serif;
    -webkit-font-smoothing: antialiased;
  }

  html {
    font-size: 50%;

    &::-webkit-scrollbar {
      width: 6px;
      padding: 0 1rem;
    }

    &::-webkit-scrollbar-track {
      background-color: #E0E0E0;
      border-radius: 6px;
    }

    &::-webkit-scrollbar-thumb {
      background-color: var(--darkPurple);
      border-radius: 6px;
    }

    @media (min-width: 360px) {
      font-size: 62.5%;
    }

    @media (min-width: 420px) {
      font-size: 68.5%;
    }

    @media (min-width: 608px) {
      font-size: 72.5%;
    }

    @media (min-width: 768px) {
      font-size: 80%;
    }

    @media (min-width: 992px) {
      font-size: 100%;
    }
  }

  @keyframes fade-in {
    from {
      opacity: 0;
    }
    to {
      opacity: 1;
    }
  }

  @keyframes fade-out {
    from {
      opacity: 1;
    }
    to {
      opacity: 0;
    }
  }

  @keyframes spinner {
    100% { transform: rotate(360deg); }
  }

  @keyframes spinner-dot {
    80%, 100% { transform: rotate(360deg); }
  }

  @keyframes spinner-dot-before {
    50% {
      transform: scale(0.4);
    }
    100%, 0% {
      transform: scale(1.0);
    }
  }
`;
