import styled from 'styled-components';

import { ReactComponent as TrashIconRaw } from '../../../assets/images/drivers/drivers-trash-icon.svg';
import { ReactComponent as EyeIconRaw } from '../../../assets/images/drivers/drivers-eye-icon.svg';

export const DriverCardContainer = styled.li`
  width: 100%;
  max-width: 35.6875rem;
  height: fit-content;

  &:not(:first-child) {
    margin-top: 1.875rem;
  }
`;

export const DriverCardArticle = styled.article`
  width: 100%;
  padding: 0.6875rem 0;

  position: relative;

  border-radius: 0.3125rem;
  background-color: #F8F8F8;

  &::before{
    content: '';
    width: 0.25rem;
    height: 60%;

    display: block;

    position: absolute;
    top: calc(20%);
    left: -0.125rem;

    background-color: var(--softBlue);
  }
`;

export const DataDivider = styled.div`
  width: 95%;
  height: fit-content;
  margin: 0 auto;

  display: flex;
  align-items: center;
  justify-content: space-between;
  gap: 1rem;

  white-space: nowrap;

  &:not(:first-child) {
    margin: 0.34375rem auto 0;
  }
`;

export const DriversName = styled.h1`
  flex-grow: 0.5;

  display: block;

  color: var(--softBlue);
  font-size: 1.125rem;

  max-width: 60%;
  width: 100%;
  white-space: normal;
`;

export const ShippingCompany = styled.h2`
  color: var(--darkPurple);
  font-size: 0.75rem;
  text-transform: uppercase;
  white-space: normal;
  margin-right: auto;
`;

export const RemoveButton = styled.button`
  width: fit-content;
  height: fit-content;

  border: 0;
  background-color: transparent;
  cursor: pointer;
`;

export const TrashIcon = styled(TrashIconRaw)`
  width: 0.935625rem;
  height: 1.039375rem;
`;

export const DocsButton = styled.button`
  flex-grow: 0.001;

  background-color: transparent;
  border: 0;
  cursor: pointer;

  text-align: left;

  @media (min-width: 480px) {
    width: 100%;
    max-width: 60%;
  }
`;

export const EyeIcon = styled(EyeIconRaw)`
  width: 0.78125rem;
  height: 0.625rem;
`;

export const DocsText = styled.h2`
  margin-left: 0.3125rem;

  display: inline-block;

  color: var(--blue);
  font-size: 0.75rem;
  text-decoration: underline;
`;

export const DriversLicense = styled.h2`
  position: relative;

  color: var(--gray);
  font-size: 0.75rem;
  margin-right: auto;
`;

export const ApproveButton = styled.button`
  width: fit-content;
  height: fit-content;

  background-color: transparent;
  border: 0;
  cursor: pointer;

  font-size: 0.75rem;
  color: #E38418;
  text-decoration: underline;
`;

export const ApprovedStatus = styled.h2`
  font-size: 0.75rem;
  color: var(--gray);
`;

export const DriverCreatedAt = styled.p`
  position: absolute;
  left: -100%;

  color: var(--gray);
  font-size: 0.75rem;
`;
