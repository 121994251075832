import styled from 'styled-components';

export const SpinnerContainer = styled.div`
  width: 100%;
  min-width: 2rem;
  height: 100%;
  min-height: 2rem;

  display: flex;
  justify-content: center;
  align-items: center;
`;

export const Spinner = styled.div`
  width: 1.8rem;
  height: 1.8rem;

  position: relative;

  animation: spinner-chase 2.5s infinite linear both;
`;

interface SpinnerDotProps {
  color?: string;
}

export const SpinnerDot = styled.div<SpinnerDotProps>`
  width: 100%;
  height: 100%;

  position: absolute;
  left: 0;
  top: 0;

  animation: spinner-dot 2.0s infinite ease-in-out both;

  &:before{
    content: '';
    width: 25%;
    height: 25%;

    display: block;

    background-color: ${(props) => props.color || '#fff'};
    border-radius: 100%;
    animation: spinner-dot-before 2.0s infinite ease-in-out both;
  }

  &:nth-child(1) { animation-delay: -1.1s; }
  &:nth-child(2) { animation-delay: -1.0s; }
  &:nth-child(3) { animation-delay: -0.9s; }
  &:nth-child(4) { animation-delay: -0.8s; }
  &:nth-child(5) { animation-delay: -0.7s; }
  &:nth-child(6) { animation-delay: -0.6s; }
  &:nth-child(1):before { animation-delay: -1.1s; }
  &:nth-child(2):before { animation-delay: -1.0s; }
  &:nth-child(3):before { animation-delay: -0.9s; }
  &:nth-child(4):before { animation-delay: -0.8s; }
  &:nth-child(5):before { animation-delay: -0.7s; }
  &:nth-child(6):before { animation-delay: -0.6s; }
`;
