import styled, { css } from 'styled-components';

import { ReactComponent as PackIconRaw } from '../../../assets/images/chart/chart-pack-icon.svg';
import { ReactComponent as DollarIconRaw } from '../../../assets/images/chart/chart-dollar-icon.svg';
import { ReactComponent as LockerIconRaw } from '../../../assets/images/chart/chart-locker-icon.svg';
import { ReactComponent as WalletIconRaw } from '../../../assets/images/chart/chart-wallet-icon.svg';
import { ReactComponent as CancelIconRaw } from '../../../assets/images/chart/chart-cancel-icon.svg';
import { ReactComponent as SafeMoneyIconRaw } from '../../../assets/images/chart/chart-safe-money-icon.svg';
import { ReactComponent as TruckIconRaw } from '../../../assets/images/chart/chart-truck-icon.svg';

interface PizzaChartContainerProps {
  color: 'white' | 'purple' | 'red';
}

export const ChartContainer = styled.div`
  width: 75%;
  margin: 0.84375em auto 0;
  aspect-ratio: 1;
  // padding: 1em;

  position: relative;

  border-radius: 50%;
`;

export const ChartTitleContainer = styled.div`
  // margin-top: 0.85625em;

  text-align: center;

  font-size: inherit;

  white-space: nowrap;
`;

export const ChartTitle = styled.h1`
  margin-left: 0.35em;

  display: inline-block;

  font-size: 0.75em;
  font-weight: 400;

  vertical-align: middle;
`;

export const ChartInfo = styled.h1`
  width: 100%;
  height: 1em;
  margin-top: 1em;

  display: block;

  font-size: 0.625em;
  font-weight: 400;
  color: #c7c0c0;
  text-align: center;
`;

const white = css`
  background-color: #f5f5f5;

  ${ChartContainer} {
    background: #f5f5f5;
    /* box-shadow: inset 5px 5px 10px #bdbdbd,
                inset -5px -5px 10px #ffffff; */
    box-shadow: inset 0 3px 6px rgba(0, 0, 0, 0.16);
    outline: 1px solid #ffffff30;
  }
  ${ChartTitleContainer} {
    color: var(--darkPurple);

    > svg {
      path {
        fill: var(--darkPurple);
      }
    }
  }

  ${ChartTitle} {
    font-size: 0.875em;
  }

  ${ChartInfo} {
    color: var(--darkPurple);
    font-size: 0.75em;
  }
`;

const purple = css`
  background-color: var(--darkPurple);

  ${ChartContainer} {
    border: 1px solid #ffffff4d;
  }

  ${ChartTitleContainer} {
    color: #c7c0c0;
  }

  ${ChartInfo} {
    color: #C7C0C0;
  }
`;

const red = css`
  background-color: #d88383;

  ${ChartContainer} {
    border: 1px solid #ffffff4d;
  }

  ${ChartTitleContainer} {
    color: #fff;
  }

  ${ChartInfo} {
    color: #fff;
    font-size: 0.75em;
  }
`;

export const PizzaChartContainer = styled.article<PizzaChartContainerProps>`
  width: 10.5em;
  height: fit-content;

  padding: 1.4375em 0;

  border-radius: 1em;
  box-shadow: 0 3px 6px rgba(0, 0, 0, 0.16);

  font-size: 87.5%;

  ${({ color }) => {
    switch (color) {
      case 'white':
        return white;
      case 'purple':
        return purple;
      case 'red':
        return red;
      default:
        return white;
    }
  }}

  @media (min-width: 1400px) {
    font-size: 100%;
  }
`;

export const ChartBackground = styled.img`
  width: 100%;
  height: 100%;

  position: absolute;
  top: 0;
  left: 0;
  z-index: 1;
`;

const borderSvg = "data:image/svg+xml,%3csvg width='100%25' height='100%25' xmlns='http://www.w3.org/2000/svg'%3e%3crect width='100%25' height='100%25' fill='none' rx='100' ry='100' stroke='%23dcdcdc' stroke-width='20' stroke-dasharray='2%2c 2' stroke-dashoffset='86' stroke-linecap='butt'/%3e%3c/svg%3e";

const purpleBorderSvg = "data:image/svg+xml,%3csvg width='100%25' height='100%25' xmlns='http://www.w3.org/2000/svg'%3e%3crect width='100%25' height='100%25' fill='none' rx='100' ry='100' stroke='%23221551' stroke-width='20' stroke-dasharray='2%2c 2' stroke-dashoffset='86' stroke-linecap='butt'/%3e%3c/svg%3e";

const redBorderSvg = "data:image/svg+xml,%3csvg width='100%25' height='100%25' xmlns='http://www.w3.org/2000/svg'%3e%3crect width='100%25' height='100%25' fill='none' rx='100' ry='100' stroke='%23d88383' stroke-width='20' stroke-dasharray='2%2c 2' stroke-dashoffset='86' stroke-linecap='butt'/%3e%3c/svg%3e";

const whiteBackgroundStyle = css`
  background-image: url("${borderSvg}");
`;

const purpleBackgroundStyle = css`
  background-image: url("${purpleBorderSvg}"), linear-gradient(#43386b, #43386b);
`;

const redBackgroundStyle = css`
  background-image: url("${redBorderSvg}"), linear-gradient(#e09494, #e09494);
`;

export const ChartBG = styled.div<PizzaChartContainerProps>`
  width: 90%;
  aspect-ratio: 1;

  position: absolute;
  left: 50%;
  top: 50%;
  transform: translate(-50%, -50%);

  border-radius: 50%;

  ${({ color }) => {
    switch (color) {
      case 'white':
        return whiteBackgroundStyle;
      case 'purple':
        return purpleBackgroundStyle;
      case 'red':
        return redBackgroundStyle;
      default:
        return whiteBackgroundStyle;
    }
  }}
`;

export const PackIcon = styled(PackIconRaw)`
  width: 0.9375em;
  height: 0.9375em;

  vertical-align: middle;
`;

export const DollarIcon = styled(DollarIconRaw)`
  width: 1.048125em;
  height: 0.798125em;

  vertical-align: middle;
`;

export const LockerIcon = styled(LockerIconRaw)`
  width: 0.936875em;
  height: 0.980625em;

  vertical-align: middle;
`;

export const WalletIcon = styled(WalletIconRaw)`
  width: 0.9375em;
  height: 0.864375em;

  vertical-align: middle;
`;

export const CancelIcon = styled(CancelIconRaw)`
  width: 0.9375em;
  height: 0.9375em;

  vertical-align: middle;
`;

export const SafeMoneyIcon = styled(SafeMoneyIconRaw)`
  width: 0.9375em;
  height: 0.9375em;

  vertical-align: middle;
`;

export const TruckIcon = styled(TruckIconRaw)`
  width: 0.9375em;
  height: 0.9375em;

  vertical-align: middle;
`;

export const FilterContainer = styled.div`
  width: 85%;
  height: fit-content;
  margin: 1.09375em auto 0;

  display: flex;
  justify-content: space-between;
`;

export const FilterButton = styled.button`
  padding: 0.2em 0.4em;

  background-color: #2cccd3;
  border-radius: 1em;
  cursor: pointer;

  color: #fff;
  font-size: 0.8em;
`;
