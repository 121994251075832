export const MaskPhone = (value: string) => {
  const phone = value.replace(/\D/g, '').replace(/^0/, '');

  if (phone.length <= 10) {
    return phone
      .replace(/(\d{2})(\d)/, '($1) $2')
      .replace(/(\d{4})(\d)/, '$1-$2')
      .replace(/(\d{4})(\d)$/, '$1');
  }

  return phone
    .replace(/(\d{2})(\d)/, '($1) $2')
    .replace(/(\d{5})(\d)/, '$1-$2')
    .replace(/(\d{4})(\d)$/, '$1');
};
