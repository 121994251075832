import styled from 'styled-components';

export const AddVehicleTypeFormContainer = styled.form`
  width: 98%;
  height: fit-content;
  margin: 1rem auto 0;

  .edit-vehicle__main-label {
    margin: 1.2rem 0 1rem;
  }

  .edit-vehicle__half-input {
    width: 60%;
    max-width: 7rem;
  }

  .edit-vehicle__button {
    margin: 1.8rem 0 0 auto;
  }
`;

export const FormWrapper = styled.div`
  display: flex;
  flex-direction: column;

  @media (min-width: 875px) {
    flex-direction: row;
    gap: 1rem;
    justify-content: space-between;
  }
`;

export const FormDividers = styled.div`
  position: relative;

  &:last-child {
    margin-bottom: 1rem;
  }
`;

export const TypeMeter = styled.p`
  margin: 0 1rem;

  display: inline-block;
`;

export const CapacityWrapper = styled.span`
  white-space: nowrap;

  &:not(:first-child) {
    // margin-top: 0.8rem;

    display: inline-block;
  }

  .capacity-wrapper__margin-left {
    margin-left: 0.75rem;
  }
`;

export const WarningText = styled.h2`
  margin: 0.5rem 0 1rem;

  color: #f00;
  font-size: 0.8rem;
`;

export const BackUpInput = styled.input`
  width: 100%;
  height: 2.375rem;

  border-radius: 0.625rem;
  border: 1px solid var(--gray);

  font-size: 1rem;
`;
