import styled from 'styled-components';
import { Link } from 'react-router-dom';

import { ReactComponent as TruckIconRaw } from '../../assets/images/vehicles/vehicles-truck-icon.svg';

export const VehiclesContainer = styled.section`
  width: 100%;
  height: fit-content;
`;

export const NewVehicleButton = styled(Link)`
  width: 100%;
  max-width: 18.125rem;
  height: 3rem;
  margin: 2.625rem 0 0 auto;

  display: flex;
  align-items: center;
  justify-content: center;

  background-color: var(--darkPurple);
  border-radius: 0.6rem;
  cursor: pointer;
  transition: filter 0.3s ease;

  text-decoration: none;

  &:hover {
    filter: brightness(0.9);
  }
`;

export const TruckIcon = styled(TruckIconRaw)`
  width: 1.326875rem;
  height: 1.1275rem;

  display: inline-block;
`;

export const ButtonText = styled.h2`
  margin-left: 0.60625rem;

  display: inline-block;

  color: #fff;
  font-size: 0.875rem;
`;
