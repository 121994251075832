import styled from 'styled-components';

export const AddAdminFormContainer = styled.form`
  width: 98%;
  height: fit-content;
  margin: 1.375rem auto 0;

  .add-admin__label{
    margin: 0 0.125rem 0.6375rem;
    color: #212529;
  }

  .add-admin__input{
    width: 100%;
    border-radius: 0.3125rem;
    min-height: 2.5rem;

    &::placeholder {
      color: var(--lightGray);
    }
  }

  .add-admin__button{
    margin: 1.8rem 2rem 0 min(55rem, 75%);
    letter-spacing: 0.08em;
    font-weight: 500;
  }
`;

export const FormWrapper = styled.div`
  width: 100%;
  max-width: 52rem;

  display: flex;
  flex-direction: column;

  @media (min-width: 875px) {
    flex-direction: row;
    gap: 1rem;
    justify-content: space-between;
  }
`;

export const FormDividers = styled.div`
  width: 100%;
  max-width: 23.1875rem;
  height: fit-content;
  margin: 0;

  position: relative;

  &:last-child{
    margin-bottom: 1rem;
  }
`;

export const WarningText = styled.p`
  margin: 0.375rem 0 1.5rem;

  color: #f00;
  font-weight: 400;
  font-size: 0.6875rem;
`;
