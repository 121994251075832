import styled from 'styled-components';

import { ReactComponent as DotsIconRaw } from '../../../assets/images/vehicles/vehicle-dots-icon.svg';
import { ReactComponent as CloseIconRaw } from '../../../assets/images/vehicles/vehicle-close-icon.svg';
import { ReactComponent as MapIconRaw } from '../../../assets/images/follow-delivery/map-icon.svg';

export const DeliveryDetailsModalContainer = styled.article`
  width: 100%;
  max-width: 72.375rem;
  // min-height: 49.0625rem;
  height: fit-content;
  overflow: hidden;

  border-radius: 0.625rem;
  background-color: #fff;
`;

export const ModalHeader = styled.header`
  width: 100%;
  height: 4.1875rem;

  display: flex;
  align-items: center;

  background-color: var(--softPurple);
`;

export const DotsIcon = styled(DotsIconRaw)`
  width: 0.375rem;
  height: 1.5rem;
  margin-left: 2.0625rem;
`;

export const DetailsTitle = styled.h1`
  max-width: 20rem;
  max-height: 2.5rem;
  margin-left: 1.5625rem;

  display: inline-block;

  color: var(--darkPurple);
  font-size: 1.125rem;
  font-weight: bold;
  overflow: hidden;
  text-overflow: ellipsis;
`;

export const DetailsLined = styled.div`
  width: fit-content;
  height: 100%;
  margin-left: 0.5rem;

  position: relative;

  font-size: 1.125rem;
  line-height: 4.2rem;
  color: var(--darkPurple);

  &:after {
    content: "";
    width: 100%;
    height: 0.4375rem;

    display: block;

    position: absolute;
    bottom: 0;

    border-radius: 0 0 10rem 10rem;
    background-color: var(--darkPurple);
  }

  @media (min-width: 340px) {
    margin-left: 3rem;
  }
`;

export const CloseButton = styled.button`
  width: fit-content;
  height: fit-content;
  margin: 0 1.9875rem 0 auto;

  cursor: pointer;
  transition: filter 0.3s ease;
  background-color: transparent;
  border: none;

  &:hover {
    filter: brightness(0.9);
  }
`;

export const CloseIcon = styled(CloseIconRaw)`
  width: 1.325625rem;
  height: 1.325625rem;
`;

export const ModalBody = styled.div`
  width: 100%;
  height: fit-content;
  max-height: calc(100vh - 4.1875rem);
  overflow-y: auto;
  overflow-x: hidden;

  scrollbar-width: thin;
  scrollbar-color: var(--darkPurple) #e0e0e0;

  &::-webkit-scrollbar {
    width: 6px;
    padding: 0 1rem;
  }

  &::-webkit-scrollbar-track {
    background-color: #e0e0e0;
    border-radius: 6px;
    margin-bottom: 1rem;
    margin-top: 0.25rem;
  }

  &::-webkit-scrollbar-thumb {
    background-color: var(--darkPurple);
    border-radius: 6px;
  }

  display: flex;
  flex-direction: column;

  @media (min-width: 605px) {
    flex-direction: row;
  }
`;

export const MapContainer = styled.div`
  width: 100%;
  height: fit-content;

  @media (min-width: 605px) {
    width: unset;
    flex: 728;
    min-height: 44.9375rem;

    flex-direction: column;
  }
`;

export const DetailsContainer = styled.div`
  width: 100%;
  height: fit-content;
  margin: 0 auto;
  padding: 1rem;

  display: flex;
  gap: 0.5rem;

  border-radius: 0 0 0 0.625rem;
  box-shadow: 0 3px 6px rgba(0, 0, 0, 0.16);

  @media (min-width: 605px) {
    width: unset;
    flex: 429;
    min-height: 44rem;

    flex-direction: column;
  }
`;

export const TransporterDetails = styled.fieldset`
  width: 95%;
  height: fit-content;
  min-height: 10.12rem;
  margin: 1.0125rem auto 0;

  border-radius: 0.625rem;
  border: 2px solid var(--softPurple);
`;

export const TransporterHeader = styled.div`
  width: 100%;
  height: fit-content;

  display: flex;

  border-bottom: 2px solid var(--softPurple);
`;

export const TransporterDataContainer = styled.div`
  flex: 13.3875;
  height: fit-content;
  min-height: 3.84375rem;
  padding: 0.8125rem 0 0.475rem;

  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 0.2rem;

  border-right: 2px solid var(--softPurple);
`;

export const TruckLabel = styled.h2`
  color: var(--darkPurple);
  font-size: 0.75rem;
  word-wrap: break-word;
`;

export const TruckValue = styled.h2`
  color: var(--darkPurple);
  font-size: 1rem;
  font-weight: bold;
  word-wrap: break-word;
`;

export const ObservationText = styled.h1`
  width: 90%;
  margin: 0.5rem auto 0;

  display: none;

  font-size: 0.625rem;
  font-weight: lighter;

  @media (min-width: 605px) {
    display: block;
  }
`;

export const Observations = styled.p`
  font-size: 0.625rem;
  font-weight: lighter;
  margin: 0.125rem auto 0;
  width: 90%;
  white-space: pre-line;
`;

export const TitleTrackerContainer = styled.div`
  width: 90%;
  margin: 2rem auto 0;
`;

export const TrackerTitle = styled.h1`
  margin-left: 0.51875rem;

  display: inline-block;
  vertical-align: middle;

  color: var(--darkPurple);
  font-size: 1rem;
`;

export const MapIcon = styled(MapIconRaw)`
  width: 1.25rem;
  height: 1.171875rem;

  vertical-align: middle;
`;
