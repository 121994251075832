import styled from 'styled-components';

import { ReactComponent as TimelineIconRaw } from '../../../assets/images/dashboard/dashboard-timeline-icon.svg';
import { ReactComponent as TrophyIconRaw } from '../../../assets/images/dashboard/dashboard-trophy-icon.svg';
import { ReactComponent as ActivityIconRaw } from '../../../assets/images/dashboard/dashboard-activity-icon.svg';

export const DashboardRow = styled.div`
  width: 100%;
  height: fit-content;
  // margin-top: 1.4rem;

  display: flex;
  flex-direction: column;
  gap: 1.8rem;
  justify-content: space-between;
  align-items: center;

  @media (min-width: 985px) {
    flex-direction: row;
    gap: 0;
    align-items: unset;
  }
`;

export const PiesChartsContainer = styled.div`
  width: 100%;
  height: fit-content;

  .pies-charts-container__filters-selector {
    margin: 0rem 0 1rem;
  }
`;

export const SectionTitleContainer = styled.div`
  width: 100%;
  height: fit-content;
  margin-bottom: 1.1rem;

  white-space: nowrap;

  display: flex;
  align-items: center;

  position: relative;
`;

export const SectionTitle = styled.h1`
  display: inline-block;

  color: var(--darkPurple);
  font-size: 1.125rem;
  font-weight: 500;

  white-space: normal;

  @media (min-width: 1180px) {
    white-space: nowrap;
  }
`;

export const SectionTitleNote = styled.span`
  font-size: 0.625rem;
  color: var(--darkPurple);
  font-weight: 400;

  position: absolute;

  bottom: -0.625rem;
  right: 50%;
  transform: translateX(50%);
`;

export const TotalRevenueContainer = styled.div`
  width: fit-content;
  height: fit-content;
  margin-right: 1rem;

  .total-revenue-container__filters-selector {
    margin: 0.625rem 0 2rem;
  }
`;

export const ChartContainer = styled.div`
  width: 100%;
  max-width: 54.0625rem;
  height: fit-content;
  margin-top: 2.25rem;
`;

export const TimelineIcon = styled(TimelineIconRaw)`
  width: 1.6875rem;
  height: 1.6875rem;
  margin-right: 0.5rem;

  display: inline-block;
`;

export const TrophyIcon = styled(TrophyIconRaw)`
  width: 1.5rem;
  height: 1.875rem;
  margin-right: 0.5rem;

  display: inline-block;
`;

export const ActivityIcon = styled(ActivityIconRaw)`
  width: 1.3125rem;
  height: 1.5rem;
  margin-right: 0.5rem;

  display: inline-block;
`;
