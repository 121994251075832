import { StrictMode } from 'react';
import { BrowserRouter } from 'react-router-dom';
import { Toaster } from 'react-hot-toast';

import { AuthProvider } from '../../../contexts/Auth/Auth';
import { Layout } from '../Layout';

import GlobalStyles from '../../../assets/styles/GlobalStyles';

export const App = () => (
  <StrictMode>
    <AuthProvider>
      <BrowserRouter>
        <Toaster />
        <GlobalStyles />
        <Layout />
      </BrowserRouter>
    </AuthProvider>
  </StrictMode>
);
