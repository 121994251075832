import { ParentSize } from '@visx/responsive';
import { Group } from '@visx/group';
import { Pie } from '@visx/shape';
import { Text } from '@visx/text';

import {
  BlueCard,
  ChartBG,
  ChartContainer,
  PurpleCard,
  TotalRevenueChartContainer,
} from './styles';
import { ITotalChartProps } from './interfaces';

const defaultData = [
  { value: 0, label: 'Total', color: '#18E3CF' },
];

export const TotalChart = ({ unit, data: cardData }: ITotalChartProps) => {
  const data = cardData ? [
    { value: cardData?.value, label: 'Total', color: '#18E3CF' },
  ] : defaultData;

  return (
    <TotalRevenueChartContainer>
      <ChartContainer>
        <ChartBG />
        <ParentSize>
          {({ width }) => {
            const half = Math.round(width / 2);

            return (
              <svg
                width={width}
                height={width}
                style={{
                  position: 'relative', zIndex: 2,
                }}
              >
                <Group top={half} left={half}>
                  <Pie
                    data={data}
                    pieValue={(content) => content.value}
                    outerRadius={half * 0.90}
                    innerRadius={half * 0.75}
                    padAngle={0.01}
                  >
                    {(pie) => pie?.arcs?.map((arc) => {
                      const d = pie.path(arc) || '';

                      return (
                        <g
                          key={arc.data.label}
                        >
                          <path d={d} fill={arc.data.color} />
                        </g>
                      );
                    })}
                  </Pie>
                  <Text
                    textAnchor="middle"
                    fill="#221551"
                    fontSize="1.25em"
                    fontWeight={500}
                    dy="-0.1rem"
                  >
                    {data[0].value.toLocaleString('pt-BR', {
                      maximumFractionDigits: 0,
                    })}
                  </Text>
                  <Text
                    textAnchor="middle"
                    fill="#22155181"
                    fontSize="0.875em"
                    fontWeight={400}
                    dy="0.9em"
                  >
                    {unit || 'reais'}
                  </Text>
                </Group>
              </svg>
            );
          }}
        </ParentSize>
      </ChartContainer>
      <PurpleCard />
      <BlueCard />
    </TotalRevenueChartContainer>
  );
};
