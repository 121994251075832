import styled from 'styled-components';

export const DetailsDataContainer = styled.div`
  width: 100%;
  height: fit-content;
  min-height: 10.12rem;
  padding: 1rem 0;

  display: none;

  background-color: #cfcbf14d;

  @media (min-width: 605px) {
    display: block;
  }
`;

export const DetailsData = styled.div`
  width: 90%;
  height: fit-content;
  margin: 0 auto;

  display: flex;
  justify-content: space-between;
  gap: 0.5rem;

  &:not(:first-child) {
    margin: 0.4375rem auto 0;
  }

  > * {
    width: 50%;
  }
`;

export const DetailsLabel = styled.h2`
  display: inline-block;

  color: var(--darkPurple);
  font-size: 0.75rem;
`;

export const DetailsValue = styled.h2`
  display: inline-block;

  color: var(--darkPurple);
  font-size: 0.875rem;
  font-weight: bold;
  text-transform: uppercase;
`;
