import styled from 'styled-components';

export const VehicleTypeCardContainer = styled.ul`
  width: 100%;
  height: fit-content;

  &:first-child {
    margin-top: 3.0625rem;
  }

  &:not(:first-child) {
    margin-top: 6.66875rem;
  }
`;

export const VehicleCard = styled.article`
  width: 100%;
  height: fit-content;
  min-height: 10.70875rem;

  display: flex;
  flex-direction: column;
  gap: 0.2rem;

  position: relative;
  z-index: 1;

  background-color: var(--softGray);
  border-radius: 1rem;
  box-shadow: -1px 3px 6px rgba(0, 0, 0, 0.16);

  @media (min-width: 675px) {
    flex-direction: row;
    gap: 1.9375rem;
  }
`;

export const CardBackground = styled.div`
  width: 12%;

  position: absolute;
  top: 0;
  left: 0;
  bottom: 0;
  z-index: -1;

  background-color: #2CCCD3;
  border-radius: 1rem 0 0 1rem;

  &:before {
    content: '';
    width: 300%;

    display: block;

    position: absolute;
    top: 0;
    right: -300%;
    bottom: 0;

    background: rgb(44,204,211);
    background: linear-gradient(75deg, rgba(44,204,211,1) 50%, rgba(255,255,255,0) 50%);
  }

  @media (min-width: 675px) {
    &:before {
      width: 210%;

      right: -180%;

      background: linear-gradient(60deg, rgba(44,204,211,1) 50%, rgba(255,255,255,0) 50%);
    }
  }

  @media (min-width: 890px) {
    &:before {
      background: linear-gradient(45deg, rgba(44,204,211,1) 50%, rgba(255,255,255,0) 50%);
    }
  }
`;

export const VehicleImage = styled.img`
  width: 16.125rem;
  height: 11.1875rem;
  margin-right: 3.6875rem;

  position: relative;
  top: -3.0625rem;
  left: 3.6875rem;

  object-fit: cover;
  border-radius: 0.9rem;
  box-shadow: 0 1px 3px rgba(0, 0, 0, 0.1);
  background-color: #fff;
`;

export const ButtonsContainer = styled.div`
  margin: 0 2rem 0 auto;
  padding: 0;

  @media (min-width: 675px){
    margin: unset;
    padding: 1.375rem 0 0;
  }
`;

export const ButtonHandler = styled.button`
  width: fit-content;
  height: fit-content;

  display: block;

  background-color: transparent;
  border: 0;
  cursor: pointer;

  &:not(:first-child){
    margin-top: 1.6875rem;
  }
`;

export const IconContainer = styled.span`
  width: fit-content;
  height: fit-content;

  display: inline-block;

  > svg {
    width: auto;
    height: 1.25rem;
  }
`;

export const HandlerText = styled.h2`
  margin-left: 0.5rem;

  display: inline-block;

  color: var(--gray);
  font-size: 1.125rem;
`;

export const DetailsContainer = styled.div`
  margin: 0 0 0 auto;
  padding: 1.625rem 0 1.4375rem;

  display: flex;
  flex-direction: column;
  justify-content: space-between;
  align-items: flex-end;
`;

export const TypeName = styled.h1`
  max-width: 16rem;
  max-height: 5.4rem;
  margin-right: 1.875rem;

  display: inline-block;

  overflow: hidden;
  text-overflow: ellipsis;
  color: var(--gray);
  font-size: 1.5rem;
`;

export const DetailsButton = styled.button`
  width: 14.9375rem;
  height: 2.8125rem;

  position: relative;
  left: 1.089375rem;

  background-color: var(--blue);
  cursor: pointer;
  transition: filter 0.2s ease;

  color: #fff;
  font-size: 1.125rem;
  font-weight: bold;

  &:before{
    content: '';
    width: 1.3rem;

    display: block;

    position: absolute;
    top: 0;
    bottom: 0;
    left: -1.3rem;

    background-color: var(--blue);
    background: linear-gradient(115deg, rgba(255,255,255,0) 50%, rgba(28,89,149,1) 50%);
  }

  &:after{
    content: '';
    width: 1.089375rem;
    height: 1.17rem;

    display: block;

    position: absolute;
    top: -1.17rem;
    bottom: 0;
    right: 0;

    background: rgb(34,21,81);
    background: linear-gradient(45deg, rgba(34,21,81,1) 50%, rgba(255,255,255,0) 50%);
  }

  &:hover {
    filter: brightness(0.9);
  }
`;
