import styled from 'styled-components';

interface IModalPortalProps {
  close?: boolean;
}

export const ModalPortal = styled.section<IModalPortalProps>`
  width: 100%;
  height: 100%;

  display: flex;
  justify-content: center;
  align-items: center;

  position: fixed;
  inset: 0;
  z-index: 100;

  background-color: rgba(0, 0, 0, 0.4);
  animation: fade-in 0.3s ease;

  ${({ close }) => close && 'animation: fade-out 0.3s ease;'}
`;
