import styled from 'styled-components';

import { ReactComponent as PlusUserRaw } from '../../assets/images/new-admin/new-admin-plus-user.svg';

export const AddAdminContainer = styled.section`
  width: 100%;
  height: fit-content;
`;

export const SubTitle = styled.span`
  width: 98%;
  margin: 2.3125rem 0.8125rem;

  display: block;
`;

export const PlusUser = styled(PlusUserRaw)`
  width: 1.6875rem;
  height: 1.5rem;

  display: inline-block;
`;

export const SubText = styled.h2`
  margin-left: 0.875rem;

  display: inline-block;

  color: var(--darkPurple);
  font-size: 1.125rem;
`;
