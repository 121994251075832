import { format, parseISO } from 'date-fns';

import { TripStatus } from '../../../pages/FollowDelivery/interfaces';
import { EnsureDate } from '../../../utils';
import { IDeliveryDetailsCardProps } from './interfaces';
import {
  DetailsData,
  DetailsDataContainer,
  DetailsLabel,
  DetailsValue,
} from './styles';

export const DeliveryDetailsCard = ({
  delivery,
  deliveryDate,
}: IDeliveryDetailsCardProps) => {
  const {
    created_at,
    initial_address: { address: initial_address },
    delivery_address: { address: delivery_address },
    status_marks,
  } = delivery;

  const collectionStatusMark = status_marks.find(
    (mark) => mark.status_id === TripStatus.Collecting,
  );
  const deliveryStatusMark = status_marks.find(
    (mark) => mark.status_id === TripStatus.Finished,
  );

  const createdAt = parseISO(created_at);
  const collectionFormattedDate = collectionStatusMark
    ? format(parseISO(collectionStatusMark.created_at), 'dd/MM/yyyy')
    : undefined;
  const collectionHour = collectionStatusMark
    ? format(parseISO(collectionStatusMark.created_at), 'HH:mm')
    : undefined;
  const deliveryFormattedDate = deliveryStatusMark
    ? format(parseISO(deliveryStatusMark.created_at), 'dd/MM/yyyy')
    : undefined;
  const deliveryHour = deliveryStatusMark
    ? format(parseISO(deliveryStatusMark.created_at), 'HH:mm')
    : undefined;

  return (
    <DetailsDataContainer>
      <DetailsData>
        <DetailsLabel>Data:</DetailsLabel>
        <DetailsValue>{format(createdAt, 'dd/MM/yyyy')}</DetailsValue>
      </DetailsData>
      <DetailsData>
        <DetailsLabel>Horário:</DetailsLabel>
        <DetailsValue>{format(createdAt, 'HH:mm')}</DetailsValue>
      </DetailsData>

      {collectionStatusMark && (
        <>
          <DetailsData>
            <DetailsLabel>Data de coleta:</DetailsLabel>
            <DetailsValue>{collectionFormattedDate}</DetailsValue>
          </DetailsData>
          <DetailsData>
            <DetailsLabel>Horário de coleta:</DetailsLabel>
            <DetailsValue>{collectionHour}</DetailsValue>
          </DetailsData>
        </>
      )}
      <DetailsData>
        <DetailsLabel>Endereço de coleta:</DetailsLabel>
        <DetailsValue>
          {initial_address.street}, {initial_address.number} -{' '}
          {initial_address?.city?.name || ''},{' '}
          {initial_address?.city?.state?.uf}
        </DetailsValue>
      </DetailsData>

      {deliveryStatusMark && (
        <>
          <DetailsData>
            <DetailsLabel>Data de entrega:</DetailsLabel>
            <DetailsValue>{deliveryFormattedDate}</DetailsValue>
          </DetailsData>
          <DetailsData>
            <DetailsLabel>Horário de entrega:</DetailsLabel>
            <DetailsValue>{deliveryHour}</DetailsValue>
          </DetailsData>
        </>
      )}
      <DetailsData>
        <DetailsLabel>Endereço de entrega:</DetailsLabel>
        <DetailsValue>
          {delivery_address.street}, {delivery_address.number} -{' '}
          {delivery_address?.city?.name || ''},{' '}
          {delivery_address?.city?.state?.uf}
        </DetailsValue>
      </DetailsData>
      {/* <DetailsData>
        <DetailsLabel>Data prevista:</DetailsLabel>
        <DetailsValue>
          {deliveryDate ? deliveryDate.toLocaleDateString() : '-'}
        </DetailsValue>
      </DetailsData>
      <DetailsData>
        <DetailsLabel>Horário previsto:</DetailsLabel>
        <DetailsValue>
          {deliveryDate
            ? deliveryDate.toLocaleTimeString().substring(0, 5)
            : '-'}
        </DetailsValue>
      </DetailsData> */}
    </DetailsDataContainer>
  );
};
