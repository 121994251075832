import { EnsureDate } from '../../../utils';
import { statusDef } from './utils/status';

import Placeholder from '../../../assets/images/follow-delivery/placeholder.jpg';

import { IDeliveryCardProps } from './interfaces';
import {
  DeliveryCardContainer, DeliverData,
  DeliverTitle, GradientBg,
  BlueBg, DeliverLogo,
  DetailsButton,
} from './styles';

export const DeliveryCard = ({
  delivery,
  onClick,
}: IDeliveryCardProps) => (
  <DeliveryCardContainer>
    <BlueBg />
    <GradientBg />
    <DeliverLogo
      src={delivery?.responsible?.avatar_url || Placeholder}
      alt={delivery?.id}
      onError={(e) => {
        e.currentTarget.src = Placeholder;
      }}
    />
    <div>
      <DeliverTitle>
        {delivery.order}
      </DeliverTitle>
      <DeliverData>
        Status: {statusDef[delivery.status_id]}
      </DeliverData>
      <DeliverData>
        Última atualização em {EnsureDate(delivery?.updated_at)}
      </DeliverData>
      <DetailsButton type="button" onClick={() => onClick(delivery?.id)}>
        Mais Informações
      </DetailsButton>
    </div>
  </DeliveryCardContainer>
);
