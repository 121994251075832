import { ActivityIcon, TimelineIcon, TrophyIcon } from './styles';

export const financialDashboardContent = {
  firstSectionTitle: {
    label: 'Receita Bruta Vagoo',
    icon: <TimelineIcon />,
  },
  secondSectionTitle: {
    label: 'Receita Bruta Total',
    icon: <TrophyIcon />,
    note: '* Valor já com impostos descontados',
  },
  chartSectionTitle: {
    label: 'Receita Bruta Total x Receita Bruta Vagoo',
    icon: <ActivityIcon />,
  },
};
