import { ChangeEvent, useEffect, useState } from 'react';

import toast from 'react-hot-toast';
import { BaseSelect } from '../../components/atoms/BaseSelect';
import { LoadingSpinner } from '../../components/atoms/LoadingSpinner/LoadingSpinner';
import { PageTitle } from '../../components/atoms/PageTitle';
import { DeliveryDetailsModal } from '../../components/organisms/DeliveryDetailsModal';
import { ListDeliveries } from '../../components/organisms/ListDeliveries';
import { ModalRoot } from '../../components/organisms/ModalRoot';
import { useInfiniteScroll } from '../../hooks/useInfiniteScroll';

import { api } from '../../services/api';
import { IDelivery, IGetTripsResponse, TripStatus } from './interfaces';
import {
  FilterInput,
  FilterInputContainer,
  FollowDeliveryContainer,
  HeaderFilters,
  LoadMoreContainer,
  SearchIcon,
} from './styles';

const statusOptions = [
  {
    value: '',
    label: 'Selecione um status',
  },
  {
    value: TripStatus.Finished.toString(),
    label: 'Entregue',
  },
  {
    value: TripStatus.Canceled.toString(),
    label: 'Cancelada',
  },
  {
    value: TripStatus.Collecting.toString(),
    label: 'Coleta',
  },
  {
    value: TripStatus.OnGoing.toString(),
    label: 'Viagem',
  },
  {
    value: TripStatus.Pending.toString(),
    label: 'Pendente',
  },
  {
    value: TripStatus.waitingPayment.toString(),
    label: 'Aguardando Pagamento',
  },
];

export const FollowDelivery = () => {
  const [deliveries, setDeliveries] = useState<IDelivery[]>([]);
  const [selectedDeliveryId, setSelectedDeliveryId] = useState<string>();
  const [showModal, setShowModal] = useState(false);
  const [orderFilter, setOrderFilter] = useState('');
  const [selectedStatus, setSelectedStatus] = useState('');
  const [total, setTotal] = useState(0);
  const [isLoading, setIsLoading] = useState(false);

  const {
    page, loadMoreRef, maximumPage, setMaximumPage, setPage,
  } = useInfiniteScroll();

  const fetchDeliveries = async () => {
    if (page === 1) {
      setIsLoading(true);
    }

    try {
      const response = await api.get<IGetTripsResponse>('/trips', {
        params: {
          page,
          order: orderFilter || undefined,
          statusId: selectedStatus || undefined,
        },
      });

      setTotal(response.data.tripList.total);

      if (page > 1) {
        setDeliveries((prevState) => [
          ...prevState,
          ...response.data.tripList.results,
        ]);
      } else {
        setDeliveries(response.data.tripList.results);
      }

      setMaximumPage(
        response.data.tripList.total / response.data.tripList.limit,
      );
    } catch (error: any) {
      toast.error(
        error?.response?.data?.message
          || error?.message
          || 'Algo deu errado, tente novamente mais tarde!',
      );
    } finally {
      setIsLoading(false);
    }
  };

  useEffect(() => {
    fetchDeliveries();
  }, [page, orderFilter, selectedStatus]);

  const handleSelectDelivery = (deliveryId: string) => {
    setSelectedDeliveryId(deliveryId);
    setShowModal(true);
  };

  const handleChange = (event: ChangeEvent<HTMLInputElement>) => {
    const { value } = event.target;
    setOrderFilter(value);
    setPage(1);
    setMaximumPage(1);
  };

  const selectedDelivery = deliveries.find(
    (delivery) => delivery.id === selectedDeliveryId,
  );

  return (
    <FollowDeliveryContainer>
      <PageTitle>Acompanhar Corrida</PageTitle>
      <HeaderFilters>
        <p style={{ marginBottom: '0.875rem' }}>Total de viagens: {total}</p>
        <BaseSelect
          options={statusOptions}
          value={selectedStatus}
          onChange={(e) => {
            setSelectedStatus(e.target.value);
            setPage(1);
            setMaximumPage(1);
          }}
          placeholder="Selecione um status"
        />
        <FilterInputContainer>
          <FilterInput
            type="text"
            placeholder="Pesquisar Cargas"
            value={orderFilter}
            onChange={handleChange}
          />
          <SearchIcon />
        </FilterInputContainer>
      </HeaderFilters>
      {isLoading ? (
        <div style={{ marginTop: '2rem' }}>
          {' '}
          <LoadingSpinner color="var(--darkPurple)" />
        </div>
      ) : (
        <ListDeliveries
          deliveries={deliveries}
          handleShowDetails={handleSelectDelivery}
        />
      )}
      {maximumPage > page && <LoadMoreContainer ref={loadMoreRef} />}
      {showModal && selectedDelivery && (
        <ModalRoot setShowModal={setShowModal}>
          <DeliveryDetailsModal delivery={selectedDelivery} />
        </ModalRoot>
      )}
    </FollowDeliveryContainer>
  );
};
