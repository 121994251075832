import styled from 'styled-components';

import { ReactComponent as CloseIconRaw } from '../../../assets/images/drivers/drivers-close-icon.svg';

interface NextImageProps {
  selected: boolean;
}

export const DriverDocsContainer = styled.div`
  width: 98%;
  max-width: 58.75rem;
  height: fit-content;

  position: relative;

  border-radius: 1.625rem;
  background-color: #fff;
`;

export const CloseButton = styled.button`
  width: fit-content;
  height: fit-content;

  position: absolute;
  top: 1.0625rem;
  right: 1.30625rem;

  background-color: transparent;
  cursor: pointer;
`;

export const CloseIcon = styled(CloseIconRaw)`
  width: 0.9375rem;
  height: 0.9375rem;
`;

export const DriverDocsWrapper = styled.div`
  width: 85%;
  height: fit-content;
  margin: 0 auto;
  padding: 2.6rem 0 2rem;

  display: flex;
  flex-direction: column;
  gap: 2rem;
  align-items: center;
  justify-content: space-between;

  @media (min-width: 650px) {
    flex-direction: row;
  }
`;

export const ImageContainer = styled.div`
  width: fit-content;
  height: fit-content;
  max-height: 80vh;
  overflow-y: auto;
  padding: 1.5rem;

  background-color: var(--softGray);
  border-radius: 0.625rem;
`;

export const CurrentImage = styled.img`
  width: 100%;
  max-width: 28.875rem;
  min-width: 20rem;
  height: auto;

  object-fit: cover;
  border-radius: 0.625rem;
`;

export const ImagesListWrapper = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: space-between;
  gap: 2rem;
  width: 100%;
  flex: 1;
  max-height: 100%;
  max-width: 300px;
`;

export const ImagesList = styled.ul`
  width: fit-content;
  max-width: 18.75rem;
  height: 90vw;
  max-height: 9.1875rem;
  padding: 1.125rem;

  /* display: flex;
  flex-direction: row;
  align-items: center;
  gap: 1.4rem; */

  display: grid;
  // should accommodate a maximum of two columns inside the grid but should resize accordingly if there is only one column, instead of creating an empty space
  grid-template-columns: repeat(auto-fill, minmax(40%, 1fr));

  grid-gap: 1rem;
  grid-column-gap: 0rem;


  background-color: var(--softGray);
  list-style-type: none;
  border-radius: 0.625rem;

  @media (min-width: 650px) {
    // width: 9.1875rem;
    height: fit-content;
    max-height: 30rem;
    padding: 2.625rem 1.5rem;

    overflow: auto;

    // flex-direction: column;
  }
`;

export const ImageItem = styled.li`
  width: fit-content;
  height: fit-content;
  margin: 0 auto;
`;

export const ImageButton = styled.button`
  background-color: transparent;
`;

export const NextImage = styled.img<NextImageProps>`
  width: 14vw;
  max-width: 6.25rem;
  height: auto;
  max-height: 8rem;

  cursor: pointer;
  overflow: hidden;
  object-fit: cover;
  border-radius: 0.625rem;
  background-color: transparent;
  transition: all 0.2s ease;

  ${({ selected }) => (selected
    && `
      border: 0.1rem solid var(--softBlue);
      padding: 0.3rem;
    `)
}
`;

export const ApprovalButtonsContainer = styled.div`
  background-color: transparent;
  display: flex;
  align-items: flex-end;
  gap: 5rem;
  width: 100%;
`;

export const RejectButton = styled.button`
  background-color: transparent;
  color: var(--softBlue);

  font-size: 0.75rem;
  font-weight: 500;
  text-decoration: underline;
  text-transform: uppercase;
  letter-spacing: 0.08em;

  cursor: pointer;

  transition: 0.2s filter;

  &:hover {
    filter: brightness(0.9);
  }
`;

export const ApproveButton = styled.button`
  background-color: var(--softBlue);
  color: #fff;

  width: 100%;
  min-height: 2.4375rem;

  display: flex;
  align-items: center;
  justify-content: center;

  font-size: 0.75rem;
  font-weight: 500;
  text-transform: uppercase;
  letter-spacing: 0.08em;

  box-shadow: 0px 3px 6px rgba(0, 0, 0, 0.16);
  border-radius: 0.625rem;

  cursor: pointer;

  transition: 0.2s filter;

  &:hover {
    filter: brightness(0.9);
  }
`;
