import styled from 'styled-components';

export const TrackerSelectContainer = styled.div`
  width: 90%;
  margin: 3.5rem auto 0;
`;

export const TrackerSelect = styled.select`
  width: 100%;
  max-width: 19.1875rem;
  height: 1.8rem;
  padding: 0 0.5rem;

  border-radius: 0.3125rem;
  background-color: #F5F5F5;

  font-size: 0.7rem;
`;
