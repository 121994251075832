import { useEffect } from 'react';
import { yupResolver } from '@hookform/resolvers/yup';
import { SubmitHandler, useForm } from 'react-hook-form';
import toast from 'react-hot-toast';
import useAuth from '../../hooks/useAuth';

import { PageTitle } from '../../components/atoms/PageTitle';
import { SettingsForm } from '../../components/organisms/SettingsForm';

import { schema } from './validation';
import { api } from '../../services/api';

import {
  IAvatarRequest, IRequest,
  ISettingsFormData, ISettingsFormDataKeys,
} from './interfaces';
import { SettingsContainer } from './styles';
import { IUser } from '../../contexts/Auth/interfaces';

export const Settings = () => {
  const { user, setUser } = useAuth();

  const {
    register,
    handleSubmit,
    setValue,
    formState: { isSubmitting, errors, isDirty },
  } = useForm<ISettingsFormData>({
    resolver: yupResolver(schema),
  });

  const requestPayload = {} as IRequest;
  const avatarRequestPayload = {} as IAvatarRequest;

  const onSubmit: SubmitHandler<ISettingsFormData> = async (data) => {
    const fileList = data.image;
    const image = fileList[0];

    const particularlyKeys = [
      'image', 'first_name', 'last_name',
    ];

    Object.keys(data)?.forEach((key) => {
      if (!particularlyKeys.includes(key)) {
        if (data[key as ISettingsFormDataKeys]) {
          Object.assign(requestPayload, {
            [key]: data[key as ISettingsFormDataKeys],
          });
        }
      }
    });

    Object.assign(requestPayload, {
      name: `${data.first_name} ${data.last_name}`,
    });

    try {
      await api.put('/admin', requestPayload);

      if (image) {
        const formData = new FormData();

        formData.append('avatar', image);

        const response = await api.patch('/users/avatar/', formData);

        avatarRequestPayload.avatar = response?.data?.avatar;
        avatarRequestPayload.avatar_url = response?.data?.avatar_url;

        setUser(ensureUserAvatar);
      }

      setUser(ensureUserData);

      toast.success('Dados atualizados com sucesso!');
    } catch (error: any) {
      toast.error(error?.response?.data?.message || error?.message);
    }
  };

  const ensureUserData = (prevState: IUser) => ({
    ...prevState,
    email: requestPayload?.email || prevState.email,
    cellphone: requestPayload?.cellphone || prevState.cellphone,
    individual_person: {
      ...prevState.individual_person,
      name: requestPayload?.name || prevState.individual_person.name,
    },
  });

  const ensureUserAvatar = (prevState: IUser) => ({
    ...prevState,
    avatar: avatarRequestPayload?.avatar || prevState.avatar,
    avatar_url: avatarRequestPayload?.avatar_url || prevState.avatar_url,
  });

  const ensureSettingsData = () => {
    const { email, cellphone, individual_person } = user;

    const responsible_name = individual_person?.name || '';

    const [first_name, ...rest] = responsible_name.split(' ');
    const last_name = rest.join(' ');

    setValue('first_name', first_name);
    setValue('last_name', last_name);
    setValue('email', email);
    setValue('cellphone', cellphone);
  };

  useEffect(() => {
    ensureSettingsData();
  }, []);

  return (
    <SettingsContainer>
      <PageTitle>
        Configurações de Perfil
      </PageTitle>
      <SettingsForm
        onSubmit={handleSubmit(onSubmit)}
        {...{
          register,
          setValue,
          errors,
          isSubmitting,
          isDirty,
          imageUrl: user?.avatar_url,
        }}
      />
    </SettingsContainer>
  );
};
