import styled from 'styled-components';

export const MainContent = styled.main`
  width: calc (100% - 1.2rem);
  height: fit-content;
  margin: 1.2rem 0 5.5rem 0;
  padding: 1rem 0;

  @media (min-width:632px) and (min-height: 450px) {
    margin: 1.2rem 0 0 7.5rem;
  }
`;

export const MainWrapper = styled.div`
  width: 98%;
  max-width: 84rem;
  height: fit-content;
  margin: 0 auto;
`;
