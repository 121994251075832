import { IDeliveryChargeDetailsCardProps } from './interfaces';
import {
  ChangeHeader, ChargeDetails, ChargeTitle,
  ChargeContent, ChargeLabel, ChargeValue,
} from './styles';

const formatCurrency = new Intl.NumberFormat('pt-BR', {
  style: 'currency',
  currency: 'BRL',
}).format;

export const DeliveryChargeDetailsCard = ({ delivery }: IDeliveryChargeDetailsCardProps) => (
  <ChargeDetails>
    <ChangeHeader>
      <ChargeTitle>
        Dados da carga
      </ChargeTitle>
    </ChangeHeader>
    <ChargeContent>
      <div>
        <ChargeLabel>
          Tipo da carga
        </ChargeLabel>
        <ChargeValue>
          {delivery.cargo_type}
        </ChargeValue>
      </div>
      <div>
        <ChargeLabel>
          Peso da carga
        </ChargeLabel>
        <ChargeValue>
          {delivery?.vehicle?.vehicle_type?.weight?.name || '-'}
        </ChargeValue>
      </div>
      <div>
        <ChargeLabel>
          Valor da carga
        </ChargeLabel>
        <ChargeValue>
          {formatCurrency(delivery.cargo_value)}
        </ChargeValue>
      </div>
    </ChargeContent>
  </ChargeDetails>
);
