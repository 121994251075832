import styled from 'styled-components';

export const FiltersContainer = styled.div`
  height: fit-content;

  display: flex;
  flex-direction: row;
  align-items: center;
  gap: 0.75rem;
`;

export const FilterButton = styled.button<{
  active: boolean;
}>`
  padding: 0.375em 0.625em;
  min-width: 3.125rem;

  background-color: #2CCCD3;
  border-radius: 1em;
  cursor: pointer;

  color: #fff;
  font-size: 0.6875rem;

  display: flex;
  justify-content: center;
  align-items: center;

  ${({ active }) => active
    && `
    background-color: #0A76AE;
  `}

  transition: background-color 0.2s ease-in-out;

  &:hover {
    background-color: #0A76AE;
  }

`;
