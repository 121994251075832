import * as yup from 'yup';

export const schema = yup.object({
  first_name: yup.string()
    .required('Nome obrigatório')
    .matches(/^[aA-zZ\s]+$/, 'Apenas letras maiúsculas e minúsculas'),
  last_name: yup.string()
    .required('Sobrenome obrigatório')
    .matches(/^[aA-zZ\s]+$/, 'Apenas letras maiúsculas e minúsculas'),
  cellphone: yup.string()
    .required('Celular obrigatório')
    .matches(/^[\d()\s-]+$/, 'Apenas números'),
  email: yup.string()
    .email('Insira um e-mail válido')
    .required('E-mail obrigatório'),
  password: yup.string()
    .required('Insira números, letras minúsculas e maiúsculas e caracteres especiais'),
  password_confirmation: yup.string()
    .required('Repita sua senha')
    .oneOf([yup.ref('password'), null], 'Confirmação divergente'),
});
