import { forwardRef } from 'react';
import { IConfirmationModalProps } from './interfaces';
import {
  ButtonsContainer,
  CloseButton,
  ConfirmationModalContainer,
  Message,
  ModalBody,
  OutlinedButton,
} from './styles';

export const ConfirmationModal = forwardRef<
  HTMLElement,
  IConfirmationModalProps
>(
  (
    {
      closeModal, message, cancelText, confirmText, onCancel, onConfirm,
    },
    ref,
  ) => (
    <ConfirmationModalContainer ref={ref}>
      <CloseButton type="button" onClick={closeModal}>
        &#10006;
      </CloseButton>
      <ModalBody>
        <Message>{message}</Message>
        <ButtonsContainer>
          <OutlinedButton type="button" onClick={onConfirm}>
            {confirmText}
          </OutlinedButton>
          <OutlinedButton type="button" onClick={onCancel}>
            {cancelText}
          </OutlinedButton>
        </ButtonsContainer>
      </ModalBody>
    </ConfirmationModalContainer>
  ),
);
