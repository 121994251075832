import styled from 'styled-components';

export const SettingsFormContainer = styled.form`
  width: 98%;
  height: fit-content;
  margin: 1.375rem auto 0;

  .settings__label {
    margin: 0 0.125rem 0.6375rem;
    color: #212529;
  }

  .settings__input {
    width: 100%;

    border-radius: 0.3125rem;
    min-height: 2.5rem;

    &::placeholder {
      color: var(--lightGray);
    }
  }

  .settings__button {
    margin: 1.8rem 2rem 0 auto;
  }
`;

export const FormWrapper = styled.div`
  width: 100%;
  max-width: 70rem;

  display: flex;
  flex-direction: column;
  align-items: center;

  @media (min-width: 875px) {
    flex-direction: row;
    gap: 1rem;
    justify-content: space-between;
    align-items: unset;
  }
`;

export const FormDividers = styled.div`
  width: 100%;
  max-width: 27rem;
  height: fit-content;

  position: relative;

  &:last-child {
    margin-bottom: 1rem;
  }
`;

export const WarningText = styled.h2`
  margin: 0.375rem 0 1.5rem;

  color: #f00;
  font-weight: 400;
  font-size: 0.6875rem;
`;
