import { TripStatus } from '../../../pages/FollowDelivery/interfaces';
import { ITrackerSelectCardProps } from './interfaces';
import { TrackerSelect, TrackerSelectContainer } from './styles';

const StatusMarkLabels = {
  [TripStatus.Pending]: 'Pendente',
  [TripStatus.Displacement]: 'Em Deslocamento',
  [TripStatus.Collecting]: 'Coletando',
  [TripStatus.OnGoing]: 'Em Deslocamento',
  [TripStatus.waitingPayment]: 'Aguardando Pagamento',
  [TripStatus.Finished]: 'Entregue',
  [TripStatus.Canceled]: 'Cancelado',
} as {
  [key in TripStatus]: string;
};

const TrackerSelectCard = ({ statusMarks = [] }: ITrackerSelectCardProps) => {
  const sortedStatusMarks = statusMarks.sort(
    (a, b) => new Date(a.created_at).getTime() - new Date(b.created_at).getTime(),
  );
  return (
    <TrackerSelectContainer>
      <TrackerSelect>
        {sortedStatusMarks.map((statusMark) => (
          <option key={statusMark.id} value={statusMark.id}>
            {new Date(statusMark.created_at).toLocaleDateString('pt-BR', {
              hour: '2-digit',
              minute: '2-digit',
            })}{' '}
            - {StatusMarkLabels[statusMark.status_id]}
          </option>
        ))}
      </TrackerSelect>
    </TrackerSelectContainer>
  );
};

export default TrackerSelectCard;
