import { useRef, useState } from 'react';
import { useOutside } from '../../../hooks/useOutside';

import {
  AddAdmIcon, AddAdminButton,
  AddAdmText, ArrowIcon,
  DocIcon, DollarIcon, MarkerIcon,
  MenuAnchorText, MenuContainer,
  MenuIcon, MenuIconContainer, MenuItemsWrapper,
  MenuLink, MenuList,
  MenuListItem, NavBarContainer,
  ToggleViewButton, TruckIcon,
} from './styles';

export const NavBar = () => {
  const ref = useRef(null);

  const [isOpen, setIsOpen] = useState(false);

  const toggleOpen = () => {
    setIsOpen((prevState) => !prevState);
  };

  useOutside(ref, () => { if (isOpen) toggleOpen(); });

  return (
    <NavBarContainer open={isOpen} ref={ref}>
      <MenuItemsWrapper>
        <MenuContainer>
          <MenuIcon />
          <AddAdminButton to="/newAdmin">
            <AddAdmIcon />
            <AddAdmText>
              Adicionar admin
            </AddAdmText>
          </AddAdminButton>
        </MenuContainer>
        <MenuList>
          <MenuListItem>
            <MenuLink to="/politicsAndPrivacies">
              <MenuIconContainer>
                <DocIcon />
              </MenuIconContainer>
              <MenuAnchorText>
                Política de Privacidade
              </MenuAnchorText>
            </MenuLink>
          </MenuListItem>
          <MenuListItem>
            <MenuLink to="/vehicles">
              <MenuIconContainer>
                <TruckIcon />
              </MenuIconContainer>
              <MenuAnchorText>
                Tipos de Veículos
              </MenuAnchorText>
            </MenuLink>
          </MenuListItem>
          <MenuListItem>
            <MenuLink to="/followDelivery">
              <MenuIconContainer>
                <MarkerIcon />
              </MenuIconContainer>
              <MenuAnchorText>
                Acompanhar Corrida
              </MenuAnchorText>
            </MenuLink>
          </MenuListItem>
          <MenuListItem>
            <MenuLink to="/financialSettings">
              <MenuIconContainer>
                <DollarIcon />
              </MenuIconContainer>
              <MenuAnchorText>
                Configurar Financeiro
              </MenuAnchorText>
            </MenuLink>
          </MenuListItem>
          <MenuListItem>
            <AddAdminButton to="/newAdmin">
              <AddAdmIcon />
            </AddAdminButton>
          </MenuListItem>
        </MenuList>
      </MenuItemsWrapper>
      <ToggleViewButton type="button" onClick={toggleOpen}>
        <ArrowIcon />
      </ToggleViewButton>
    </NavBarContainer>
  );
};
