import { IDriverCardProps } from './interfaces';
import {
  ApproveButton, ApprovedStatus,
  DataDivider,
  DocsButton, DocsText,
  DriverCardArticle, DriverCardContainer,
  DriverCreatedAt,
  DriversLicense,
  DriversName, EyeIcon, RemoveButton,
  ShippingCompany, TrashIcon,
} from './styles';

export const DriverCard = ({
  driver,
  onRemove,
  onApprove,
  setCurrentDriver,
}: IDriverCardProps) => {
  const renderDriverStatus = () => {
    switch (driver.status_id) {
      case 1:
        return <ApprovedStatus>Aprovado</ApprovedStatus>;
      case 5:
        return <ApprovedStatus>Reprovado</ApprovedStatus>;
      default:
        return (
          <ApproveButton
            type="button"
            onClick={() => onApprove(driver?.id)}
          >
            Aprovar
          </ApproveButton>
        );
    }
  };

  return (
    <DriverCardContainer>

      <DriverCardArticle>
        <DataDivider>
          <DriversName title={driver?.user?.individual_person?.name}>
            {driver?.user?.individual_person?.name}
          </DriversName>

          <ShippingCompany>
            {driver?.transporter_drivers?.transporter?.user?.legal_person?.corporate_name}
          </ShippingCompany>
          <RemoveButton
            type="button"
            onClick={() => onRemove(driver?.id, driver?.transporter_drivers?.transporter_id)}
          >
            <TrashIcon />
          </RemoveButton>

        </DataDivider>
        <DataDivider>
          {driver?.driver_documents.length >= 1 ? (
            <DocsButton type="button" onClick={() => setCurrentDriver(driver)}>
              <EyeIcon />
              <DocsText>
                Visualizar documentos
              </DocsText>
            </DocsButton>
          ) : (
            <DocsButton type="button">
              <DocsText>
                Sem documentos Cadastrados
              </DocsText>
            </DocsButton>
          )}
          <DriversLicense>
            {}
            <DriverCreatedAt>
              {new Date(driver.created_at).toLocaleDateString()}
            </DriverCreatedAt>
            CNH
            {' '}
            {driver?.cnh}
          </DriversLicense>
          {renderDriverStatus()}
        </DataDivider>
      </DriverCardArticle>
    </DriverCardContainer>
  );
};
