import { ButtonHTMLAttributes } from 'react';

import { LoadingSpinner } from '../LoadingSpinner/LoadingSpinner';

import { Button } from './styles';

interface ISubmitButtonProps extends ButtonHTMLAttributes<HTMLButtonElement> {
  large?: boolean;
  loading?: boolean;
}

export const SubmitButton = ({
  children,
  type = 'submit',
  large = false,
  loading: $loading = false,
  ...rest
}: ISubmitButtonProps) => {
  const value = $loading ? <LoadingSpinner /> : children;

  return (
  // eslint-disable-next-line react/button-has-type
    <Button {...{
      ...rest, type, large, $loading,
    }}
    >
      {value}
    </Button>
  );
};

SubmitButton.defaultProps = {
  large: false,
  loading: false,
};
