import {
  FinancialDashboardResponse,
  OperationalDashboardResponse,
} from './interfaces';

export type TimeSpan = 'day' | 'week' | 'month' | 'year';

export type DashboardCard = {
  value: number;
  percentage: number;
};

export type GraphPoint = {
  x: string;
  y: number;
};

export type VagooIncomeSection = {
  income_transport: DashboardCard;
  income_fines: DashboardCard;
  income_insurance: DashboardCard;
  income_vagoo: DashboardCard;
};

export type TripNumberSection = {
  finished_count: DashboardCard;
  fines_count: DashboardCard;
  insured_count: DashboardCard;
  canceled_count: DashboardCard;
};

export type DashboardState = {
  dashboardType: 'financial' | 'operational';
  filters: {
    request: TimeSpan;
    financial: {
      vagooIncome: TimeSpan;
      totalIncome: TimeSpan;
      incomeGraph: TimeSpan;
    };
    operational: {
      tripNumber: TimeSpan;
      totalTrips: TimeSpan;
      tripGraph: TimeSpan;
    };
  };
  financial: {
    [key in TimeSpan]?: {
      vagooIncome: VagooIncomeSection;
      totalIncome: {
        income_total: DashboardCard;
      };
      incomeGraph: {
        vagooIncome: GraphPoint[];
        totalIncome: GraphPoint[];
      };
    };
  };
  operational: {
    [key in TimeSpan]?: {
      tripNumber: TripNumberSection;
      totalTrips: {
        trip_count: DashboardCard;
      };
      tripGraph: {
        total: GraphPoint[];
        finished: GraphPoint[];
        canceled: GraphPoint[];
      };
    };
  };
};

export type ChangeDashboardType = {
  type: 'CHANGE_DASHBOARD_TYPE';
  payload: 'financial' | 'operational';
};

export type ChangeSectionTimeSpan = {
  type: 'CHANGE_SECTION_TIME_SPAN';
  payload:
    | {
        type: 'financial';
        section: 'vagooIncome' | 'totalIncome' | 'incomeGraph';
        timespan: TimeSpan;
      }
    | {
        type: 'operational';
        section: 'tripNumber' | 'totalTrips' | 'tripGraph';
        timespan: TimeSpan;
      };
};

export type SetFinancialDashboard = {
  type: 'SET_FINANCIAL_DASHBOARD';
  payload: {
    timespan: TimeSpan;
    data: FinancialDashboardResponse;
  };
};

export type SetOperationalDashboard = {
  type: 'SET_OPERATIONAL_DASHBOARD';
  payload: {
    timespan: TimeSpan;
    data: OperationalDashboardResponse;
  };
};

export type DashboardReducerActions =
  | ChangeDashboardType
  | ChangeSectionTimeSpan
  | SetFinancialDashboard
  | SetOperationalDashboard;

export type DashboardReducer = (
  state: DashboardState,
  action: DashboardReducerActions
) => DashboardState;

export const initialDashboardCard: DashboardCard = {
  value: 0,
  percentage: 0,
};

export const initialDashboardState: DashboardState = {
  dashboardType: 'financial',
  filters: {
    request: 'month',
    financial: {
      vagooIncome: 'month',
      totalIncome: 'month',
      incomeGraph: 'month',
    },
    operational: {
      tripNumber: 'month',
      totalTrips: 'month',
      tripGraph: 'month',
    },
  },
  financial: {
    month: {
      vagooIncome: {
        income_transport: initialDashboardCard,
        income_fines: initialDashboardCard,
        income_insurance: initialDashboardCard,
        income_vagoo: initialDashboardCard,
      },
      totalIncome: {
        income_total: initialDashboardCard,
      },
      incomeGraph: {
        vagooIncome: [],
        totalIncome: [],
      },
    },
  },
  operational: {
    month: {
      tripNumber: {
        finished_count: initialDashboardCard,
        fines_count: initialDashboardCard,
        insured_count: initialDashboardCard,
        canceled_count: initialDashboardCard,
      },
      totalTrips: {
        trip_count: initialDashboardCard,
      },
      tripGraph: {
        total: [],
        finished: [],
        canceled: [],
      },
    },
  },
};

const mapFinancialDashboardResponseToState = (
  dashboardResponse: FinancialDashboardResponse,
): Partial<DashboardState['financial']['month']> => {
  const latestItem = dashboardResponse[0];

  if (!latestItem) {
    return {
      ...initialDashboardState.financial.month,
    };
  }

  const graphData = dashboardResponse.reduce(
    (acc, item) => {
      const { income_total, income_vagoo, timespan } = item;

      return {
        vagooIncome: [...acc.vagooIncome, { x: timespan, y: income_vagoo }],
        totalIncome: [...acc.totalIncome, { x: timespan, y: income_total }],
      };
    },
    {
      vagooIncome: [] as GraphPoint[],
      totalIncome: [] as GraphPoint[],
    },
  );

  return {
    vagooIncome: {
      income_transport: {
        value: latestItem.income_transport || 0,
        percentage: latestItem.percentage_transport || 0,
      },
      income_fines: {
        value: latestItem.income_fines || 0,
        percentage: latestItem?.percentage_fines || 0,
      },
      income_insurance: {
        value: latestItem.income_insurance || 0,
        percentage: latestItem.percentage_insurance || 0,
      },
      income_vagoo: {
        value: latestItem.income_vagoo || 0,
        percentage: latestItem.percentage_vagoo || 0,
      },
    },
    totalIncome: {
      income_total: {
        value: latestItem.income_total || 0,
        percentage: 100,
      },
    },
    incomeGraph: {
      ...graphData,
    },
  };
};

const mapOperationalDashboardResponseToState = (
  dashboardResponse: OperationalDashboardResponse,
): Partial<DashboardState['operational']['month']> => {
  const latestItem = dashboardResponse[
    dashboardResponse.length > 0 ? dashboardResponse.length - 1 : 0
  ];

  if (!latestItem) {
    return {
      ...initialDashboardState.operational.month,
    };
  }

  const graphData = dashboardResponse.reduce(
    (acc, item) => {
      const {
        trip_count, finished_count, canceled_count, timespan,
      } = item;

      return {
        total: [...acc.total, { x: timespan, y: Number(trip_count) }],
        finished: [...acc.finished, { x: timespan, y: Number(finished_count) }],
        canceled: [...acc.canceled, { x: timespan, y: Number(canceled_count) }],
      };
    },
    {
      total: [] as GraphPoint[],
      finished: [] as GraphPoint[],
      canceled: [] as GraphPoint[],
    },
  );

  const {
    canceled_count,
    finished_count,
    insured_count,
    fines_count,
    percentage_canceled,
    percentage_fined,
    percentage_finished,
    percentage_insured,
    trip_count,
  } = latestItem;

  return {
    tripNumber: {
      finished_count: {
        value: Number(finished_count || 0),
        percentage: Number(percentage_finished || 0),
      },
      fines_count: {
        value: Number(fines_count || 0),
        percentage: Number(percentage_fined || 0),
      },
      insured_count: {
        value: Number(insured_count || 0),
        percentage: Number(percentage_insured || 0),
      },
      canceled_count: {
        value: Number(canceled_count || 0),
        percentage: Number(percentage_canceled || 0),
      },
    },
    totalTrips: {
      trip_count: {
        value: Number(trip_count || 0),
        percentage: 100,
      },
    },
    tripGraph: {
      ...graphData,
    },
  };
};

export const dashboardReducer: DashboardReducer = (state, action) => {
  switch (action.type) {
    case 'CHANGE_DASHBOARD_TYPE':
      return {
        ...state,
        dashboardType: action.payload,
      };
    case 'CHANGE_SECTION_TIME_SPAN': {
      const { type, section, timespan } = action.payload;

      let shouldChangeRequestTimespan = false;

      if (type === 'financial') {
        shouldChangeRequestTimespan = !state.financial[timespan];
      } else {
        shouldChangeRequestTimespan = !state.operational[timespan];
      }

      return {
        ...state,
        filters: {
          ...state.filters,
          request: shouldChangeRequestTimespan
            ? timespan
            : state.filters.request,
          [type]: {
            ...state.filters[type],
            [section]: timespan,
          },
        },
      };
    }
    case 'SET_FINANCIAL_DASHBOARD': {
      const { timespan, data } = action.payload;
      const mappedData = mapFinancialDashboardResponseToState(data);

      return {
        ...state,
        financial: {
          ...state.financial,
          [timespan]: {
            ...state.financial[timespan],
            ...mappedData,
          },
        },
      };
    }
    case 'SET_OPERATIONAL_DASHBOARD': {
      const { timespan, data } = action.payload;
      const mappedData = mapOperationalDashboardResponseToState(data);

      return {
        ...state,
        operational: {
          ...state.operational,
          [timespan]: {
            ...state.operational[timespan],
            ...mappedData,
          },
        },
      };
    }
    default:
      return state;
  }
};
