import { MaskPhone } from '../../../utils';
import { IDeliveryTransporterDetailsCardProps } from './interfaces';
import {
  BoardIcon,
  CargoIcon,
  DriverName,
  IconContainer,
  PhoneDiv,
  PhoneIcon,
  PhoneNumber,
  TransporterDataContainer,
  TransporterDetails,
  TransporterHeader,
  TransporterName,
  TransporterPhonesContainer,
  TruckIcon,
  TruckDetailsContainer,
  TruckLabel,
  TruckValue,
} from './styles';

export const DeliveryTransporterDetailsCard = ({
  delivery,
}: IDeliveryTransporterDetailsCardProps) => {
  // const items = Array(10).fill('(11) 92830-1029');

  /* const mapPhones = (item: string, index: number) => {
    if (index >= 2) {
      return null;
    }
    return (
      <PhoneDiv>
        <IconContainer>
          <PhoneIcon />
          {index + 1}
        </IconContainer>
        <PhoneNumber>{item}</PhoneNumber>
      </PhoneDiv>
    );
  }; */

  const { responsible, driver, vehicle } = delivery;

  return (
    <TransporterDetails>
      <TransporterHeader>
        <TransporterDataContainer>
          <TransporterName>
            {responsible?.legal_person?.corporate_name
              || responsible?.individual_person?.name}
          </TransporterName>
          <DriverName>
            Motorista: {driver?.user?.individual_person?.name}
          </DriverName>
        </TransporterDataContainer>
        <TransporterPhonesContainer>
          <PhoneDiv>
            <IconContainer>
              <PhoneIcon />1
            </IconContainer>
            <PhoneNumber>{MaskPhone(responsible.cellphone)}</PhoneNumber>
          </PhoneDiv>
          <PhoneDiv>
            <IconContainer>
              <PhoneIcon />2
            </IconContainer>
            <PhoneNumber>{MaskPhone(driver.user.cellphone)}</PhoneNumber>
          </PhoneDiv>
        </TransporterPhonesContainer>
      </TransporterHeader>
      <TruckDetailsContainer>
        <div>
          <TruckIcon />
          <TruckLabel>Veículo</TruckLabel>
          <TruckValue>
            {vehicle?.vehicle_type?.name || '-'} |{' '}
            {vehicle?.vehicle_type?.bodywork?.name || '-'}
          </TruckValue>
        </div>
        <div>
          <CargoIcon />
          <TruckLabel>Volume máximo</TruckLabel>
          <TruckValue>{vehicle?.vehicle_type?.weight ? `${(vehicle.vehicle_type.weight.max / 1000).toLocaleString('pt-BR', {
            maximumFractionDigits: 1,
          })} Toneladas` : '-'}
          </TruckValue>
        </div>
        <div>
          <BoardIcon />
          <TruckLabel>Placa</TruckLabel>
          <TruckValue>{vehicle.plate}</TruckValue>
        </div>
      </TruckDetailsContainer>
    </TransporterDetails>
  );
};
