import { useLocation } from 'react-router';

import { Routes } from '../../../routes';
import { Header } from '../../organisms/Header';
import { NavBar } from '../../organisms/NavBar';

import { MainContent, MainWrapper } from './styles';

export const Layout = () => {
  const { pathname } = useLocation();

  const noHeader = [
    '/signIn',
    '/signin',
  ];

  if (noHeader.includes(pathname)) {
    return (
      <main>
        <Routes />
      </main>
    );
  }

  return (
    <>
      <Header />
      <NavBar />
      <MainContent>
        <MainWrapper>
          <Routes />
        </MainWrapper>
      </MainContent>
    </>
  );
};
