import styled from 'styled-components';

import { ReactComponent as DotsIconRaw } from '../../../assets/images/vehicles/vehicle-dots-icon.svg';
import { ReactComponent as CloseIconRaw } from '../../../assets/images/vehicles/vehicle-close-icon.svg';

export const VehicleDetailContainer = styled.article`
  width: 100%;
  max-width: 53.125rem;
  height: fit-content;

  position: relative;
  overflow: hidden;

  background-color: #fff;
  border-radius: 1rem;
`;

export const VehicleDetailHeader = styled.header`
  width: 100%;
  height: 4.1875rem;

  display: flex;
  align-items: center;

  background-color: var(--softPurple);
`;

export const DotsIcon = styled(DotsIconRaw)`
  width: 0.375rem;
  height: 1.5rem;
  margin-left: 2.0625rem;
`;

export const DetailsTitle = styled.h1`
  max-width: 20rem;
  max-height: 2.5rem;
  margin-left: 1.5625rem;

  display: inline-block;

  color: var(--darkPurple);
  font-size: 1.125rem;
  font-weight: bold;
  overflow: hidden;
  text-overflow: ellipsis;
`;

export const CloseButton = styled.button`
  width: fit-content;
  height: fit-content;
  margin: 0 1.9875rem 0 auto;

  cursor: pointer;
  transition: filter 0.3s ease;
  background-color: transparent;
  border: none;

  &:hover {
    filter: brightness(0.9);
  }
`;

export const CloseIcon = styled(CloseIconRaw)`
  width: 1.325625rem;
  height: 1.325625rem;
`;

export const ContentContainer = styled.div`
  margin: 1.5625rem 2rem 2.8875rem;

  display: flex;
  flex-direction: column;
  align-items: center;

  @media (min-width: 738px) {
    flex-direction: row;
    justify-content: space-between;
  }
`;

export const VehicleImage = styled.img`
  width: 18.25rem;
  height: 10.6875rem;

  object-fit: cover;
  border-radius: 1rem;
  box-shadow: 0 3px 6px rgba(0, 0, 0, 0.16);
  background-color: #fff;
`;

export const DetailsContainer = styled.div`
  width: 100%;
  max-width: 26.875rem;
  height: fit-content;
  padding: 1.6875rem 2rem 2.25rem;
  margin-top: 2rem;

  border-radius: 0.9375rem;
  background-color: rgba(207, 203, 241, 0.39);

  @media (min-width: 738px) {
    margin: unset;
  }
`;

export const DetailWrapper = styled.span`
  color: var(--darkPurple);

  display: flex;
  justify-content: space-between;

  &:not(:first-child) {
    margin-top: 0.375rem;
  }
`;

export const DetailText = styled.h2`
  font-size: 0.9rem;
  font-weight: normal;
`;

export const DetailValue = styled.b`
  max-width: 15rem;
  margin-left: 1.5rem;
  text-align: right;

  color: var(--darkPurple);
  font-size: 1.1rem;
  font-weight: bold;
  word-wrap: break-word;
`;
