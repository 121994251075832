/* eslint-disable no-undef */
import {
  DirectionsRenderer,
  DirectionsService,
  GoogleMap,
  Marker,
  OverlayView,
  useJsApiLoader,
} from '@react-google-maps/api';
import { useCallback, useMemo, useState } from 'react';

import rounded_pin_icon from '../../../assets/images/follow-delivery/rounded_pin_icon.svg';
import truck_in_route from '../../../assets/images/follow-delivery/truck_in_route.svg';

import { IDeliveryMapProps } from './interfaces';

import mapsStyle from './resources/mapsStyle.json';

import {
  DistanceIndicatorContainer,
  MapContainer,
  MinusIcon,
  PlusIcon,
  ZoomBall,
  ZoomContainer,
} from './styles';

const polyline = {
  strokeColor: '#2CCCD3',
  strokeWeight: 5,
  strokeOpacity: 0.5,
};

const DeliveryMap = ({
  origin,
  destination,
  waypoint,
  onTimeToDeliveryChange,
  isDelivering = false,
}: IDeliveryMapProps) => {
  const [originLng, originLat] = origin;
  const [destinationLng, destinationLat] = destination;
  const [waypointLng, waypointLat] = waypoint;
  const { isLoaded } = useJsApiLoader({
    id: 'google-map-script',
    googleMapsApiKey: process.env.REACT_APP_GOOGLE_MAPS_API_KEY as string,
  });
  const [map, setMap] = useState<google.maps.Map>();
  const [directions, setDirections] = useState<google.maps.DirectionsResult | null>();

  const center = {
    lat: -23.58,
    lng: -46.64,
  };

  const directionsCallback = useCallback(
    (
      response: google.maps.DirectionsResult | null,
      status: google.maps.DirectionsStatus | null,
    ) => {
      if (response == null || status == null) return;
      if (status === 'OK') {
        const secondsToDestination = response?.routes[0]?.legs.reduce(
          (acc, leg) => acc + (leg?.duration?.value || 0),
          0,
        );

        onTimeToDeliveryChange(secondsToDestination);
        setDirections(response);
      }
    },
    [],
  );

  const directionsOptions = useMemo(
    () => {
      if (isDelivering) {
        return ({
          origin: { lat: originLat, lng: originLng },
          destination: { lat: destinationLat, lng: destinationLng },
          travelMode: 'DRIVING',
        });
      }

      return ({
        destination: {
          lat: destinationLat,
          lng: destinationLng,
        },
        origin: {
          lat: originLat,
          lng: originLng,
        },
        waypoints: [
          {
            location: {
              lat: waypointLat,
              lng: waypointLng,
            },
          },
        ],
        travelMode: 'DRIVING',
      });
    },
    [origin, destination, map, isDelivering],
  );

  const onLoad = useCallback((_map: google.maps.Map) => {
    setMap(_map);
  }, []);

  const onUnmount = useCallback(() => {
    setMap(undefined);
  }, []);

  const handleDirectionsChange = useCallback(() => {
    if (!map) return;

    map.fitBounds(
      directions?.routes[0].bounds
        || new window.google.maps.LatLngBounds({
          lat: -23.5489,
          lng: -46.6388,
        }),
    );
  }, [directions, map]);

  const renderTruckMarker = () => {
    if (!directions) return null;

    return (
      <Marker
        position={{ lat: originLat, lng: originLng }}
        icon={truck_in_route}
      />
    );
  };

  const renderCollectionMarker = () => {
    if (!directions) return null;

    return (
      <Marker
        position={{
          lat: isDelivering ? destinationLat : waypointLat,
          lng: isDelivering ? destinationLng : waypointLng,
        }}
        icon={rounded_pin_icon}
      />
    );
  };

  const renderDistanceMarker = () => {
    if (!directions) return null;

    const distance = directions.routes[0]?.legs[0]?.distance?.value || 0;

    const formattedDistance = `${(distance / 1000).toLocaleString('pt-BR', {
      maximumFractionDigits: 1,
    })} km`;

    return (
      <OverlayView
        mapPaneName="markerLayer"
        position={{ lat: originLat, lng: originLng }}
      >
        <DistanceIndicatorContainer>
          <span>{formattedDistance}</span>
        </DistanceIndicatorContainer>
      </OverlayView>
    );
  };

  const handleIncreaseZoom = useCallback(() => {
    if (!map) return;

    const currentZoom = map?.getZoom() || 10;

    map.setZoom(currentZoom + 1);
  }, [map]);

  const handleDecreaseZoom = useCallback(() => {
    if (!map) return;

    const currentZoom = map?.getZoom() || 10;

    map.setZoom(currentZoom - 1);
  }, [map]);

  const firstDirectionsLeg = directions?.routes[0]?.legs[0]
    ? {
      ...directions,
      routes: [
        {
          ...directions?.routes[0],
          legs: [
            {
              ...directions.routes[0].legs[0],
            },
          ],
        },
      ],
    }
    : undefined;

  return (
    <MapContainer>
      {isLoaded && (
        <GoogleMap
          onLoad={onLoad}
          onUnmount={onUnmount}
          mapContainerClassName="del-map__map"
          center={center}
          zoom={10}
          options={{
            styles: mapsStyle,
            disableDefaultUI: true,
            keyboardShortcuts: false,
          }}
        >
          {origin && destination && (
            <DirectionsService
              options={directionsOptions as google.maps.DirectionsRequest}
              callback={directionsCallback}
            />
          )}
          {directions && (
            <DirectionsRenderer
              options={{
                directions: firstDirectionsLeg,
                polylineOptions: polyline,
                suppressMarkers: true,
                suppressPolylines: false,
                preserveViewport: true,
              }}
              onDirectionsChanged={handleDirectionsChange}
            />
          )}

          {renderTruckMarker()}
          {renderCollectionMarker()}
          {renderDistanceMarker()}
          <ZoomContainer>
            <ZoomBall
              style={{ marginBottom: '1rem' }}
              onClick={handleIncreaseZoom}
            >
              <PlusIcon />
            </ZoomBall>

            <ZoomBall onClick={handleDecreaseZoom}>
              <MinusIcon />
            </ZoomBall>
          </ZoomContainer>
        </GoogleMap>
      )}
    </MapContainer>
  );
};

export default DeliveryMap;
