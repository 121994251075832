import * as yup from 'yup';

// const imageTypes = [
//   'image/jpeg',
//   'image/png',
//   'image/bmp',
//   'image/png',
//   'application/pdf',
//   'application/msword',
// ];

export const schema = yup.object({
  first_name: yup
    .string()
    .optional(),
  last_name: yup
    .string()
    .optional(),
  phone: yup
    .string()
    .optional(),
  email: yup
    .string()
    .email('Insira um e-mail válido')
    .optional(),
  password: yup.string(),
  confirm_password: yup
    .string()
    .oneOf([yup.ref('password'), null], 'Confirmação divergente!')
    .optional(),
}).required();
