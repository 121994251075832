import styled from 'styled-components';

import { ReactComponent as SearchIconRaw } from '../../assets/images/drivers/drivers-search-icon.svg';

export const ManageDriversContainer = styled.section`
  width: 100%;
  height: fit-content;
`;

export const FilterInputContainer = styled.label`
  width: 100%;
  max-width: 18.125rem;
  height: 3rem;
  margin: 2.625rem 1rem 0 auto;

  display: block;

  position: relative;

  border: 1px solid #868E96;
  border-radius: 0.25rem;
  overflow: hidden;
`;

export const FilterInput = styled.input`
  width: 100%;
  height: 100%;
  padding: 0 3rem 0 1rem;

  font-size: 1rem;
`;

export const SearchIcon = styled(SearchIconRaw)`
  width: 0.79625rem;
  height: 0.79625rem;

  position: absolute;
  top: calc(50% - 0.398125rem);
  right: 1.1625rem;
`;

export const LoadMoreContainer = styled.div`
  height: 20px;
  width: 100%;
`;
