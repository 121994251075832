import { ChangeEvent, useEffect, useState } from 'react';
import toast from 'react-hot-toast';

import { PageTitle } from '../../components/atoms/PageTitle';
import { DriverDocs } from '../../components/organisms/DriverDocs';
import { ListDrivers } from '../../components/organisms/ListDrivers';
import { ModalRoot } from '../../components/organisms/ModalRoot';

import { IDriver } from './interfaces';
import {
  FilterInput,
  FilterInputContainer,
  LoadMoreContainer,
  ManageDriversContainer,
  SearchIcon,
} from './styles';
import { api } from '../../services/api';
import { useInfiniteScroll } from '../../hooks/useInfiniteScroll';
import { IConfirmationModalProps } from '../../components/organisms/ConfirmationModal/interfaces';
import { ConfirmationModal } from '../../components/organisms/ConfirmationModal';

interface IGetDriversResponse {
  results: IDriver[];
  total: number;
  page: number;
  limit: number;
}

export const ManageDrivers = () => {
  const [searchName, setSearchName] = useState<string>();

  const [showModal, setShowModal] = useState(false);

  const [showConfirmationModal, setShowConfirmationModal] = useState(false);
  const [handleConfirmationModal, setHandleConfirmationModal] = useState<IConfirmationModalProps>({
    closeModal: () => setShowConfirmationModal(false),
    message: 'Deseja realmente excluir o cadastro do motorista?',
    onCancel: () => setShowConfirmationModal(false),
    onConfirm: () => {},
    confirmText: 'Sim',
    cancelText: 'Não',
  });

  const [driverData, setDriverData] = useState([] as IDriver[]);
  const [currentDriverData, setCurrentDriverData] = useState({} as IDriver);

  const {
    page, loadMoreRef, maximumPage, setMaximumPage, setPage,
  } = useInfiniteScroll();

  const handleConfirmRemoveDriver = async (
    driverId: string,
    transporterId: string,
  ) => {
    try {
      await api.delete(`/drivers/${driverId}`);

      setDriverData((prevState) => prevState.filter((driver) => driver.id !== driverId));
      setShowConfirmationModal(false);
      toast.success('Motorista excluído com sucesso!');
    } catch (error: any) {
      toast.error(
        error?.response?.data?.message || 'Erro ao remover motorista',
      );
    }
  };

  const handleRemoveDriver = (driverId: string, transporterId: string) => {
    setHandleConfirmationModal((prevState) => ({
      ...prevState,
      onConfirm: () => handleConfirmRemoveDriver(driverId, transporterId),
    }));
    setShowConfirmationModal(true);
  };

  const handleApproveDriver = async (driverId: string) => {
    try {
      await api.put(`/drivers/aprove/${driverId}`, undefined, {
        params: {
          statusId: 1,
        },
      });

      setDriverData((prevState) => {
        const newState = [...prevState];
        const index = newState.findIndex((driver) => driver.id === driverId);

        newState[index].status_id = 1;

        return newState;
      });

      if (showModal) {
        setShowModal(false);
      }

      toast.success('Motorista aprovado com sucesso!');
    } catch (error: any) {
      toast.error(
        error?.response?.data?.message || 'Erro ao aprovar motorista',
      );
    }
  };

  const handleRefuseDriver = async (driverId: string) => {
    try {
      await api.put(`/drivers/aprove/${driverId}`, undefined, {
        params: {
          statusId: 5,
        },
      });

      setDriverData((prevState) => {
        const newState = [...prevState];
        const index = newState.findIndex((driver) => driver.id === driverId);

        newState[index].status_id = 5;

        return newState;
      });

      if (showModal) {
        setShowModal(false);
      }

      toast.success('Motorista reprovado com sucesso!');
    } catch (error: any) {
      toast.error(
        error?.response?.data?.message || 'Erro ao rejeitar motorista',
      );
    }
  };

  const handleShowDriverDocs = (driver: IDriver) => {
    setCurrentDriverData(driver);

    setShowModal(true);
  };

  const onChange = (event: ChangeEvent<HTMLInputElement>) => {
    const { value } = event.target;
    setSearchName(value);
    setPage(1);
    setMaximumPage(1);
  };

  const ensureDrivers = async () => {
    try {
      const response = await api.get<IGetDriversResponse>('/drivers', {
        params: {
          name: searchName,
          page,
        },
      });

      if (page > 1) {
        setDriverData((prevState) => [...prevState, ...response.data.results]);
      } else {
        setDriverData(response.data.results);
      }

      setMaximumPage(response.data.total / response.data.limit);
    } catch (error: any) {
      toast.error(
        error?.response?.data?.message || 'Erro ao carregar motoristas',
      );
    }
  };

  useEffect(() => {
    ensureDrivers();
  }, [searchName, page]);

  return (
    <ManageDriversContainer>
      <PageTitle>Gerenciar Motoristas</PageTitle>
      <FilterInputContainer>
        <FilterInput
          type="text"
          placeholder="Pesquisar Motorista"
          value={searchName}
          onChange={onChange}
        />
        <SearchIcon />
      </FilterInputContainer>
      <ListDrivers
        drivers={driverData}
        onRemove={handleRemoveDriver}
        onApprove={handleApproveDriver}
        setCurrentDriver={handleShowDriverDocs}
      />
      {maximumPage > page && <LoadMoreContainer ref={loadMoreRef} />}
      {showModal && (
        <ModalRoot setShowModal={setShowModal}>
          <DriverDocs
            driver={currentDriverData}
            onApprove={handleApproveDriver}
            onRefuse={handleRefuseDriver}
          />
        </ModalRoot>
      )}
      {showConfirmationModal && (
        <ModalRoot setShowModal={setShowConfirmationModal}>
          <ConfirmationModal {...handleConfirmationModal} />
        </ModalRoot>
      )}
    </ManageDriversContainer>
  );
};
