/* eslint-disable react/require-default-props */
import { curveCardinal } from '@visx/curve';
import { ParentSize } from '@visx/responsive';
import {
  AnimatedAreaSeries,
  AnimatedAxis,
  AnimatedGrid,
  Tooltip,
  XYChart,
} from '@visx/xychart';

import { LinearGradient } from '@visx/gradient';

import { TimeSpan } from '../../../pages/Dashboard/reducer';
import { FiltersSelector } from '../../molecules/FiltersSelector';
import { accessors } from './acessors';
import { customTheme } from './customTheme';
import {
  ChartLegend,
  ChartWrapper,
  DeliveriesChartAside,
  DeliveriesChartContainer,
  DeliveriesChartContent,
  DeliveriesChartHeader,
} from './styles';

interface IDeliveriesChartProps {
  canceled?: {
    x: string;
    y: number;
  }[];
  total?: {
    x: string;
    y: number;
  }[];
  finished?: {
    x: string;
    y: number;
  }[];
  onChangeTimespan: (timespan: TimeSpan) => void;
  timespan: TimeSpan;
}

export const DeliveriesChart = ({
  onChangeTimespan,
  timespan,
  canceled,
  finished,
  total,
}: IDeliveriesChartProps) => {
  const handleTimespanChange = (text: string) => {
    onChangeTimespan(text as TimeSpan);
  };

  return (
    <DeliveriesChartContainer>
      <DeliveriesChartHeader>
        <FiltersSelector value={timespan} onChange={handleTimespanChange} />
      </DeliveriesChartHeader>
      <DeliveriesChartContent>
        <ChartWrapper>
          <ParentSize>
            {({ width, height }) => (
              <XYChart
                width={width}
                height={height}
                xScale={{ type: 'band' }}
                yScale={{ type: 'linear' }}
                theme={customTheme}
                margin={{
                  left: 48,
                  right: 10,
                  top: 12,
                  bottom: 20,
                }}
              >
                <LinearGradient
                  id="total-area"
                  from="#00FFCB"
                  to="#12c9d65a"
                  toOpacity={0}
                />
                <LinearGradient
                  id="finished-area"
                  from="#0A76AE"
                  to="#7acccf60"
                  toOpacity={0.0}
                />
                <LinearGradient
                  id="cancelled-area"
                  from="#E30303"
                  to="#720B0B"
                  toOpacity={1}
                  fromOpacity={1}
                />
                <AnimatedAxis orientation="bottom" />
                <AnimatedAxis orientation="left" numTicks={6} />
                <AnimatedGrid columns numTicks={6} />
                <AnimatedAreaSeries
                  dataKey="Nº de Viagens Canceladas"
                  data={canceled || []}
                  stroke="url(#cancelled-area)"
                  fill="url(#cancelled-area)"
                  curve={curveCardinal}
                  {...accessors}
                />
                <AnimatedAreaSeries
                  dataKey="Nº de Viagens Totais"
                  data={total || []}
                  stroke="url(#total-area)"
                  fill="url(#total-area)"
                  curve={curveCardinal}
                  {...accessors}
                />
                <AnimatedAreaSeries
                  dataKey="Nº de Viagens Realizadas"
                  data={finished || []}
                  stroke="url(#finished-area)"
                  fill="url(#finished-area)"
                  curve={curveCardinal}
                  {...accessors}
                />
                <Tooltip
                  snapTooltipToDatumX
                  snapTooltipToDatumY
                  showVerticalCrosshair
                  showSeriesGlyphs
                  renderTooltip={({ tooltipData, colorScale }: any) => (
                    <div>
                      <div
                        style={{
                          color: colorScale(tooltipData.nearestDatum.key),
                        }}
                      >
                        {tooltipData.nearestDatum.key}
                      </div>
                      {accessors
                        .xAccessor(tooltipData.nearestDatum.datum)
                        .split('-')
                        .reverse()
                        .join('/')}
                      {', '}
                      {accessors.yAccessor(tooltipData.nearestDatum.datum)}
                    </div>
                  )}
                />
              </XYChart>
            )}
          </ParentSize>
        </ChartWrapper>
        <DeliveriesChartAside>
          <ChartLegend color="#00FFCB">Nº de Viagens Totais</ChartLegend>
          <ChartLegend color="#0A76AE">Nº de Viagens Realizadas</ChartLegend>
          <ChartLegend color="#E30303">Nº de Viagens Canceladas</ChartLegend>
        </DeliveriesChartAside>
      </DeliveriesChartContent>
    </DeliveriesChartContainer>
  );
};
