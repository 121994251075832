import axios from 'axios';
import { IUser } from '../contexts/Auth/interfaces';

export const api = axios.create({
  baseURL: process.env.REACT_APP_API_URL,
});

interface RefreshTokenResponse {
  user: IUser;
  refresh_token: string;
  access_token: string;
}

// eslint-disable-next-line consistent-return
export const refreshAccessToken = async () => {
  try {
    const refreshToken = localStorage.getItem('@vagoo_emb refreshToken');
    if (refreshToken) {
      const response = await api.put<RefreshTokenResponse>(
        '/sessions/refresh-token',
        {
          refresh_token: refreshToken,
        },
      );

      localStorage.setItem(
        '@vagoo_emb accessToken',
        response.data.access_token,
      );
      localStorage.setItem(
        '@vagoo_emb refreshToken',
        response.data.refresh_token,
      );

      return response.data.access_token;
    }
    localStorage.clear();
    window.location.href = '/';
    return '';
  } catch (error: any) {
    localStorage.clear();
    window.location.href = '/';
  }
};

api.interceptors.request.use(
  async (config: any) => {
    const accessToken = localStorage.getItem('@Vagoo: accessToken');
    if (accessToken) {
      // eslint-disable-next-line no-param-reassign
      config.headers!.Authorization = `Bearer ${accessToken}`;
    }
    return config;
  },
  (error) => Promise.reject(error),
);

api.interceptors.response.use(
  (response) => response,
  async (error: any) => {
    const originalRequest = error.config;

    if (error?.response?.status === 401 && !originalRequest.retry) {
      originalRequest.retry = true;
      const accessToken = await refreshAccessToken();
      originalRequest.headers.Authorization = `Bearer ${accessToken}`;
      return api(originalRequest);
    }

    return Promise.reject(error);
  },
);
