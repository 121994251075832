import styled from 'styled-components';

export const ListDriversContainer = styled.ul`
  width: 100%;
  height: fit-content;
  margin-top: 1.6875rem;

  list-style-type: none;
`;

export const EmptyMessage = styled.h1`
  margin-top: 1.0625rem;

  display: inline-block;

  font-size: 1.2rem;
  color: var(--darkPurple)
`;
