import { useNavigate } from 'react-router';
import useAuth from '../../../hooks/useAuth';

import {
  ImageContainer, UserBoxContainer,
  ExitIcon, GearIcon,
  UserImage, IconButton,
} from './styles';

export const UserBox = () => {
  const { handleSignOut, user } = useAuth();
  const navigate = useNavigate();

  const editUser = () => {
    navigate('/settings');
  };

  const signOut = () => {
    navigate('/signIn');

    handleSignOut();
  };

  return (
    <UserBoxContainer>
      <ImageContainer>
        {user?.avatar_url && (
        <UserImage
          src={user?.avatar_url}
          alt="User"
        />
        )}
      </ImageContainer>
      <IconButton type="button" onClick={editUser}>
        <GearIcon />
      </IconButton>
      <IconButton type="button" onClick={signOut}>
        <ExitIcon />
      </IconButton>
    </UserBoxContainer>
  );
};
