import styled from 'styled-components';

export const DeliveriesChartsContainer = styled.div`
  width: 100%;
  height: fit-content;

  display: flex;
  flex-wrap: wrap;
  gap: 1.875rem;

  margin-right: 1.875rem;

  @media (min-width: 1100px) {
    flex-wrap: nowrap;
  }
`;
