import styled from 'styled-components';

export const DeliveryCardContainer = styled.article`
  width: 100%;
  max-width: 23.89375rem;
  height: fit-content;
  min-height: 10.70875rem;
  padding: 0.875rem 1rem 0 0;
  margin: 0 1.089375rem 5rem 0;

  display: flex;
  gap: 0.89375rem;

  position: relative;
  z-index: 2;

  background-color: var(--softGray);
  border-radius: 0.9375rem;
  box-shadow: -1px 3px 6px rgba(0, 0, 0, 0.16);
`;

export const BlueBg = styled.div`
  height: 100%;
  width: 2rem;

  position: absolute;
  top: 0;
  left: 0;
  z-index: -1;

  background-color: var(--softBlue);
  border-radius: 0.9375rem 0 0 0.9375rem;
`;

export const GradientBg = styled.div`
  height: 100%;
  width: 6rem;

  position: absolute;
  top: 0;
  left: 2rem;
  z-index: -1;

  background-color: var(--softBlue);
  background: linear-gradient(243deg, rgba(255,255,255,0) 50%, rgba(31,193,203,1) 50%);
`;

export const DeliverLogo = styled.img`
  width: 6.8125rem;
  height: 6.8125rem;
  margin-left: 1rem;

  object-fit: cover;
  border-radius: 50%;
`;

export const DeliverTitle = styled.h1`
  max-width: 14rem;

  overflow: hidden;

  color: var(--gray);
  font-size: 1.5rem;
  text-align: right;
  text-overflow: ellipsis;
  white-space: nowrap;
`;

export const DeliverData = styled.h2`
  color: var(--gray);
  font-size: 0.75rem;
  text-align: right;

  &:first-child {
    margin-top: 0.5rem;
  }
`;

export const DetailsButton = styled.button`
  width: 14.9375rem;
  height: 2.8125rem;
  margin-top: 2rem;

  position: relative;
  left: 1.4rem;

  background-color: var(--blue);
  cursor: pointer;
  transition: filter 0.2s ease;

  color: #fff;
  font-size: 1.125rem;
  font-weight: bold;

  &:before{
    content: '';
    width: 1.3rem;

    display: block;

    position: absolute;
    top: 0;
    bottom: 0;
    left: -1.3rem;

    background-color: var(--blue);
    background: linear-gradient(115deg, rgba(255,255,255,0) 50%, rgba(28,89,149,1) 50%);
  }

  &:after{
    content: '';
    width: 1.089375rem;
    height: 1.17rem;

    display: block;

    position: absolute;
    top: -1.17rem;
    bottom: 0;
    right: 0;

    background: rgb(34,21,81);
    background: linear-gradient(45deg, rgba(34,21,81,1) 50%, rgba(255,255,255,0) 50%);
  }

  &:hover {
    filter: brightness(0.9);
  }
`;
