import styled from 'styled-components';

import { ReactComponent as SignInIconsRaw } from '../../assets/images/signIn/sign-in-icons.svg';
import { ReactComponent as SignInLogoRaw } from '../../assets/images/signIn/sign-in-logo.svg';

export const SignInContainer = styled.section`
  width: 100%;
  height: fit-content;
  min-height: 100vh;

  display: flex;
  flex-direction: column;

  position: relative;

  @media (min-width: 780px) {
    flex-direction: row;
  }
`;

export const BackGroundGradient = styled.img`
  width: 100%;
  height: 100%;

  position: absolute;
  inset: 0;
  z-index: -1;

  border: 0;
`;

export const LogoContainer = styled.aside`
  width: 100%;
  height: 100%;
  padding: 1rem 0;

  @media (min-width: 780px) {
    min-height: 100vh;
    // padding: 15.125rem 0 15.25rem 0;
    height: fit-content;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
  }
`;

export const SignInIcons = styled(SignInIconsRaw)`
  width: 23.861875rem;
  margin: 0 auto;

  display: none;

  @media (min-width: 780px) {
    display: block;
  }
`;

export const SignInInfo = styled.h1`
  width: fit-content;
  margin: 0 auto;

  display: block;

  color: var(--darkPurple);
  text-align: center;
  font-size: 2.75rem;
  font-weight: bold;

  @media (min-width: 780px) {
    margin: 1.86875rem auto 0;
  }
`;

export const SignInLogo = styled(SignInLogoRaw)`
  width: 19.428125rem;
  margin: 1rem auto 0;

  display: block;

  @media (min-width: 780px) {
    margin: 7.01875rem auto 0;
  }
`;

export const FormContainer = styled.section`
  width: 100%;
  max-width: 55rem;
  flex-grow: 1;
  margin: 0 0 0 auto;

  display: flex;
  align-items: center;
  justify-content: center;

  @media (min-width: 780px) {
    min-height: 100vh;
  }
`;
