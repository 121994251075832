import { ReactComponent as PenIcon } from '../../../assets/images/vehicles/vehicles-edit-icon.svg';
import { ReactComponent as TrashIcon } from '../../../assets/images/vehicles/vehicles-trash-icon.svg';

import { IVehicleTypeCardProps } from './interfaces';

import {
  ButtonHandler, VehicleTypeCardContainer,
  ButtonsContainer, TypeName,
  CardBackground, DetailsButton,
  DetailsContainer, IconContainer,
  VehicleCard, VehicleImage, HandlerText,
} from './styles';

export const VehicleTypeCard = ({
  vehicleType,
  onEdit,
  onRemove,
  handleDetails,
}: IVehicleTypeCardProps) => {
  const {
    id,
    name,
    vehicle_image_url,
  } = vehicleType;

  return (
    <VehicleTypeCardContainer>
      <VehicleCard>
        <CardBackground />
        <VehicleImage
          src={vehicle_image_url}
          alt={name}
        />
        <ButtonsContainer>
          <ButtonHandler
            type="button"
            onClick={() => onEdit(id)}
          >
            <IconContainer>
              <PenIcon />
            </IconContainer>
            <HandlerText>
              Editar
            </HandlerText>
          </ButtonHandler>
          <ButtonHandler
            type="button"
            onClick={() => onRemove(id)}
          >
            <IconContainer>
              <TrashIcon />
            </IconContainer>
            <HandlerText>
              Excluir
            </HandlerText>
          </ButtonHandler>
        </ButtonsContainer>
        <DetailsContainer>
          <TypeName>
            {name}
          </TypeName>
          <DetailsButton
            type="button"
            onClick={() => handleDetails(vehicleType)}
          >
            Detalhes
          </DetailsButton>
        </DetailsContainer>
      </VehicleCard>
    </VehicleTypeCardContainer>
  );
};
