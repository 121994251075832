import styled from 'styled-components';

interface IButtonProps {
  large?: boolean;
  loading?: boolean;
}

export const Button = styled.button<IButtonProps>`
  ${({ large }) => (large ? `
    width: 100%;
  ` : `
    width: 100%;
    max-width: 8.75rem;
  `)}
  height: ${({ large }) => (large ? '4.375' : '2.4375')}rem;

  display: block;

  border-radius: 0.625rem;
  cursor: pointer;
  transition: 0.2s filter;

  ${({ large }) => (large
    ? `
      background-color: var(--darkPurple);

      font-size: 1.9375rem;
    ` : `
      background-color: var(--softBlue);

      font-size: 0.75rem;
      box-shadow: 0 3px 6px rgba(0, 0, 0, 0.16);
    `)}
  color: #fff;
  text-align: center;

  ${({ loading }) => loading && `
    pointer-events: none;
  `}

  &:hover {
    filter: brightness(0.9);
  }

  &:disabled {
    filter: grayscale(0.9);
  }
`;
