import styled from 'styled-components';

export const BaseLabelContainer = styled.label`
  width: 100%;
  height: fit-content;

  display: block;

  color: var(--darkPurple);
  font-size: 1rem;
  text-align: left;
`;
