import styled from 'styled-components';

import { ReactComponent as PlusIconRaw } from '../../../assets/images/follow-delivery/plus.svg';
import { ReactComponent as MinusIconRaw } from '../../../assets/images/follow-delivery/minus.svg';

export const MapContainer = styled.div`
  width: 95%;
  margin: 1.375rem auto 0;

  .del-map__map{
    width: 100%;
    height: 21.823125rem;
  }
`;

export const ZoomContainer = styled.div`
  display: flex;
  flex-direction: column;
  padding: 0.625rem 0.5rem;

  border-radius: 12px;

  background: white;

  box-shadow: rgba(0, 0, 0, 0.1) 0px 0px 5px 0px,
    rgba(0, 0, 0, 0.1) 0px 0px 1px 0px;

  position: absolute;
  bottom: 1.625rem;
  right: 1.875rem;
`;

export const ZoomBall = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;

  background: #221551;

  width: 1.5rem;
  height: 1.5rem;

  border-radius: 50%;

  color: white;

  font-size: 1.5rem;

  transition: 0.3s;

  &:hover {
    cursor: pointer;

    background: #2699fb;
  }
`;

export const DistanceIndicatorContainer = styled.div`
  width: fit-content;
  min-height: 1.5rem;
  color: #fff;
  background-color: #2cccd3;
  border-radius: 1.25rem;
  padding: 0.5rem 1rem;
  font-family: 'Roboto';
  font-size: 0.875rem;
`;

export const PlusIcon = styled(PlusIconRaw)``;

export const MinusIcon = styled(MinusIconRaw)``;
