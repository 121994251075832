import { ChangeEvent, useEffect, useState } from 'react';

import { IAvatarHandlerProps } from './interfaces';

import {
  AvatarHandlerContainer, AvatarImg,
  AvatarLabel, GhostInput,
  ImageContainer, WarningText,
} from './styles';

export const AvatarHandler = ({
  defaultUrl, register, errors,
}: IAvatarHandlerProps) => {
  const [imageUrl, setImageUrl] = useState<string>();

  const handleImageChange = (event: ChangeEvent<HTMLInputElement>) => {
    const { files } = event.target;

    if (files && files.length > 0) {
      const file = files[0];

      const url = URL.createObjectURL(file);

      setImageUrl(url);

      return;
    }

    setImageUrl(undefined);
  };

  useEffect(() => {
    setImageUrl(defaultUrl);
  }, [defaultUrl]);

  return (
    <AvatarHandlerContainer>
      <ImageContainer htmlFor="avatar-handler-input">
        {imageUrl && (
          <AvatarImg
            src={imageUrl || defaultUrl}
            alt="Vehicle Avatar"
          />
        )}
      </ImageContainer>
      <AvatarLabel htmlFor="avatar-handler-input">
        Alterar Foto
      </AvatarLabel>
      <GhostInput
        id="avatar-handler-input"
        type="file"
        accept="image/*"
        {...register('image', { onChange: handleImageChange })}
      />
      <WarningText>{errors?.image?.message}</WarningText>
    </AvatarHandlerContainer>
  );
};
