import styled from 'styled-components';

export const Title = styled.h1`
  width: 100%;
  height: fit-content;

  display: block;

  color: var(--darkPurple);
  font-size: 1.875rem;
  font-weight: bold;
`;
