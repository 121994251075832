import styled from 'styled-components';

import { ReactComponent as EyeIconRaw } from '../../../assets/images/input/input-eye-icon.svg';

interface IInputProps {
  lined?: boolean;
  error?: boolean;
  togglePassword?: boolean;
}

export const Input = styled.input<IInputProps>`
  width: 100%;
  ${({ togglePassword }) => (togglePassword ? 'padding: 0 2.5rem 0 1rem;' : 'padding: 0 1rem;')}

  color: var(--gray);

  ${({ lined }) => (lined
    ? `
      height: 3.4375rem;

      border-bottom: 3px solid var(--gray);

      font-size: 1.875rem;
    `
    : `
      height: 2.375rem;

      border-radius: 0.625rem;
      border: 1px solid var(--lightGray);

      font-size: 1rem;
  `)}
  transition: border-color 0.2s ease;

  &:not(:disabled):focus {
    border-color: var(--lightGreen);
  }

  &:disabled {
    background-color: var(--gray);
  }

  ${({ error }) => error
    && `
    border-color: #f00;
  `}

  &::-webkit-outer-spin-button,
  &::-webkit-inner-spin-button {
    -webkit-appearance: none;
    margin: 0;
  }

  &[type="number"] {
    -moz-appearance: textfield;
  }
`;

export const InputContainer = styled.div`
  width: 100%;
  height: fit-content;

  position: relative;
`;

export const ToggleButton = styled.button`
  width: fit-content;
  height: fit-content;

  position: absolute;
  top: calc(45% - 0.46875rem);
  right: 1rem;

  background-color: transparent;
  border: none;
  cursor: pointer;

  @media (min-width: 435px) {
    top: calc(50% - 0.46875rem);
  }
`;

export const EyeIcon = styled(EyeIconRaw)`
  width: 1.171875rem;
  height: 0.9375rem;
`;
