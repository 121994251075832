import styled from 'styled-components';

export const DeliveriesChartContainer = styled.article`
  width: 100%;
  height: fit-content;
  min-height: 17.9375rem;
  padding: 1.375rem 0 0;

  border-radius: 0.9375rem;
  background-color: #F5F5F5;
  box-shadow: 0 3px 6px rgba(0, 0, 0, 0.16);
`;

export const DeliveriesChartHeader = styled.header`
  padding-left: 1.375rem;
`;

export const DeliveriesChartContent = styled.div`
  width: 100%;
  height: 100%;
  display: flex;
`;

export const ChartWrapper = styled.div`
  width: 75%;
  height: 18rem;

  @media (min-width: 745px) {
    height: 13.65625rem;
  }
`;

export const DeliveriesChartAside = styled.aside`
  display: flex;
  flex-direction: column;
  flex: 1;

  justify-content: flex-end;
`;

export const ChartLegend = styled.span<{
  color: string;
}>`
  display: inline-block;
  white-space: nowrap;

  font-size: 0.875rem;
  font-weight: 500;
  color: var(--darkPurple);

  &:before {
    content: '';
    width: 0.5rem;
    height: 0.5rem;
    margin-right: 0.5rem;

    display: inline-block;

    background-color: ${(props) => props.color};
  }
`;
