import styled from 'styled-components';

export const PaPContainer = styled.section`
  width: 100%;
  height: fit-content;
`;

export const TermsCard = styled.form`
  width: 100%;
  max-width: 60.625rem;
  height: 32.5625rem;
  margin-top: 2.0625rem;
  padding: 1.5rem 2.5rem 2.95625rem;

  border: 1px solid #707070;
  border-radius: 0.9375rem;
`;

export const TermsTitle = styled.h1`
  color: var(--darkPurple);
  font-size: 1.25rem;
  text-align: center;
`;

export const TermsTextarea = styled.textarea`
  width: 100%;
  height: 100%;
  padding: 0 1.5rem 0 0;
  margin: 1rem auto;

  resize: none;

  overflow-x: hidden;
  overflow-y: auto;

  &::-webkit-scrollbar {
    width: 6px;
    padding: 0 1rem;
  }

  &::-webkit-scrollbar-track {
    background-color: #E0E0E0;
    border-radius: 6px;
  }

  &::-webkit-scrollbar-thumb {
    background-color: var(--darkPurple);
    border-radius: 6px;
  }
`;

export const ButtonContainer = styled.div`
  width: 100%;
  max-width: 60.625rem;

  margin: 1rem 0 0;

  .pap__button{
    margin-left: auto;
  }
`;

export const SelectContainer = styled.div`
  margin-top: 2rem;

  display: flex;
  align-items: center;
  gap: 1rem;

  select {
    border: 1px solid #868E96;
    border-radius: 0.25rem;
    overflow: hidden;
    height: 2.875rem;
    max-width: 18.125rem;
    padding: 0 0.5rem;
  }
`;

export const SelectLabel = styled.label`

`;
