import styled from 'styled-components';

export const TotalRevenueChartContainer = styled.article`
  width: 13.1875em;
  min-height: 12.5em;
  padding: 1.3em 0 0.9375em;

  position: relative;

  border-radius: 1em;
  background-color: #f5f5f5;
  box-shadow: 0 3px 6px rgba(0, 0, 0, 0.16);

  font-size: 87.5%;

  @media (min-width: 1400px) {
    font-size: 100%;
  }
`;

export const ChartContainer = styled.div`
  width: 75%;
  margin: 0 auto;
  aspect-ratio: 1;
  // padding: 1em;

  position: relative;

  border-radius: 50%;
  background: #f5f5f5;
  /* box-shadow: inset 5px 5px 10px #bdbdbd,
              inset -5px -5px 10px #ffffff; */

  box-shadow: inset 0 3px 6px rgba(0, 0, 0, 0.16);
  outline: 1px solid #ffffff30;
`;

export const ChartBackground = styled.img`
  width: 115%;
  height: 115%;

  position: absolute;
  top: -7.4%;
  left: -7.4%;
  z-index: 1;
`;

export const ChartBG = styled.div`
  width: 90%;
  aspect-ratio: 1;

  position: absolute;
  left: 50%;
  top: 50%;
  transform: translate(-50%, -50%);

  background-image: url("data:image/svg+xml,%3csvg width='100%25' height='100%25' xmlns='http://www.w3.org/2000/svg'%3e%3crect width='100%25' height='100%25' fill='none' rx='100' ry='100' stroke='%23dcdcdc' stroke-width='20' stroke-dasharray='2%2c 2' stroke-dashoffset='86' stroke-linecap='butt'/%3e%3c/svg%3e");
  border-radius: 50%;
`;

export const FilterContainer = styled.div`
  width: 85%;
  margin: 1.09375em auto 0;

  display: flex;
  justify-content: space-between;
`;

export const FilterButton = styled.button`
  padding: 0.2em 0.4em;

  background-color: #2cccd3;
  border-radius: 1em;
  cursor: pointer;

  color: #fff;
  font-size: 0.8em;
`;

export const BlueCard = styled.div`
  width: 10.875em;
  min-height: 10.875em;

  position: absolute;
  top: 0em;
  right: -1em;
  z-index: -1;

  border-radius: 1em;
  background-color: var(--softBlue);
  transform: rotate(15deg);
`;

export const PurpleCard = styled.div`
  width: 10.25em;
  min-height: 10.25em;

  position: absolute;
  top: 0em;
  right: -2em;
  z-index: -1;

  border-radius: 1em 1em 0 0;
  background-color: var(--darkPurple);
  transform: rotate(28deg);
`;
