import { ChangeEvent } from 'react';

import { BaseInput } from '../../atoms/BaseInput';
import { BaseLabel } from '../../atoms/BaseLabel';
import { SubmitButton } from '../../atoms/SubmitButton';

import { MaskPhone } from '../../../utils';

import { IAddAdminFormProps } from './interfaces';
import {
  AddAdminFormContainer, FormDividers,
  FormWrapper, WarningText,
} from './styles';

export const AddAdminForm = ({
  register, setValue, onSubmit, errors, isSubmitting,
}: IAddAdminFormProps) => {
  const handlePhoneMask = (event: ChangeEvent<HTMLInputElement>) => {
    const { value } = event.target;

    const phone = MaskPhone(value);

    setValue('cellphone', phone);
  };

  return (
    <AddAdminFormContainer onSubmit={onSubmit}>
      <FormWrapper>
        <FormDividers>
          <BaseLabel className="add-admin__label">
            Nome *
          </BaseLabel>
          <BaseInput
            type="text"
            className="add-admin__input"
            error={!!errors?.first_name}
            autoComplete="add-admin-vagoo first_name"
            {...register('first_name', { required: true })}
          />
          <WarningText>
            {errors?.first_name?.message}
          </WarningText>
          <BaseLabel className="add-admin__label">
            E-mail *
          </BaseLabel>
          <BaseInput
            type="text"
            className="add-admin__input"
            placeholder="@email.com"
            error={!!errors?.email}
            autoComplete="add-admin-vagoo email"
            {...register('email', { required: true })}
          />
          <WarningText>
            {errors?.email?.message}
          </WarningText>
          <BaseLabel className="add-admin__label">
            Celular *
          </BaseLabel>
          <BaseInput
            type="text"
            className="add-admin__input"
            error={!!errors?.cellphone}
            placeholder="(xx) x xxxx-xxxx"
            autoComplete="add-admin-vagoo phone"
            {...register('cellphone', {
              required: true, onChange: handlePhoneMask,
            })}
          />
          <WarningText>
            {errors?.cellphone?.message}
          </WarningText>
        </FormDividers>
        <FormDividers>
          <BaseLabel className="add-admin__label">
            Sobrenome *
          </BaseLabel>
          <BaseInput
            type="text"
            className="add-admin__input"
            error={!!errors?.last_name}
            autoComplete="add-admin-vagoo last_name"
            placeholder="Ex: da Silva"
            {...register('last_name', { required: true })}
          />
          <WarningText>
            {errors?.last_name?.message}
          </WarningText>

          <BaseLabel className="add-admin__label">
            Senha *
          </BaseLabel>
          <BaseInput
            type="password"
            className="add-admin__input"
            error={!!errors?.password}
            autoComplete="add-admin-vagoo password"
            placeholder="*************"
            togglePassword
            {...register('password', { required: true })}
          />
          <WarningText>
            {errors?.password?.message}
          </WarningText>
          <BaseLabel className="add-admin__label">
            Confirmar Senha *
          </BaseLabel>
          <BaseInput
            type="password"
            className="add-admin__input"
            error={!!errors?.password_confirmation}
            autoComplete="add-admin-vagoo password_confirmation"
            placeholder="*************"
            togglePassword
            {...register('password_confirmation', { required: true })}
          />
          <WarningText>
            {errors?.password_confirmation?.message}
          </WarningText>
        </FormDividers>
      </FormWrapper>
      <SubmitButton
        type="submit"
        className="add-admin__button"
        loading={!!isSubmitting}
      >
        Salvar
      </SubmitButton>
    </AddAdminFormContainer>
  );
};
