import { useEffect, useState } from 'react';
import { useNavigate } from 'react-router';
import toast from 'react-hot-toast';

import { PageTitle } from '../../components/atoms/PageTitle';
import { ListVehiclesTypes } from '../../components/organisms/ListVehiclesTypes/ListVehiclesTypes';
import { ModalRoot } from '../../components/organisms/ModalRoot';
import { VehicleDetails } from '../../components/organisms/VehicleDetails';

import { api } from '../../services/api';

import { IVehicleTypes } from './interface';
import {
  VehiclesContainer,
  NewVehicleButton,
  TruckIcon,
  ButtonText,
} from './styles';
import { IConfirmationModalProps } from '../../components/organisms/ConfirmationModal/interfaces';
import { ConfirmationModal } from '../../components/organisms/ConfirmationModal';

export const Vehicles = () => {
  const navigate = useNavigate();

  const [showModal, setShowModal] = useState(false);

  const [showConfirmationModal, setShowConfirmationModal] = useState(false);
  const [handleConfirmationModal, setHandleConfirmationModal] = useState<IConfirmationModalProps>({
    closeModal: () => setShowConfirmationModal(false),
    onCancel: () => setShowConfirmationModal(false),
    message: 'Deseja realmente excluir o cadastro desse veículo?',
    confirmText: 'Sim',
    cancelText: 'Não',
  });

  const [vehicleTypes, setVehicleTypes] = useState([] as IVehicleTypes[]);
  const [currentVehicleType, setCurrentVehicleType] = useState(
    {} as IVehicleTypes,
  );

  const handleEditTruck = (vehicleId: string) => {
    navigate(`/vehicles/edit/${vehicleId}`);
  };

  const confirmRemoveTruck = async (vehicleId: string) => {
    try {
      await api.delete(`/vehicle-types/${vehicleId}`);

      setShowConfirmationModal(false);
      setVehicleTypes((prevState) => prevState.filter((vehicle) => vehicle.id !== vehicleId));
      toast.success('Tipo de veículo removido com sucesso!');
    } catch (error: any) {
      toast.error(
        error?.response?.data?.message || error?.response?.messageString,
      );
    }
  };

  const handleRemoveTruck = (vehicleId: string) => {
    setHandleConfirmationModal((prevState) => ({
      ...prevState,
      onConfirm: () => confirmRemoveTruck(vehicleId),
    }));
    setShowConfirmationModal(true);
  };

  const handleDetails = (vehicle: IVehicleTypes) => {
    setCurrentVehicleType(vehicle);

    setShowModal(true);
  };

  const ensureVehicles = async () => {
    try {
      const response = await api.get('/vehicle-types?limit=999&distinct=false');

      setVehicleTypes(response?.data?.results);
    } catch (error: any) {
      toast.error(
        error?.response?.data?.message || error?.response?.messageString,
      );
    }
  };

  useEffect(() => {
    ensureVehicles();
  }, []);

  return (
    <VehiclesContainer>
      <PageTitle>Tipos de Veículo</PageTitle>
      <NewVehicleButton to="/vehicles/new">
        <TruckIcon />
        <ButtonText>Adicionar novo tipo de veículo</ButtonText>
      </NewVehicleButton>
      <ListVehiclesTypes
        vehicleTypes={vehicleTypes}
        onEdit={handleEditTruck}
        onRemove={handleRemoveTruck}
        handleDetails={handleDetails}
      />
      {showModal && (
        <ModalRoot setShowModal={setShowModal}>
          <VehicleDetails vehicle={currentVehicleType} />
        </ModalRoot>
      )}
      {showConfirmationModal && (
        <ModalRoot setShowModal={setShowConfirmationModal}>
          <ConfirmationModal {...handleConfirmationModal} />
        </ModalRoot>
      )}
    </VehiclesContainer>
  );
};
