import { forwardRef, useEffect, useState } from 'react';

import { DriverDocsProps } from './interfaces';
import {
  CloseButton,
  CloseIcon,
  DriverDocsContainer,
  DriverDocsWrapper,
  ImageContainer,
  ImagesList,
  NextImage,
  ImageItem,
  ImageButton,
  CurrentImage,
  ImagesListWrapper,
  ApprovalButtonsContainer,
  RejectButton,
  ApproveButton,
} from './styles';

export const DriverDocs = forwardRef<HTMLDivElement, DriverDocsProps>(
  ({
    closeModal, driver, onApprove, onRefuse,
  }, ref) => {
    const [activeImage, setActiveImage] = useState('');

    const handleImageChange = (url: string) => {
      setActiveImage(url);
    };

    useEffect(() => {
      setActiveImage(driver?.driver_documents[0]?.document_url);
    }, []);

    return (
      <DriverDocsContainer ref={ref}>
        <CloseButton type="button" onClick={closeModal}>
          <CloseIcon />
        </CloseButton>
        <DriverDocsWrapper>
          <ImageContainer>
            <CurrentImage
              src={activeImage}
              alt={`Documento de ${driver?.user?.individual_person?.name}`}
            />
          </ImageContainer>
          <ImagesListWrapper>
            <ImagesList>
              {driver?.driver_documents?.map(({ document_url }) => (
                <ImageItem>
                  <ImageButton
                    type="button"
                    onClick={() => handleImageChange(document_url)}
                  >
                    <NextImage
                      src={document_url}
                      alt={`Documento de ${driver?.user?.individual_person?.name}`}
                      selected={activeImage === document_url}
                    />
                  </ImageButton>
                </ImageItem>
              ))}
            </ImagesList>
            <ApprovalButtonsContainer>
              <RejectButton type="button" onClick={() => onRefuse(driver.id)}>Reprovado</RejectButton>
              <ApproveButton type="button" onClick={() => onApprove(driver.id)}>Aprovar</ApproveButton>
            </ApprovalButtonsContainer>
          </ImagesListWrapper>
        </DriverDocsWrapper>
      </DriverDocsContainer>
    );
  },
);
