import {
  CancelIcon,
  DollarIcon, LockerIcon, PackIcon, SafeMoneyIcon, TruckIcon, WalletIcon,
} from './styles';

export const content = {
  freight: {
    icon: <PackIcon />,
    title: 'Frete',
  },
  penalty: {
    icon: <DollarIcon />,
    title: 'Multa',
  },
  secure: {
    icon: <LockerIcon />,
    title: 'Seguro',
  },
  vagoo: {
    icon: <WalletIcon />,
    title: 'Receita Bruta Vagoo',
  },
  finished: {
    icon: <TruckIcon />,
    title: 'Viagens realizadas',
  },
  secure_delivery: {
    icon: <LockerIcon />,
    title: 'Viagens com Seguro',
  },
  cancelled: {
    icon: <CancelIcon />,
    title: 'Viagens Canceladas',
  },
  fined_delivery: {
    icon: <SafeMoneyIcon />,
    title: 'Viagens com Multa',
  },
};
