/* eslint-disable no-unused-vars */
import { forwardRef, InputHTMLAttributes, useState } from 'react';
import { FieldError, UseFormRegister } from 'react-hook-form';

import {
  EyeIcon, Input, InputContainer, ToggleButton,
} from './styles';

interface IBaseButtonProps extends InputHTMLAttributes<HTMLInputElement>{
  togglePassword?: boolean;
  error?: FieldError | boolean;
  lined?: boolean;
}

export const BaseInput = forwardRef<
  HTMLInputElement,
  IBaseButtonProps & ReturnType<UseFormRegister<String>>
>(({
  error, togglePassword = false, ...rest
}, ref) => {
  if (togglePassword) {
    const [viewPassword, setViewPassword] = useState(false);

    // eslint-disable-next-line @typescript-eslint/no-unused-vars
    const { type: __, ...props } = rest;

    const handleToggleView = () => {
      setViewPassword((prevState) => !prevState);
    };

    return (
      <InputContainer>
        <Input
          type={viewPassword ? 'text' : 'password'}
          error={!!error}
          {...{ ...props, togglePassword }}
          ref={ref}
        />
        <ToggleButton type="button" onClick={handleToggleView}>
          <EyeIcon />
        </ToggleButton>
      </InputContainer>
    );
  }

  return (
    <Input error={!!error} {...rest} ref={ref} />
  );
});

BaseInput.defaultProps = {
  togglePassword: false,
  error: false,
  lined: false,
};
