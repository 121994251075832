import { Group } from '@visx/group';
import { ParentSize } from '@visx/responsive';
import { Pie } from '@visx/shape';
import { Text } from '@visx/text';

import { content } from './content';

import { IPizzaChartProps } from './interfaces';
import {
  ChartContainer, ChartTitle,
  ChartTitleContainer,
  PizzaChartContainer,
  ChartInfo, ChartBG,
} from './styles';

const valueTextColor = {
  white: '#221551',
  purple: '#fff',
  red: '#fff',
};

const unitTextColor = {
  white: '#22155181',
  purple: '#ccc',
  red: '#fff',
};

export const PizzaChart = ({
  color = 'white', type, data, unit, info,
}: IPizzaChartProps) => {
  const { title, icon } = content[type];

  return (
    <PizzaChartContainer color={color}>
      <ChartTitleContainer>
        {icon}
        <ChartTitle>
          {title}
        </ChartTitle>
      </ChartTitleContainer>
      <ChartContainer>
        <ChartBG color={color} />
        <ParentSize>
          {({ width }) => {
            const half = Math.round(width / 2);

            return (
              <svg
                width={width}
                height={width}
                style={{
                  position: 'relative', zIndex: 2,
                }}
              >
                <Group top={half} left={half}>
                  <Pie
                    data={data}
                    pieSortValues={null}
                    pieValue={({ value }) => value}
                    outerRadius={half * 0.92}
                    innerRadius={half * 0.725}
                    padAngle={0.01}
                  >
                    {(pie) => (pie?.arcs?.map((arc) => {
                      const d = pie.path(arc) || '';

                      return (
                        <g
                          key={arc.data.label}
                        >
                          <path d={d} fill={arc.data.color} />
                        </g>
                      );
                    })
                    )}
                  </Pie>
                  <Text
                    textAnchor="middle"
                    fill={valueTextColor[color]}
                    fontWeight={500}
                    fontSize="0.9375em"
                    dy="-0.1em"
                  >
                    {data[0].value.toLocaleString('pt-BR', {
                      maximumFractionDigits: 2,
                    })}
                  </Text>
                  <Text
                    textAnchor="middle"
                    fill={unitTextColor[color]}
                    fontSize="0.875em"
                    fontWeight={400}
                    dy="0.9em"
                  >
                    {unit || 'reais'}
                  </Text>
                </Group>
              </svg>
            );
          }}
        </ParentSize>
      </ChartContainer>
      <ChartInfo>
        {info || ''}
      </ChartInfo>
    </PizzaChartContainer>
  );
};
