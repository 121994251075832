import styled from 'styled-components';

export const FinancialSettingsContainer = styled.div`
  width: 100%;
  height: fit-content;
`;

export const FinancialSettingsContent = styled.div`
  width: 100%;
  margin-top: 7.3125rem;

  display: flex;
  flex-direction: column;
  gap: 1.25rem;
`;

export const ToggleContainer = styled.div`
  min-height: 2.5625rem;
  height: fit-content;
  max-width: 16rem;
  padding: 0.5rem 0.625rem 0.5rem 1rem;

  border-radius: 1rem;

  display: flex;
  align-items: center;
  justify-content: space-between;

  background-color: rgba(252, 252, 252, 0.78);
  filter: drop-shadow(0px 3px 3px rgba(0, 0, 0, 16%));
`;

export const ToggleLabel = styled.label`
  color: var(--darkPurple);
  font-size: 0.875rem;
  font-weight: 400;
`;
