import { ChangeEvent } from 'react';

import { BaseInput } from '../../atoms/BaseInput';
import { BaseLabel } from '../../atoms/BaseLabel';
import { SubmitButton } from '../../atoms/SubmitButton';

import { MaskPhone } from '../../../utils';

import { SettingsFormProps } from './interfaces';
import {
  FormDividers, FormWrapper, SettingsFormContainer, WarningText,
} from './styles';
import { AvatarHandler } from '../AvatarHandler';

export const SettingsForm = ({
  register, setValue, onSubmit, errors, isSubmitting, isDirty, imageUrl,
}: SettingsFormProps) => {
  const handlePhoneMask = (event: ChangeEvent<HTMLInputElement>) => {
    const { value } = event.target;

    const phone = MaskPhone(value);

    setValue('cellphone', phone);
  };

  return (
    <SettingsFormContainer onSubmit={onSubmit}>
      <FormWrapper>
        <AvatarHandler
          {...{
            register,
            defaultUrl: imageUrl,
            errors: {
              image: errors?.image,
              image_url: errors?.image_url,
            },
          }}
        />
        <FormDividers>
          <BaseLabel className="settings__label">
            Nome *
          </BaseLabel>
          <BaseInput
            type="text"
            className="settings__input"
            error={!!errors?.first_name}
            autoComplete="settings-vagoo first_name"
            {...register('first_name', { required: true })}
          />
          <WarningText>
            {errors?.first_name?.message}
          </WarningText>
          <BaseLabel className="settings__label">
            Sobrenome *
          </BaseLabel>
          <BaseInput
            type="text"
            className="settings__input"
            error={!!errors?.last_name}
            autoComplete="settings-vagoo last_name"
            {...register('last_name', { required: true })}
          />
          <WarningText>
            {errors?.last_name?.message}
          </WarningText>
          <BaseLabel className="settings__label">
            Celular *
          </BaseLabel>
          <BaseInput
            type="text"
            className="settings__input"
            error={!!errors?.cellphone}
            autoComplete="settings-vagoo phone"
            {...register('cellphone', {
              required: true, onChange: handlePhoneMask,
            })}
          />
          <WarningText>
            {errors?.cellphone?.message}
          </WarningText>
        </FormDividers>
        <FormDividers>
          <BaseLabel className="settings__label">
            E-mail *
          </BaseLabel>
          <BaseInput
            type="email"
            className="settings__input"
            error={!!errors?.email}
            autoComplete="settings-vagoo email"
            {...register('email', { required: true })}
          />
          <WarningText>
            {errors?.email?.message}
          </WarningText>
          <BaseLabel className="settings__label">
            Senha *
          </BaseLabel>
          <BaseInput
            type="password"
            className="settings__input"
            error={!!errors?.password}
            autoComplete="settings-vagoo password"
            togglePassword
            {...register('password', { required: true })}
          />
          <WarningText>
            {errors?.password?.message}
          </WarningText>
          <BaseLabel className="settings__label">
            Confirmar Senha *
          </BaseLabel>
          <BaseInput
            type="password"
            className="settings__input"
            error={!!errors?.password_confirmation}
            autoComplete="settings-vagoo password_confirm"
            togglePassword
            {...register('password_confirmation', { required: true })}
          />
          <WarningText>
            {errors?.password_confirmation?.message}
          </WarningText>
        </FormDividers>
      </FormWrapper>
      <SubmitButton
        type="submit"
        className="settings__button"
        loading={!!isSubmitting}
        disabled={!isDirty}
      >
        Salvar
      </SubmitButton>
    </SettingsFormContainer>
  );
};
