import styled from 'styled-components';

export const ListVehiclesTypeContainer = styled.ul`
  width: 100%;
  max-width: 58.1875rem;
  height: fit-content;
  margin-top: 1.0625rem;
  padding: 0 1rem 2rem;
`;

export const EmptyMessage = styled.h1`
  margin-top: 1.0625rem;

  display: inline-block;

  font-size: 1.2rem;
  color: var(--darkPurple)
`;
