import { useEffect, useReducer } from 'react';
import toast from 'react-hot-toast';
import { FinancialDashboard } from '../../components/organisms/FinancialDashboard/FinancialDashboard';
import { OperationalDashboard } from '../../components/organisms/OperationalDashboard/OperationalDashboard';
import { api } from '../../services/api';
import {
  FinancialDashboardResponse,
  OperationalDashboardResponse,
} from './interfaces';
import { dashboardReducer, initialDashboardState } from './reducer';

import {
  DashboardContainer,
  DashboardContent, DashboardTypeButton,
  DashboardTypeSelector,
} from './styles';

export const Dashboard = () => {
  const [dashboardState, dispatch] = useReducer(
    dashboardReducer,
    initialDashboardState,
  );

  const { dashboardType, filters } = dashboardState;

  const fetchFinancialDashboard = async () => {
    try {
      const response = await api.get<FinancialDashboardResponse>(
        '/dashboard/financial',
        {
          params: {
            timespan: filters.request,
            limit: 6,
          },
        },
      );

      dispatch({
        type: 'SET_FINANCIAL_DASHBOARD',
        payload: {
          timespan: filters.request,
          data: response.data,
        },
      });
    } catch (error: any) {
      toast.error(
        error?.response?.data?.message
          || error?.message
          || 'Algo deu errado tente novamente mais tarde',
      );
    }
  };

  const fetchOperationalDashboard = async () => {
    try {
      const response = await api.get<OperationalDashboardResponse>(
        '/dashboard/operational',
        {
          params: {
            timespan: filters.request,
            limit: 6,
          },
        },
      );

      dispatch({
        type: 'SET_OPERATIONAL_DASHBOARD',
        payload: {
          timespan: filters.request,
          data: response.data,
        },
      });
    } catch (error: any) {
      toast.error(
        error?.response?.data?.message
          || error?.message
          || 'Algo deu errado tente novamente mais tarde',
      );
    }
  };

  useEffect(() => {
    fetchFinancialDashboard();
    fetchOperationalDashboard();
  }, [filters.request]);

  return (
    <DashboardContainer>
      <DashboardContent>
        <DashboardTypeSelector>
          <DashboardTypeButton
            active={dashboardType === 'financial'}
            onClick={() => dispatch({
              type: 'CHANGE_DASHBOARD_TYPE',
              payload: 'financial',
            })}
          >
            Dashboard Financeiro
          </DashboardTypeButton>
          <DashboardTypeButton
            active={dashboardType === 'operational'}
            onClick={() => dispatch({
              type: 'CHANGE_DASHBOARD_TYPE',
              payload: 'operational',
            })}
          >
            Dashboard Operacional
          </DashboardTypeButton>
        </DashboardTypeSelector>
        {dashboardType === 'financial' ? (
          <FinancialDashboard state={dashboardState} dispatch={dispatch} />
        ) : <OperationalDashboard state={dashboardState} dispatch={dispatch} />}
      </DashboardContent>
    </DashboardContainer>
  );
};
