import { VehicleTypeCard } from '../VehicleTypeCard';

import { IListVehiclesTypesProps } from './interfaces';

import {
  EmptyMessage, ListVehiclesTypeContainer,
} from './styles';

export const ListVehiclesTypes = ({
  vehicleTypes,
  onRemove,
  onEdit,
  handleDetails,
}: IListVehiclesTypesProps) => (
  <ListVehiclesTypeContainer>
    {vehicleTypes?.length > 0 ? (
      vehicleTypes?.map((vehicleType) => (
        <VehicleTypeCard
          key={vehicleType?.id}
          vehicleType={vehicleType}
          onEdit={onEdit}
          onRemove={onRemove}
          handleDetails={handleDetails}
        />
      ))
    ) : (
      <EmptyMessage>
        Nenhum tipo de veiculo encontrado!
      </EmptyMessage>
    )}
  </ListVehiclesTypeContainer>
);
