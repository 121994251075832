import styled from 'styled-components';

export const Toggle = styled.input.attrs({
  type: 'checkbox',
})`
  --inactive-background: linear-gradient(315deg, #FF6565 0%, #FF8000 110%);
  --active-background: linear-gradient(315deg, #2CCCD3 0%, #00FFCB 110%);

  appearance: none;
  width: 2.875rem;
  height: 1.625rem;

  background-color: transparent;
  border-radius: 1rem;

  box-shadow: inset 0px 3px 7px rgba(0, 0, 0, 0.1);

  border: 1px solid #f6f6f5;

  cursor: pointer;

  &:after {
    content: "";
    width: 1.5rem;
    height: 1.5rem;
    position: relative;

    display: inline-block;

    border-radius: 50%;

    background: var(--inactive-background);
    transition: transform 0.2s ease;
    filter: drop-shadow(0 0 1.5px rgba(255, 255, 255, 0.8));
  }

  &:checked {
    &:after {
      background: var(--active-background);
      transform: translateX(100%);
    }
  }

  @media (max-width: 360px) {
    &:after {
      width: 1.375rem;
      height: 1.375rem;

      bottom: 2px;
    }
  }
`;
