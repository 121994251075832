import styled from 'styled-components';

import { ReactComponent as FileIconRaw } from '../../../assets/images/follow-delivery/file-icon.svg';
import { ReactComponent as ImageIconRaw } from '../../../assets/images/follow-delivery/image-icon.svg';
import { ReactComponent as DownloadIconRaw } from '../../../assets/images/follow-delivery/download-icon.svg';

export const Container = styled.div`
  width: 90%;
  margin: 2.51875rem auto 0;
`;

export const FileIcon = styled(FileIconRaw)`
  width: 0.9375rem;
  height: 1.171875rem;

  vertical-align: middle;
`;

export const TitleFileContainer = styled.div`
  width: 100%;
  height: fit-content;
`;

export const FileContainer = styled.div`
  width: 92.5%;
  height: fit-content;
  margin: 0.8125rem auto 0;

  display: flex;
  align-items: center;
`;

export const FileWrapper = styled.div`
  width: 100%;
  max-width: 18rem;
  height: 2rem;

  display: flex;
  align-items: center;
  gap: 0.5rem;
  padding: 0.5rem;

  border-radius: 0.625rem;
  box-shadow: 0 0 10px rgba(0, 0, 0, 0.18);
`;

export const ImageIcon = styled(ImageIconRaw)`
  width: 0.7425rem;
  height: 0.92875rem;
`;

export const FileTitle = styled.h1`
  color: #DBDBDB;
  font-size: 0.8rem;
  font-weight: lighter;
`;

export const DownloadAnchor = styled.button`
  background: transparent;
  border: none;

  width: fit-content;
  height: fit-content;
  margin-left: 0.80625rem;

  display: block;

  text-decoration: none;
  white-space: nowrap;

  cursor: pointer;

  transition: filter 0.2s;

  &:hover {
    filter: brightness(0.8);
  }

  &:disabled {
    &:hover {
      filter: unset;
    }
  }
`;

export const AnchorText = styled.h2`
  margin-left: 0.55625rem;

  display: inline-block;

  color: var(--softBlue);
  font-size: 1rem;
  font-weight: normal;
`;

export const DownloadIcon = styled(DownloadIconRaw)`
  width: 0.886875rem;
  height: 1.053125rem;

  display: inline-block;
`;
