import styled from 'styled-components';

export const AvatarHandlerContainer = styled.div`
  width: 11.8125rem;
  max-width: 100%;
  height: fit-content;
`;

export const ImageContainer = styled.label`
  width: 11.8125rem;
  height: 11.8125rem;

  display: block;

  overflow: hidden;

  border-radius: 50%;
  border: 0;
  background-color: #E5E5E5;
`;

export const AvatarImg = styled.img`
  width: 101%;
  height: 101%;
  margin: -1% 0 0 -1%;

  background-color: #E5E5E5;
  object-fit: cover;
`;

export const AvatarLabel = styled.label`
  width: 100%;
  margin-top: 0.5625rem;

  display: block;

  cursor: pointer;

  color: var(--lightGreen);
  font-size: 1.125rem;
  text-align: center;
  text-decoration: underline;
`;

export const GhostInput = styled.input`
  display: none;
`;

export const WarningText = styled.h2`
  margin: 0.8rem 0 1rem;

  color: #f00;
  font-size: 0.9rem;
  text-align: center;
`;
