import { DriverCard } from '../../molecules/DriverCard';
import { IListDriversProps } from './interfaces';

import { EmptyMessage, ListDriversContainer } from './styles';

export const ListDrivers = ({
  drivers, onRemove, onApprove, setCurrentDriver,
}: IListDriversProps) => (
  <ListDriversContainer>
    {drivers?.length >= 1 ? (
      drivers?.map((driver) => (
        <DriverCard
          key={driver?.id}
          driver={driver}
          onRemove={onRemove}
          onApprove={onApprove}
          setCurrentDriver={setCurrentDriver}
        />
      ))
    ) : (
      <EmptyMessage>
        Nenhum motorista encontrado!
      </EmptyMessage>
    )}
  </ListDriversContainer>
);
