/* eslint-disable react/require-default-props */

import { curveCardinal } from '@visx/curve';
import { ParentSize } from '@visx/responsive';
import {
  AnimatedAreaSeries,
  AnimatedAxis,
  AnimatedGrid,
  Tooltip,
  XYChart,
} from '@visx/xychart';

import { LinearGradient } from '@visx/gradient';
import { accessors } from './accessors';

import {
  ChartLegend,
  ChartWrapper,
  LegendWrapper,
  RevenueChartContainer,
  RevenueChartHeader,
} from './styles';
import { customTheme } from './customTheme';
import { FiltersSelector } from '../../molecules/FiltersSelector';
import { TimeSpan } from '../../../pages/Dashboard/reducer';

interface IRevenueChartProps {
  vagooIncome?: {
    x: string;
    y: number;
  }[];
  totalIncome?: {
    x: string;
    y: number;
  }[];
  onChangeTimespan: (timespan: TimeSpan) => void;
  timespan: TimeSpan;
}

export const RevenueChart = ({
  onChangeTimespan, vagooIncome, timespan, totalIncome,
}: IRevenueChartProps) => {
  const handleFilterChange = (text: string) => {
    onChangeTimespan(text as TimeSpan);
  };

  return (
    <RevenueChartContainer>
      <RevenueChartHeader>
        <FiltersSelector
          value={timespan}
          onChange={handleFilterChange}
        />
        <LegendWrapper>
          <ChartLegend color="#00FFCB">Receita Bruta Total</ChartLegend>
          <ChartLegend color="#0A76AE">Receita Bruta Vagoo</ChartLegend>
        </LegendWrapper>
      </RevenueChartHeader>
      <ChartWrapper>
        <ParentSize>
          {({ width, height }) => (
            <XYChart
              width={width}
              height={height}
              xScale={{ type: 'band' }}
              yScale={{ type: 'linear' }}
              theme={customTheme}
            >
              <LinearGradient
                id="total-area"
                from="#00FFCB"
                to="#12c9d65a"
                toOpacity={0.6}
              />
              <LinearGradient
                id="vagoo-area"
                from="#0A76AE"
                to="#7acccf60"
                toOpacity={0.4}
              />
              <AnimatedAxis orientation="bottom" />
              <AnimatedAxis orientation="left" tickLength={6} />
              <AnimatedGrid columns numTicks={6} />
              <AnimatedAreaSeries
                dataKey="Receita Bruta Vagoo"
                data={vagooIncome || []}
                stroke="url(#vagoo-area)"
                fill="url(#vagoo-area)"
                curve={curveCardinal}
                {...accessors}
              />
              <AnimatedAreaSeries
                dataKey="Receita Bruta Total"
                data={totalIncome || []}
                stroke="url(#total-area)"
                fill="url(#total-area)"
                curve={curveCardinal}
                {...accessors}
              />
              <Tooltip
                snapTooltipToDatumX
                snapTooltipToDatumY
                showVerticalCrosshair
                showSeriesGlyphs
                renderTooltip={({ tooltipData, colorScale }: any) => (
                  <div>
                    <div
                      style={{
                        color: colorScale(tooltipData.nearestDatum.key),
                      }}
                    >
                      {tooltipData.nearestDatum.key}
                    </div>
                    {accessors
                      .xAccessor(tooltipData.nearestDatum.datum)
                      .split('-')
                      .reverse()
                      .join('/')}
                    {', '}
                    {accessors.yAccessor(tooltipData.nearestDatum.datum)}
                  </div>
                )}
              />
            </XYChart>
          )}
        </ParentSize>
      </ChartWrapper>
    </RevenueChartContainer>
  );
};
