import { SubmitHandler, useForm } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';
import toast from 'react-hot-toast';

import { PageTitle } from '../../components/atoms/PageTitle';
import { AddAdminForm } from '../../components/organisms/AddAdminForm';

import { schema } from './validation';
import { api } from '../../services/api';

import { IAddAdminFormData } from './interfaces';
import {
  AddAdminContainer, PlusUser,
  SubText, SubTitle,
} from './styles';

export const AddAdmin = () => {
  const {
    register, handleSubmit, setValue, reset, formState: { isSubmitting, errors },
  } = useForm<IAddAdminFormData>({
    resolver: yupResolver(schema),
  });

  const onSubmit: SubmitHandler<IAddAdminFormData> = async ({
    first_name,
    last_name,
    email,
    password,
    password_confirmation,
    cellphone,
  }) => {
    const requestPayload = {
      name: `${first_name} ${last_name}`,
      email,
      password,
      password_confirmation,
      cellphone: cellphone
        .replace(/\(/g, '')
        .replace(/\)/g, '')
        .replace(/-/g, '')
        .replace(' ', ''),
    };

    try {
      await api.post('/admin', requestPayload);

      toast.success('Administrador cadastrado com sucesso!');

      reset();
    } catch (error: any) {
      toast.error(error?.data?.message || error?.message);
    }
  };

  return (
    <AddAdminContainer>
      <PageTitle>
        Adicionar Administrador
      </PageTitle>
      <SubTitle>
        <PlusUser />
        <SubText>
          Cadastre um novo administrador Vagoo
        </SubText>
      </SubTitle>
      <AddAdminForm
        onSubmit={handleSubmit(onSubmit)}
        {...{
          register, setValue, errors, isSubmitting,
        }}
      />
    </AddAdminContainer>
  );
};
