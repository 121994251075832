import { FormatCurrency } from '../../../utils/FormatCurrency';
import { IDeliveryValueDetailsCardProps } from './interfaces';
import {
  DeliveryDetails,
  DeliveryValuesContainer,
  DetailLabel,
  DetailValue,
  TotalValueAmount,
  TotalValueContainer,
  TotalValueLabel,
  ValueAmount,
  ValueLabel,
  ValueTitle,
} from './styles';

const paymentMethodLabels = {
  credit_card: 'Cartão de crédito',
  boleto: 'Boleto',
  pix: 'Pix',
} as {
  [key: string]: string;
};

export const DeliveryValueDetailsCard = ({
  delivery,
}: IDeliveryValueDetailsCardProps) => (
  <DeliveryDetails>
    <div>
      <DetailLabel>Método de Pagamento:</DetailLabel>
      <DetailValue>
        {delivery?.payment_method
          ? (paymentMethodLabels[delivery.payment_method]
            || delivery.payment_method)
          : '-'}
      </DetailValue>
    </div>
    <div>
      <DetailLabel>CTE:</DetailLabel>
      <DetailValue>{delivery.cte ? 'Emitida' : 'Não emitida'}</DetailValue>
    </div>
    <ValueTitle>Valor</ValueTitle>
    <DeliveryValuesContainer>
      <div>
        <ValueLabel>Valor frete</ValueLabel>
        <ValueAmount>{FormatCurrency(delivery.transport_cost)}</ValueAmount>
      </div>
      {/* <div>
        <ValueLabel>Valor Pedágio</ValueLabel>
        <ValueAmount>{FormatCurrency(delivery.toll_cost)}</ValueAmount>
      </div> */}
      <div>
        <ValueLabel>Valor seguro</ValueLabel>
        <ValueAmount>{FormatCurrency(delivery.insurance_cost)}</ValueAmount>
      </div>
    </DeliveryValuesContainer>
    <TotalValueContainer>
      <TotalValueLabel>Valor total</TotalValueLabel>
      <TotalValueAmount>{FormatCurrency(delivery.total_cost)}</TotalValueAmount>
    </TotalValueContainer>
  </DeliveryDetails>
);
