import { Dispatch } from 'react';
import {
  DashboardReducerActions,
  DashboardState,
  TimeSpan,
} from '../../../pages/Dashboard/reducer';
import { FiltersSelector } from '../../molecules/FiltersSelector';
import { TotalChart } from '../../molecules/TotalChart';
import { DeliveriesChart } from '../DeliveriesChart';
import { DeliveriesPizzaCharts } from '../DeliveriesPizzaCharts';
import { operationalDashboardContent } from './content';
import {
  ChartContainer,
  DashboardRow,
  PiesChartsContainer,
  SectionTitle,
  SectionTitleContainer,
  SectionTitleNote,
  TotalRevenueContainer,
} from './styles';

interface IOperationalDashboardProps {
  state: DashboardState;
  dispatch: Dispatch<DashboardReducerActions>;
}

export const OperationalDashboard = ({
  state,
  dispatch,
}: IOperationalDashboardProps) => {
  const { firstSectionTitle, chartSectionTitle, secondSectionTitle } = operationalDashboardContent;

  const { filters, operational } = state;

  return (
    <>
      <DashboardRow>
        <PiesChartsContainer>
          <SectionTitleContainer>
            {firstSectionTitle.icon}
            <SectionTitle>{firstSectionTitle.label}</SectionTitle>
          </SectionTitleContainer>
          <FiltersSelector
            value={filters.operational.tripNumber}
            onChange={(value) => dispatch({
              type: 'CHANGE_SECTION_TIME_SPAN',
              payload: {
                type: 'operational',
                section: 'tripNumber',
                timespan: value as TimeSpan,
              },
            })}
            customClassName="pies-charts-container__filters-selector"
          />
          <DeliveriesPizzaCharts
            data={operational[filters.operational.tripNumber]?.tripNumber}
          />
        </PiesChartsContainer>
        <TotalRevenueContainer>
          <SectionTitleContainer>
            {secondSectionTitle.icon}
            <SectionTitle>{secondSectionTitle.label}</SectionTitle>
            {secondSectionTitle?.note && (
              <SectionTitleNote>{secondSectionTitle.note}</SectionTitleNote>
            )}
          </SectionTitleContainer>
          <FiltersSelector
            value={filters.operational.totalTrips}
            onChange={(value) => dispatch({
              type: 'CHANGE_SECTION_TIME_SPAN',
              payload: {
                type: 'operational',
                section: 'totalTrips',
                timespan: value as TimeSpan,
              },
            })}
            customClassName="total-revenue-container__filters-selector"
          />
          <TotalChart
            type="operational"
            unit="viagens"
            data={
              operational[filters.operational.totalTrips]?.totalTrips.trip_count
            }
          />
        </TotalRevenueContainer>
      </DashboardRow>
      <DashboardRow>
        <ChartContainer>
          <SectionTitleContainer>
            {chartSectionTitle.icon}
            <SectionTitle>{chartSectionTitle.label}</SectionTitle>
          </SectionTitleContainer>
          <DeliveriesChart
            onChangeTimespan={(value: TimeSpan) => {
              dispatch({
                type: 'CHANGE_SECTION_TIME_SPAN',
                payload: {
                  type: 'operational',
                  section: 'tripGraph',
                  timespan: value,
                },
              });
            }}
            timespan={filters.operational.tripGraph}
            total={operational[filters.operational.tripGraph]?.tripGraph?.total}
            finished={
              operational[filters.operational.tripGraph]?.tripGraph?.finished
            }
            canceled={
              operational[filters.operational.tripGraph]?.tripGraph?.canceled
            }
          />
        </ChartContainer>
      </DashboardRow>
    </>
  );
};
