import styled from 'styled-components';

export const DashboardContainer = styled.section`
  width: 100%;
  height: fit-content;

  position: relative;
  margin-top: 8rem;

  max-width: 1200px;

  @media (min-width: 600px) {
    margin-top: 3rem;
  }
`;

export const DashboardContent = styled.div`
  width: 100%;
  height: fit-content;
  background: white;

  padding: 2.25rem 2.25rem 2rem;

  filter: drop-shadow(0 3px 6px rgba(0, 0, 0, 0.16));

  border-radius: 0 1.875rem 1.875rem 1.875rem;
`;

export const DashboardTypeSelector = styled.div`
  display: flex;
  flex-direction: column-reverse;

  align-items: center;

  width: fit-content;
  height: fit-content;

  position: absolute;

  top: -7rem;
  left: 0;

  @media (min-width: 600px) {
    flex-direction: row;
    gap: 4rem;
    top: -3rem;
  }
`;

export const DashboardTypeButton = styled.button<{
  active: boolean;
}>`
  background: white;
  border: none;
  border-radius: 0.625rem;

  padding: 0.875rem;

  width: 20.5rem;
  height: 4rem;
  display: flex;
  justify-content: center;
  align-items: flex-start;

  font-size: 1.5rem;
  font-weight: bold;
  color: ${({ active }) => (active ? 'var(--darkPurple)' : 'rgba(120, 191, 194, 0.6)')};

  cursor: pointer;
`;
