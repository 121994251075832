import styled from 'styled-components';

export const ChargeDetails = styled.fieldset`
  width: 40%;
  height: fit-content;
  min-height: 6.5rem;
  margin: 1.0125rem auto 0;

  border-radius: 0.625rem;
  border: 2px solid var(--softPurple);

  @media (min-width: 605px) {
    width: 95%;
  }
`;

export const ChangeHeader = styled.div`
  padding: 0.79375rem 0;

  border-bottom: 2px solid var(--softPurple);
`;

export const ChargeTitle = styled.h1`
  width: 90%;
  height: fit-content;
  margin: 0 auto;

  display: block;
  vertical-align: middle;

  color: var(--darkPurple);
  font-size: 1rem;
  font-weight: bold;
`;

export const ChargeContent = styled.div`
  width: 90%;
  margin: 0 auto;
  padding: 0.7375rem 0 1.03125rem;

  display: flex;
  justify-content: space-between;
  gap: 0.5rem;
  flex-direction: column;

  @media (min-width: 605px) {
    flex-direction: row;
  }
`;

export const ChargeLabel = styled.h2`
  color: var(--darkPurple);
  font-size: 0.75rem;
  font-weight: normal;
`;

export const ChargeValue = styled.h1`
  color: var(--darkPurple);
  font-size: 1rem;
  font-weight: bold;
`;
