import styled from 'styled-components';

import { ReactComponent as MarkerIconRaw } from '../../../assets/images/follow-delivery/marker-icon.svg';
import { ReactComponent as TruckIconRaw } from '../../../assets/images/follow-delivery/purple-truck-icon.svg';

interface ITrackerBulletProps {
  isActive?: boolean;
}

interface ITruckerIconProps {
  stage: number;
}

export const TrackerContainer = styled.div`
  width: 85%;
  margin: 1.43125rem auto 3rem;

  display: flex;
  justify-content: space-between;
  align-items: center;

  position: relative;
  z-index: 1;
`;

export const MarkerIcon = styled(MarkerIconRaw)`
  width: 1.055rem;
  height: 1.506875rem;
`;

export const StageContainer = styled.div`
  width: fit-content;
  height: fit-content;

  position: relative;
`;

export const MarkerBullet = styled.div<ITrackerBulletProps>`
  width: 0.45rem;
  height: 0.45rem;
  margin-top: -0.2rem;

  position: relative;
  z-index: 1;

  border-radius: 50%;
  background-color: var(--darkPurple);
  transition: all 0.2s ease;

  ${({ isActive }) => isActive && `
    outline: 1.25rem solid rgba(207, 203, 241, .58);
    -webkit-background-clip: padding-box;
    background-clip: padding-box;
  `}
`;

export const MarkerLegend = styled.h1`
  position: absolute;
  left: -3rem;
  right: -3rem;
  top: 2.5rem;

  color: var(--darkPurple);
  font-size: 0.625rem;

  text-align: center;
`;

export const TrackLegend = styled.h1`
  position: absolute;
  left: -3rem;
  right: -3rem;
  top: 2rem;

  color: var(--darkPurple);
  font-size: 0.625rem;

  text-align: center;
`;

export const TrackerLine = styled.div`
  width: calc(100% + -1.3rem);
  height: 0.125rem;

  position: absolute;
  top: calc(50% + -0.125rem);
  left: 1.3rem;
  right: 0;
  z-index: -1;

  background-color: rgba(232, 230, 248, 1);
`;

export const TruckIcon = styled(TruckIconRaw)<ITruckerIconProps>`
  width: 1.2875rem;
  height: 1.04875rem;

  position: absolute;
  top: -25%;

  /* ${({ stage }) => {
    if (stage === 0) {
      return `
        left: 16.66%;
      `;
    }
    if (stage === 1) {
      return `
        left: 50%;
      `;
    }
    if (stage === 2) {
      return `
        left: 83.33%;
      `;
    }
    if (stage === 3) {
      return `
        display: none;
      `;
    }

    return null;
  }} */

  left: ${({ stage }) => `${stage * 16.6}%`};
  display: ${({ stage }) => (stage === 0 ? 'none' : 'block')};
`;
