import styled from 'styled-components';

interface ChartLegendProps {
  color: string;
}

export const RevenueChartContainer = styled.article`
  width: 100%;
  height: fit-content;
  min-height: 17.9375rem;
  padding: 1.375rem 0 0;

  border-radius: 0.9375rem;
  background-color: #F5F5F5;
  box-shadow: 0 3px 6px rgba(0, 0, 0, 0.16);
`;

export const RevenueChartHeader = styled.header`
  width: 95%;
  margin: 0 auto;

  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 0.8rem;
  justify-content: space-between;

  @media (min-width: 568px) {
    flex-direction: row;
    gap: unset;
    align-items: unset;
  }
`;

export const ButtonsWrapper = styled.div`
  display: flex;
  justify-content: flex-start;
  align-items: center;
  gap: 0.625rem;
`;

export const FilterButton = styled.button`
  padding: 0.2rem 0.4rem;

  background-color: #2CCCD3;
  border-radius: 1rem;
  cursor: pointer;

  color: #fff;
  font-size: 0.8rem;
`;

export const LegendWrapper = styled.div`
  display: flex;
  justify-content: flex-end;
  align-items: center;
  gap: 0.8rem;
`;

export const ChartLegend = styled.span<ChartLegendProps>`
  display: inline-block;

  font-size: 1rem;
  font-weight: bold;
  color: var(--darkPurple);

  &:before {
    content: '';
    width: 1rem;
    height: 1rem;
    margin-right: 0.5rem;

    display: inline-block;

    background-color: ${(props) => props.color};
  }
`;

export const ChartWrapper = styled.div`
  width: 100%;
  height: 18rem;

  @media (min-width: 745px) {
    height: 13.65625rem;
  }
`;
