import { SubmitHandler, useForm } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';
import toast from 'react-hot-toast';

import { PageTitle } from '../../components/atoms/PageTitle';
import { AddVehicleTypeForm } from '../../components/organisms/AddVehicleTypeForm';

import { schema } from './validation';
import { api } from '../../services/api';

import { IAddVehicleTypeFormData, IAddVehicleTypeFormDataKeys } from './interfaces';
import { AddVehicleTypeContainer } from './styles';

export const AddVehicleType = () => {
  const {
    register, handleSubmit, reset, formState: { errors, isSubmitting },
  } = useForm<IAddVehicleTypeFormData>({
    resolver: yupResolver(schema),
  });

  const onSubmit: SubmitHandler<IAddVehicleTypeFormData> = async (data) => {
    if (data.image.length <= 0) {
      toast.error('É necessário enviar uma foto do veículo!');

      return;
    }

    try {
      const formData = new FormData();

      Object.keys(data).forEach((key) => {
        if (key !== 'image' && key !== 'vehicle_pattern') {
          if (data[key as IAddVehicleTypeFormDataKeys]) {
            formData.append(key, data[key as IAddVehicleTypeFormDataKeys] as string);
          }
        }
      });

      const photo = data.image[0];

      formData.append('description', ' ');
      formData.append('photo', photo);
      formData.set('vehicle_class_id', '1');

      await api.post('/vehicle-types', formData);

      toast.success('Tipo de veículo cadastrado com sucesso!');

      reset();
    } catch (error: any) {
      toast.error(error?.response?.data?.message || error?.response?.messageString);
    }
  };

  return (
    <AddVehicleTypeContainer>
      <PageTitle>
        Adicionar Novo Tipo de Veículo
      </PageTitle>
      <AddVehicleTypeForm
        onSubmit={handleSubmit(onSubmit)}
        {...{ register, isSubmitting, errors }}
      />
    </AddVehicleTypeContainer>
  );
};
