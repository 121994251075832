import { ChangeEvent, useEffect, useState } from 'react';
import toast from 'react-hot-toast';
import { PageTitle } from '../../components/atoms/PageTitle';
import { Toggle } from '../../components/atoms/Toggle';

import { api } from '../../services/api';
import { IFinancialSettingsItems, ISystemInfos } from './interfaces';
import {
  FinancialSettingsContainer,
  FinancialSettingsContent,
  ToggleContainer,
  ToggleLabel,
} from './styles';

const defaultFinancialSettings: IFinancialSettingsItems = {
  bank_slip_enabled: true,
  credit_card_enabled: true,
  pix_enabled: true,
};

export const FinancialSettings = () => {
  const [financialSettings, setFinancialSettings] = useState(
    defaultFinancialSettings,
  );

  const ensureFinancialSettings = async () => {
    try {
      const response = await api.get<{
        system_infos: ISystemInfos;
      }>('/system-infos');

      const { bank_slip_enabled, credit_card_enabled, pix_enabled } = response.data.system_infos;

      setFinancialSettings({
        bank_slip_enabled,
        credit_card_enabled,
        pix_enabled,
      });
    } catch (error: any) {
      toast.error(error?.response?.data?.message || error?.message);
    }
  };

  useEffect(() => {
    ensureFinancialSettings();
  }, []);

  const handleToggle = async (event: ChangeEvent<HTMLInputElement>) => {
    const { name, checked } = event.target;

    try {
      await api.put('/system-infos', {
        [name]: checked,
      });

      setFinancialSettings((prevState) => ({
        ...prevState,
        [name]: checked,
      }));
    } catch (error: any) {
      toast.error(error?.response?.data?.message || error?.message || 'Algo deu errado, tente novamente mais tarde!');
    }
  };

  const { bank_slip_enabled, credit_card_enabled, pix_enabled } = financialSettings;

  return (
    <FinancialSettingsContainer>
      <PageTitle>Configurar Financeiro</PageTitle>
      <FinancialSettingsContent>
        <ToggleContainer>
          <ToggleLabel>Boleto Bancário</ToggleLabel>
          <Toggle
            name="bank_slip_enabled"
            checked={bank_slip_enabled}
            onChange={handleToggle}
          />
        </ToggleContainer>
        <ToggleContainer>
          <ToggleLabel>
            Cartão de Crédito
          </ToggleLabel>
          <Toggle
            name="credit_card_enabled"
            checked={credit_card_enabled}
            onChange={handleToggle}
          />
        </ToggleContainer>
        <ToggleContainer>
          <ToggleLabel>PIX</ToggleLabel>
          <Toggle
            name="pix_enabled"
            checked={pix_enabled}
            onChange={handleToggle}
          />
        </ToggleContainer>
      </FinancialSettingsContent>
    </FinancialSettingsContainer>
  );
};
