import { TripStatus } from '../../../pages/FollowDelivery/interfaces';
import {
  MarkerBullet, MarkerIcon, MarkerLegend,
  StageContainer, TrackerContainer, TrackerLine, TrackLegend, TruckIcon,
} from './styles';

interface IDeliveryTrackerCardProps {
  status: TripStatus;
}

const getStageFromStatus = (status: TripStatus) => {
  switch (status) {
    case TripStatus.Collecting:
      return 1;
    case TripStatus.OnGoing:
      return 2;
    case TripStatus.Finished:
      return 3;
    default:
      return 0;
  }
};

const getTruckStage = (status: TripStatus) => {
  switch (status) {
    case TripStatus.Pending:
      return 0;
    case TripStatus.Displacement:
      return 1;
    case TripStatus.Collecting:
      return 2;
    case TripStatus.OnGoing:
      return 3;
    case TripStatus.Finished:
      return 5;
    default:
      return 0;
  }
};

const DeliveryTrackerCard = ({
  status,
}: IDeliveryTrackerCardProps) => {
  const stage = getStageFromStatus(status);
  const truckStage = getTruckStage(status);

  return (
    <TrackerContainer>
      <StageContainer>
        <MarkerIcon />
        <MarkerLegend>
          DESLOCAMENTO
        </MarkerLegend>
      </StageContainer>
      <StageContainer>
        <MarkerBullet isActive={stage >= 1} />
        <TrackLegend>
          COLETA
        </TrackLegend>
      </StageContainer>
      <StageContainer>
        <MarkerBullet isActive={stage >= 2} />
        <TrackLegend>
          ENTREGA
        </TrackLegend>
      </StageContainer>
      <StageContainer>
        <MarkerBullet isActive={stage === 3} />
        <TrackLegend>
          COMPROVANTE ENVIADO
        </TrackLegend>
      </StageContainer>
      <TruckIcon stage={truckStage} />
      <TrackerLine />
    </TrackerContainer>
  );
};

export default DeliveryTrackerCard;
