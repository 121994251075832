import styled from 'styled-components';

import { ReactComponent as GearIconRaw } from '../../../assets/images/user-box/user-gear-icon.svg';
import { ReactComponent as ExitIconRaw } from '../../../assets/images/user-box/user-exit-icon.svg';

export const UserBoxContainer = styled.section`
  width: 7.346rem;
  height: 3.075rem;
  padding: 0 0.75rem;

  display: flex;
  align-items: center;
  justify-content: space-between;

  position: relative;
  bottom: -1rem;

  border-radius: 0.8125rem 0.8125rem 0 0;
  background-color: var(--softBlue);

  @media (min-width: 478px) {
    width: 9.1825rem;
    height: 3.84375rem;

    bottom: -0.7rem;
  }
`;

export const ImageContainer = styled.span`
  width: 2.3rem;
  height: 2.3rem;
  aspect-ratio: 1;

  display: inline-block;

  border: 2px solid #fff;
  background-color: #fff;
  border-radius: 50%;
  cursor: pointer;

  @media (min-width: 478px) {
    width: 2.875rem;
    height: 2.875rem;
  }
`;

export const UserImage = styled.img`
  width: 100%;
  height: 100%;

  object-fit: cover;
  border-radius: 50%;
`;

export const IconButton = styled.button`
  width: fit-content;
  height: fit-content;

  background-color: transparent;
  cursor: pointer;
  transition: all 0.2s ease;

  &:hover {
    filter: brightness(0.9);
  }
`;

export const GearIcon = styled(GearIconRaw)`
  width: 1.12rem;
  height: 1.12rem;

  @media (min-width: 478px) {
    width: 1.4rem;
    height: 1.4rem;
  }
`;

export const ExitIcon = styled(ExitIconRaw)`
  width: 1.317rem;
  height: 1.062rem;

  @media (min-width: 478px) {
    width: 1.64625rem;
    height: 1.3275rem;
  }
`;
