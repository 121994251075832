import { DashboardCard, initialDashboardCard, VagooIncomeSection } from '../../../pages/Dashboard/reducer';
import { PizzaChart } from '../../molecules/PizzaChart';

import { RevenueChartsContainer } from './styles';

interface IRevenuePizzaChartsProps {
  // eslint-disable-next-line react/require-default-props
  data?: VagooIncomeSection;
}

interface PizzaChartDataPoint {
  label: string;
  value: number;
  color: string;
}

const getDataPoint = (dashboardCard: DashboardCard): PizzaChartDataPoint[] => {
  const { percentage, value } = dashboardCard;

  return [
    {
      value,
      label: Math.random().toString(),
      color: '#18E3CF',
    },
    {
      value: ((100 - percentage) * value) / (percentage),
      label: '',
      color: 'transparent',
    },
  ];
};

const defaultData = {
  income_fines: initialDashboardCard,
  income_insurance: initialDashboardCard,
  income_transport: initialDashboardCard,
  income_vagoo: initialDashboardCard,
};

export const RevenuePizzaCharts = ({ data }: IRevenuePizzaChartsProps) => {
  const {
    income_fines,
    income_insurance,
    income_transport,
    income_vagoo,
  } = data || defaultData;

  return (
    <RevenueChartsContainer>
      <PizzaChart data={getDataPoint(income_transport)} type="freight" />
      <PizzaChart data={getDataPoint(income_fines)} type="penalty" />
      <PizzaChart data={getDataPoint(income_insurance)} type="secure" />
      <PizzaChart
        data={getDataPoint(income_vagoo)}
        type="vagoo"
        color="purple"
        info={`Receita Vagoo x Total (%): ${income_vagoo.percentage.toLocaleString('pt-BR', {
          maximumFractionDigits: 0,
        })}%`}
      />
    </RevenueChartsContainer>
  );
};
