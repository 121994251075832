import styled from 'styled-components';

export const BaseSelectContainer = styled.select`
  width: 100%;
  height: 2.375rem;
  padding: 0 1rem;

  border-radius: 0.625rem;
  border: 1px solid var(--lightGray);

  color: var(--gray);
  font-size: 1rem;
  transition: border-color 0.2s ease;

  &:not(:disabled):focus {
    border-color: var(--lightGreen);
  }

  &:disabled {
    background-color: var(--gray);
  }
`;
