import { LabelHTMLAttributes } from 'react';

import { BaseLabelContainer } from './styles';

export const BaseLabel = (props: LabelHTMLAttributes<HTMLLabelElement>) => {
  const { children } = props;

  return (
    <BaseLabelContainer {...props}>
      {children}
    </BaseLabelContainer>
  );
};
