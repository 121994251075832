import {
  DashboardCard, initialDashboardCard, TripNumberSection,
} from '../../../pages/Dashboard/reducer';
import { PizzaChart } from '../../molecules/PizzaChart';
import { DeliveriesChartsContainer } from './styles';

interface IDeliveriesPizzaChartsProps {
  // eslint-disable-next-line react/require-default-props
  data?: TripNumberSection;
}

interface PizzaChartDataPoint {
  label: string;
  value: number;
  color: string;
}

const getDataPoint = (dashboardCard: DashboardCard): PizzaChartDataPoint[] => {
  const { percentage: percentageStr, value: valueStr } = dashboardCard;

  const percentage = Number(percentageStr);
  const value = Number(valueStr);

  return [
    {
      value,
      label: Math.random().toString(),
      color: '#29235C',
    },
    {
      value: ((100 - percentage) * value) / (percentage),
      label: '',
      color: 'transparent',
    },
  ];
};

const defaultData = {
  canceled_count: initialDashboardCard,
  finished_count: initialDashboardCard,
  fines_count: initialDashboardCard,
  insured_count: initialDashboardCard,
};

export const DeliveriesPizzaCharts = ({ data }: IDeliveriesPizzaChartsProps) => {
  const {
    canceled_count,
    fines_count,
    finished_count,
    insured_count,
  } = data || defaultData;

  return (
    <DeliveriesChartsContainer>
      <PizzaChart
        data={getDataPoint(finished_count)}
        type="finished"
        unit={Number(finished_count.value) === 1 ? 'viagem' : 'viagens'}
        info={`${finished_count.percentage}% das viagens totais`}
      />
      <PizzaChart
        data={getDataPoint(fines_count)}
        type="fined_delivery"
        unit={Number(fines_count.value) === 1 ? 'viagem' : 'viagens'}
        info={`${fines_count.percentage}% das viagens canceladas`}
      />
      <PizzaChart
        data={getDataPoint(insured_count)}
        type="secure_delivery"
        unit={Number(insured_count.value) === 1 ? 'viagem' : 'viagens'}
        info={`${insured_count.percentage}% das viagens realizadas`}
      />
      <PizzaChart
        data={getDataPoint(canceled_count)}
        color="red"
        type="cancelled"
        unit={Number(canceled_count.value) === 1 ? 'viagem' : 'viagens'}
        info={`${canceled_count.percentage}% das viagens totais`}
      />
    </DeliveriesChartsContainer>
  );
};
