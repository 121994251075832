import { ChangeEvent, useEffect, useState } from 'react';
import toast from 'react-hot-toast';
import { PageTitle } from '../../components/atoms/PageTitle';
import { SubmitButton } from '../../components/atoms/SubmitButton';
import { api } from '../../services/api';

import {
  ButtonContainer,
  PaPContainer,
  SelectContainer,
  SelectLabel,
  TermsCard,
  TermsTextarea,
  TermsTitle,
} from './styles';
import { BaseSelect } from '../../components/atoms/BaseSelect';

export const PoliticsAndPrivacy = () => {
  const [transporterTerms, setTransporterTerms] = useState('');
  const [shipperTerms, setShipperTerms] = useState('');
  const [selectedRole, setSelectedRole] = useState('transporter');

  const handleSubmit = async () => {
    try {
      await api.put('/system-infos', {
        ...(selectedRole === 'transporter' && { terms_and_conditions: transporterTerms }),
        ...(selectedRole === 'shipper' && { terms_and_conditions_shipper: shipperTerms }),
      });

      ensurePolitics();

      toast.success('Políticas de Privacidade atualizadas com sucesso!');
    } catch (error: any) {
      toast.error(error?.data?.message || error?.message);
    }
  };

  const onChange = (event: ChangeEvent<HTMLTextAreaElement>) => {
    const { value } = event.target;

    if (selectedRole === 'transporter') {
      setTransporterTerms(value);
    } else {
      setShipperTerms(value);
    }
  };

  const ensurePolitics = async () => {
    try {
      const response = await api.get('/system-infos');
      setTransporterTerms(response?.data?.system_infos?.terms_and_conditions || '');
      setShipperTerms(response?.data?.system_infos?.terms_and_conditions_shipper || '');
    } catch (error: any) {
      toast.error(error?.data?.message || error?.message);
    }
  };

  useEffect(() => {
    ensurePolitics();
  }, []);

  const textareaValue = selectedRole === 'transporter' ? transporterTerms : shipperTerms;

  return (
    <PaPContainer>
      <PageTitle>Política de Privacidade</PageTitle>
      <SelectContainer>
        <SelectLabel>
          Tipo de usuário:
        </SelectLabel>
        <BaseSelect
          options={[
            {
              value: 'transporter',
              label: 'Transportador',
            },
            {
              value: 'shipper',
              label: 'Embarcador',
            },
          ]}
          value={selectedRole}
          onChange={(e) => {
            setSelectedRole(e.target.value);
          }}
        />
      </SelectContainer>

      <TermsCard>
        <TermsTitle>Termos Obrigatórios {selectedRole === 'transporter' ? 'Transportador' : 'Embarcador'}</TermsTitle>
        <TermsTextarea value={textareaValue} onChange={onChange} />
      </TermsCard>
      <ButtonContainer>
        <SubmitButton
          className="pap__button"
          type="submit"
          onClick={handleSubmit}
        >
          Salvar
        </SubmitButton>
      </ButtonContainer>
    </PaPContainer>
  );
};
