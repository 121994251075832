import { PageTitle } from '../../components/atoms/PageTitle';
import { EditVehicleTypeForm } from '../../components/organisms/EditVehicleTypeForm';

import { EditVehicleTypeContainer } from './styles';

export const EditVehicleType = () => (
  <EditVehicleTypeContainer>
    <PageTitle>
      Editar Tipo de Veículo
    </PageTitle>
    <EditVehicleTypeForm />
  </EditVehicleTypeContainer>
);
