import { BaseInput } from '../../atoms/BaseInput';
import { SubmitButton } from '../../atoms/SubmitButton';

import { ISignInFormProps } from './interfaces';
import {
  CheckBox, CheckContainer,
  CheckLabel, Form,
  FormWrapper, WarningText,
} from './styles';

export const SignInForm = ({
  register, onSubmit, isSubmitting, errors,
}: ISignInFormProps) => (
  <Form onSubmit={onSubmit}>
    <FormWrapper>
      <BaseInput
        type="email"
        placeholder="E-mail"
        lined
        autoComplete="sign-in-vagoo email"
        error={!!errors?.email}
        {...register('email', { required: true })}
      />
      <WarningText>
        {errors?.email?.message}
      </WarningText>
      <BaseInput
        type="password"
        placeholder="Senha"
        className="sign-in-form__input"
        lined
        autoComplete="sign-in-vagoo password"
        error={!!errors?.password}
        {...register('password', { required: true })}
      />
      <WarningText>
        {errors?.password?.message}
      </WarningText>
      <SubmitButton
        type="submit"
        className="sign-in-form__button"
        loading={!!isSubmitting}
        large
      >
        Login
      </SubmitButton>
      <CheckContainer>
        <CheckBox
          type="checkbox"
          id="login-keep"
          className="sign-in-form__check-box"
          {...register('saveUser')}
        />
        <CheckLabel
          htmlFor="login-keep"
          className="sign-in-form__check-label"
        >
          Me mantenha logado
        </CheckLabel>
      </CheckContainer>
    </FormWrapper>
  </Form>
);
