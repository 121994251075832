import { forwardRef, useEffect, useState } from 'react';
import { io, Socket } from 'socket.io-client';
import useAuth from '../../../hooks/useAuth';
import { TripStatus } from '../../../pages/FollowDelivery/interfaces';

import { DeliveryChargeDetailsCard } from '../../molecules/DeliveryChargeDetailsCard';
import { DeliveryDetailsCard } from '../../molecules/DeliveryDetailsCard';
import { DeliveryTrackerCard } from '../../molecules/DeliveryTrackerCard';
import { DeliveryTransporterDetailsCard } from '../../molecules/DeliveryTransporterDetailsCard';
import { DeliveryValueDetailsCard } from '../../molecules/DeliveryValueDetailsCard';
import { FileContainerCard } from '../../molecules/FileContainerCard';
import { TrackerSelectCard } from '../../molecules/TrackerSelectCard';
import { DeliveryMap } from '../DeliveryMap';

import { IDeliveryDetailsModalProps } from './interfaces';
import {
  CloseButton,
  CloseIcon,
  DeliveryDetailsModalContainer,
  DetailsContainer,
  DetailsLined,
  DetailsTitle,
  DotsIcon,
  MapContainer,
  MapIcon,
  ModalBody,
  ModalHeader,
  Observations,
  ObservationText,
  TitleTrackerContainer,
  TrackerTitle,
} from './styles';

interface VehicleUpdateSocketEvent {
  vehicle_id: string;
  position: {
    latitude: number;
    longitude: number;
  }
}

const DeliveryDetailsModal = forwardRef<
  HTMLElement,
  IDeliveryDetailsModalProps
>(({ closeModal, delivery }, ref) => {
  const { accessToken } = useAuth();
  const [deliveryDate, setDeliveryDate] = useState<Date>();
  const [socket, setSocket] = useState<Socket>();
  const [vehicleGeo, setVehicleGeo] = useState<number[] | undefined>(() => {
    if (delivery.vehicle) {
      return delivery.vehicle.geolocation.coordinates;
    }
    return undefined;
  });

  const handleTimeToDeliveryChange = (seconds: number) => {
    if (seconds === 0) return;

    const now = new Date();
    const timeToDelivery = new Date(now.getTime() + seconds * 1000);

    setDeliveryDate(timeToDelivery);
  };

  useEffect(() => {
    if (!accessToken) {
      return () => {};
    }

    const API_URL = process.env.REACT_APP_API_URL || '';

    const s = io(API_URL, {
      transports: ['websocket', 'polling'],
      query: {
        token: accessToken,
      },
    });

    setSocket(s);

    return () => {
      s.disconnect();
    };
  }, [accessToken]);

  useEffect(() => {
    if (!socket) return () => {};

    socket.on('vehicle-updates', (e: VehicleUpdateSocketEvent) => {
      const { vehicle_id } = e;

      if (vehicle_id && vehicle_id === delivery.vehicle_id) {
        setVehicleGeo([
          e.position.longitude,
          e.position.latitude,
        ]);
      }
    });

    return () => {
      socket.off('vehicle-updates');
    };
  }, [socket]);

  return (
    <DeliveryDetailsModalContainer ref={ref}>
      <ModalHeader>
        <DotsIcon />
        <DetailsTitle>{delivery.order}</DetailsTitle>
        <DetailsLined>Informações da Carga</DetailsLined>
        <CloseButton type="button" onClick={closeModal}>
          <CloseIcon />
        </CloseButton>
      </ModalHeader>
      <ModalBody>
        <MapContainer>
          <TitleTrackerContainer>
            <MapIcon />
            <TrackerTitle>Trajeto</TrackerTitle>
          </TitleTrackerContainer>
          <DeliveryTrackerCard status={delivery.status_id} />
          <TrackerSelectCard statusMarks={delivery.status_marks} />
          <DeliveryMap
            destination={delivery.delivery_address.geolocation.coordinates}
            origin={vehicleGeo || [0, 0]}
            waypoint={delivery.initial_address.geolocation.coordinates}
            onTimeToDeliveryChange={handleTimeToDeliveryChange}
            isDelivering={delivery.status_id === TripStatus.OnGoing}
          />
          <FileContainerCard document={delivery.document} />
        </MapContainer>
        <DetailsContainer>
          <DeliveryDetailsCard delivery={delivery} deliveryDate={deliveryDate} />
          <DeliveryTransporterDetailsCard delivery={delivery} />
          <DeliveryChargeDetailsCard delivery={delivery} />
          <ObservationText>OBSERVAÇÕES</ObservationText>
          <Observations>{delivery.observation || '-'}</Observations>
          <DeliveryValueDetailsCard delivery={delivery} />
        </DetailsContainer>
      </ModalBody>
    </DeliveryDetailsModalContainer>
  );
});

export default DeliveryDetailsModal;
