import { FilterButton, FiltersContainer } from './styles';

export const defaultFilterSelectorOptions: FilterSelectorOption[] = [
  {
    label: 'Dia',
    value: 'day',
  },
  {
    label: 'Semana',
    value: 'week',
  },
  {
    label: 'Mês',
    value: 'month',
  },
  {
    label: 'Ano',
    value: 'year',
  },
];

export interface FilterSelectorOption {
  label: string;
  value: string;
}

interface IFiltersSelectorProps {
  value: string;
  onChange: (value: string) => void;
  options?: FilterSelectorOption[];
  customClassName?: string;
}

export const FiltersSelector = ({
  value,
  onChange,
  customClassName = '',
  options = defaultFilterSelectorOptions,
}: IFiltersSelectorProps) => (
  <FiltersContainer className={customClassName}>
    {options.map((option) => (
      <FilterButton
        key={option.value}
        active={option.value === value}
        onClick={() => onChange(option.value)}
      >
        {option.label}
      </FilterButton>
    ))}
  </FiltersContainer>
);

FiltersSelector.defaultProps = {
  options: defaultFilterSelectorOptions,
  customClassName: undefined,
};
