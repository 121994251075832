import styled from 'styled-components';

import { ReactComponent as PhoneIconRaw } from '../../../assets/images/follow-delivery/phone-icon.svg';
import { ReactComponent as TruckIconRaw } from '../../../assets/images/follow-delivery/truck-icon.svg';
import { ReactComponent as CargoIconRaw } from '../../../assets/images/follow-delivery/cargo-icon.svg';
import { ReactComponent as BoardIconRaw } from '../../../assets/images/follow-delivery/board-icon.svg';

export const TransporterDetails = styled.fieldset`
  width: 95%;
  height: fit-content;
  min-height: 10.12rem;
  margin: 1.0125rem auto 0;

  border-radius: 0.625rem;
  border: 2px solid var(--softPurple);
`;

export const TransporterHeader = styled.div`
  width: 100%;
  height: fit-content;

  display: flex;

  border-bottom: 2px solid var(--softPurple);
`;

export const TransporterDataContainer = styled.div`
  flex: 13.3875;
  height: fit-content;
  min-height: 3.84375rem;
  padding: 0.8125rem 0 0.475rem;

  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 0.2rem;

  border-right: 2px solid var(--softPurple);
`;

export const TransporterName = styled.h1`
  width: 80%;
  height: fit-content;
  margin: 0 auto;

  display: inline-block;

  color: var(--darkPurple);
  font-size: 1rem;
  font-weight: bold;
  word-wrap: break-word;
`;

export const DriverName = styled.h2`
  width: 80%;
  height: fit-content;

  color: var(--darkPurple);
  font-size: 0.75rem;
`;

export const TransporterPhonesContainer = styled.div`
  flex: 9.6125;
  height: fit-content;
  min-height: 3.84375rem;
  padding: 0.8125rem 0 0.475rem;
  margin-left: -2px;

  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 0.2rem;

  border-left: 2px solid var(--softPurple);
`;

export const PhoneDiv = styled.div`
  width: 80%;
  height: fit-content;
`;

export const IconContainer = styled.span`
  margin-right: 0.4rem;

  vertical-align: middle;

  color: var(--softBlue);
  font-size: 0.6rem;
`;

export const PhoneIcon = styled(PhoneIconRaw)`
  width: 0.94375rem;
  height: 0.94375rem;
  margin-right: 0.1rem;
`;

export const PhoneNumber = styled.h2`
  display: inline-block;
  vertical-align: middle;

  color: var(--darkPurple);
  font-size: 0.75rem;
  font-weight: bold;
  word-wrap: break-word;
`;

export const TruckIcon = styled(TruckIconRaw)`
  width: 1.168125rem;
  height: 0.934375rem;
`;

export const CargoIcon = styled(CargoIconRaw)`
  width: 1.295625rem;
  height: 0.996875rem;
`;

export const BoardIcon = styled(BoardIconRaw)`
  width: 1.5rem;
  height: 0.875rem;
`;

export const TruckDetailsContainer = styled.div`
  width: 90%;
  margin: 0 auto;
  padding: 0.875rem 0 1.34375rem;

  display: flex;
  justify-content: space-between;
  gap: 0.5rem;
`;

export const TruckLabel = styled.h2`
  color: var(--darkPurple);
  font-size: 0.75rem;
  word-wrap: break-word;
`;

export const TruckValue = styled.h2`
  color: var(--darkPurple);
  font-size: 1rem;
  font-weight: bold;
  word-wrap: break-word;
`;
