import styled from 'styled-components';

export const ListDeliveriesContainer = styled.ul`
  width: 100%;
  height: fit-content;
  margin-top: 2rem;
  padding: 0 0.5rem 1rem;

  display: flex;
  flex-direction: column;
  align-items: center;

  overflow: visible;

  list-style-type: none;

  @media (min-width: 860px) {
    flex-direction: row;
    flex-wrap: wrap;
    gap: 10%;
    justify-content: space-between;
  }
`;

export const DeliveryItem = styled.li`
  flex: 100%;

  @media (min-width: 860px) {
    flex: 40%;
  }
`;

export const EmptyMessage = styled.h1`
  margin-top: 1.0625rem;

  display: inline-block;

  font-size: 1.2rem;
  color: var(--darkPurple)
`;
