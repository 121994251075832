import * as yup from 'yup';

export const schema = yup
  .object({
    /*  vehicle_class_id: yup
    .string()
    .required('Tipo de veículo obrigatório!'), */
    name: yup.string().required('Tipo de veículo obrigatório!'),
    bodywork_type_id: yup.string().optional(),
    axis_number: yup
      .number()
      .typeError('Total de eixos deve ser numérico')
      .required('Total de eixos obrigatório!'),
    cargo_weight_id: yup.number().optional().typeError(''),
    cargo_volume_id: yup.number().optional().typeError(''),
    percentage_per_cte: yup
      .number()
      .typeError('Taxa de CTE deve ser numérico')
      .required('Taxa de CTE obrigatório!'),
    /* minimal_time_for_cancelation: yup
    .number()
    .typeError('Tempo máximo para cancelamento deve ser numérico')
    .required('Tempo máximo para cancelamento obrigatório!'), */
    shipper_cancelation_fee: yup
      .number()
      .typeError('Taxa de cancelamento do remetente deve ser numérico')
      .required('Taxa de cancelamento do remetente obrigatório!'),
    transporter_cancelation_fee: yup
      .number()
      .typeError('Taxa de cancelamento do transportador deve ser numérico')
      .required('Taxa de cancelamento do transportador obrigatório!'),
    insurance_percentage: yup
      .number()
      .typeError('A porcentagem do seguro deve ser um valor numérico')
      .required('A porcentagem do seguro é obrigatória!'),
    /* minimal_radius: yup
    .number()
    .typeError('Raio máximo deve ser numérico')
    .required('Raio máximo obrigatório!'), */
    fee: yup
      .number()
      .typeError('Valor do km rodado deve ser numérico')
      .required('Valor do km rodado é obrigatório!'),
    fixed_fee: yup
      .number()
      .typeError('Valor fixo deve ser numérico')
      .required('Valor fixo da viagem é obrigatório!'),
    transporter_percentage: yup
      .number()
      .typeError('A porcentagem do transportador deve ser um valor numérico')
      .min(0, 'A porcentagem do transportador deve ser maior que zero')
      .max(100, 'A porcentagem do transportador deve ser menor que 100')
      .required('A porcentagem do transportador é obrigatória!'),
  })
  .required();
