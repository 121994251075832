import { SubmitHandler, useForm } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';
import toast from 'react-hot-toast';
import { useNavigate } from 'react-router';
import useAuth from '../../hooks/useAuth';

import { SignInForm } from '../../components/molecules/SignInForm/SignInForm';

import BgGradient from '../../assets/images/signIn/sign-in-bg.png';

import { schema } from './validation';

import { ISignInFormData } from './interfaces';
import {
  BackGroundGradient, FormContainer,
  LogoContainer, SignInContainer,
  SignInIcons, SignInInfo, SignInLogo,
} from './styles';

export const SignIn = () => {
  const navigate = useNavigate();

  const { handleSignIn } = useAuth();

  const {
    register, handleSubmit, formState: { isSubmitting, errors },
  } = useForm<ISignInFormData>({
    resolver: yupResolver(schema),
  });

  const onSubmit: SubmitHandler<ISignInFormData> = async ({
    email, password, saveUser,
  }) => {
    try {
      await handleSignIn({
        email, password, saveUser,
      });

      navigate('/');
    } catch (error: any) {
      toast.error(error?.response?.data?.message || error?.message);
    }
  };

  return (
    <SignInContainer>
      <BackGroundGradient
        src={BgGradient}
        alt="Background gradient"
      />
      <LogoContainer>
        <SignInIcons />
        <SignInInfo>
          Área do Administrador
        </SignInInfo>
        <SignInLogo />
      </LogoContainer>
      <FormContainer>
        <SignInForm
          onSubmit={handleSubmit(onSubmit)}
          {...{ register, isSubmitting, errors }}
        />
      </FormContainer>
    </SignInContainer>
  );
};
