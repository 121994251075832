import styled from 'styled-components';
import { NavLink } from 'react-router-dom';

import { ReactComponent as HeaderLogoRaw } from '../../../assets/images/header/header-logo.svg';

export const HeaderContainer = styled.header`
  width: 100%;
  height: 4.546875rem;

  position: relative;
  z-index: 20;

  background-color: #fff;
  box-shadow: 10px 10px 20px rgba(0, 0, 0, 0.16);
`;

export const HeaderWrapper = styled.div`
  width: 98%;
  max-width: 71.745rem;
  height: 100%;
  margin: 0 auto;

  display: flex;
  align-items: center;
  justify-content: space-between;
`;

export const ButtonsContainer = styled.span`
  width: 6.5rem;

  @media (min-width: 296px){
    width: fit-content;
  }
`;

export const LinkButton = styled(NavLink)`
  width: 5.8rem;
  height: 2rem;
  margin-left: 0.5rem;

  display: inline-block;

  background-color: var(--darkPurple);
  border-radius: 0.625rem;
  transition: filter 0.2s ease;

  &:hover {
    filter: brightness(1.2);
  }

  @media (min-width: 296px){
    margin-left: unset;
  }

  @media (min-width: 478px) {
    width: 8.015625rem;
    height: 2.25rem;
  }

  @media (min-width: 768px) {
    width: 10.6875rem;
    height: 3rem;
  }
`;

export const ButtonText = styled.h1`
  color: #fff;
  line-height: 2rem;
  font-size: 0.54625rem;
  font-weight: bold;
  text-align: center;

  @media (min-width: 478px) {
    line-height: 2.25rem;
    font-size: 0.65625rem;
  }

  @media (min-width: 768px) {
    line-height: 3rem;
    font-size: 0.875rem;
  }
`;

export const LinedLink = styled(NavLink)`
  width: fit-content;
  margin-left: 0.5rem;

  display: inline-block;

  color: #0A76AE;
  font-size: 0.8rem;
  font-weight: bold;

  @media (min-width: 296px){
    margin-left: 0.8rem;
  }

  @media (min-width: 478px) {
    margin-left: 1.25rem;

    font-size: 0.875rem;
  }
`;

export const Logo = styled(HeaderLogoRaw)`
  width: 5rem;
  aspect-ratio: 7.97 / 2.061875;

  @media (min-width: 478px) {
    width: 7.97rem;
    height: 2.061875rem;
  }
`;
