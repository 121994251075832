import { forwardRef, SelectHTMLAttributes } from 'react';

import { BaseSelectContainer } from './styles';

interface IBaseSelectProps extends SelectHTMLAttributes<HTMLSelectElement> {
  options: {
    value?: string;
    label: string;
  }[];
}

export const BaseSelect = forwardRef<
  HTMLSelectElement, IBaseSelectProps
>((props, ref) => {
  const { options } = props;

  return (
    <BaseSelectContainer ref={ref} {...props}>
      {options?.map((option) => (
        <option
          key={option?.value}
          value={option?.value}
        >
          {option?.label}
        </option>
      ))}
    </BaseSelectContainer>
  );
});
