import ReactDOM from 'react-dom';
import {
  cloneElement,
  Dispatch, ReactElement, SetStateAction, useRef, useState,
} from 'react';
import { useOutside } from '../../../hooks/useOutside';
import { ModalPortal } from './styles';

interface IModalRootProps {
  children: ReactElement;
  setShowModal: Dispatch<SetStateAction<boolean>>;
  callback?: Function;
}

export const ModalRoot = ({
  children, setShowModal, callback,
}: IModalRootProps) => {
  const modalRoot = document.getElementById('modal-root');

  const modalContainer = useRef(null);
  const [closeModal, setCloseModal] = useState(false);

  const handleCloseModal = () => {
    setCloseModal(true);

    setTimeout(() => {
      setShowModal(false);

      if (callback) {
        callback();
      }
    }, 300);
  };

  if (!modalRoot) {
    return null;
  }

  useOutside(modalContainer, handleCloseModal);

  return ReactDOM.createPortal(
    <ModalPortal close={closeModal}>
      {cloneElement(children, { ref: modalContainer, closeModal: handleCloseModal })}
    </ModalPortal>,
    modalRoot,
  );
};
