import { ReactNode } from 'react';

import { Title } from './styles';

interface IPageTitleProps {
  children: ReactNode
}

export const PageTitle = ({ children }: IPageTitleProps) => (
  <Title>
    {children}
  </Title>
);
