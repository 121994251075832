import styled from 'styled-components';

export const DeliveryDetails = styled.div`
  width: fit-content;
  padding: 0.5rem;
  margin: 1.3125rem auto 0;
  background-color: #EFFEFF;
  border-radius: 0.625rem;

  @media (min-width: 605px) {
    width: 95%;
    padding: 0.75rem 1rem;
  }
`;

export const DetailLabel = styled.h1`
  margin-right: 0.5rem;

  display: inline-block;

  color: var(--darkPurple);
  font-size: 0.75rem;
  font-weight: bold;
`;

export const DetailValue = styled.h1`
  display: inline-block;

  color: var(--darkPurple);
  font-size: 0.75rem;
  font-weight: normal;
`;

export const ValueTitle = styled.h1`
  margin-top: 0.6125rem;

  display: inline-block;

  color: var(--darkPurple);
  font-size: 1rem;
  font-weight: bold;
`;

export const DeliveryValuesContainer = styled.div`
  width: 100%;
  margin-top: 0.4rem;
  padding: 0 1rem 0.4rem;

  display: none;
  justify-content: space-between;

  border-bottom: 2px solid #2CCCD3;

  @media (min-width: 605px) {
    display: flex;
  }
`;

export const ValueLabel = styled.h2`
  color: var(--darkPurple);
  font-size: 0.625rem;
  font-weight: normal;
`;

export const ValueAmount = styled.h1`
  color: var(--darkPurple);
  font-size: 0.875rem;
  font-weight: bold;
`;

export const TotalValueContainer = styled.div`
  width: 90%;
  margin: 0.5rem auto 0;

  @media (min-width: 605px) {
    margin: 0.8rem auto 0;
  }
`;

export const TotalValueLabel = styled.h2`
  color: var(--darkPurple);
  font-size: 0.75rem;
`;

export const TotalValueAmount = styled.h1`
  color: var(--darkPurple);
  font-size: 1rem;
  font-weight: bold;
`;
