import toast from 'react-hot-toast';
import { DownloadFile } from '../../../utils/DownloadFile';
import { TrackerTitle } from '../../organisms/DeliveryDetailsModal/styles';
import { IFileContainerCardProps } from './interfaces';
import {
  AnchorText, DownloadAnchor, DownloadIcon,
  FileIcon, FileTitle, FileWrapper, ImageIcon,
  TitleFileContainer, FileContainer, Container,
} from './styles';

const FileContainerCard = ({ document }: IFileContainerCardProps) => {
  const fileName = document?.document?.substring(0, 25) || '';

  const handleDownloadFile = async () => {
    try {
      await DownloadFile(document?.document_url as string, fileName);
    } catch (error: any) {
      toast.error(error?.response?.data?.message || error?.message || 'Algo deu errado, tente novamente mais tarde!');
    }
  };

  return (
    <Container>
      <TitleFileContainer>
        <FileIcon />
        <TrackerTitle>
          Comprovante de Entrega
        </TrackerTitle>
      </TitleFileContainer>
      <FileContainer>
        <FileWrapper>
          <ImageIcon />
          <FileTitle>
            {document?.document_url ? fileName : 'Não existem anexos'}
          </FileTitle>
        </FileWrapper>
        <DownloadAnchor type="button" onClick={handleDownloadFile} disabled={document == null}>
          <DownloadIcon />
          <AnchorText>
            Download
          </AnchorText>
        </DownloadAnchor>
      </FileContainer>
    </Container>
  );
};

export default FileContainerCard;
