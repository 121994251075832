import { TripStatus } from '../../../../pages/FollowDelivery/interfaces';

export const statusDef = {
  1: 'pendente',
  2: 'em deslocamento',
  3: 'coleta',
  4: 'em rota',
  5: 'aguardando pagamento',
  6: 'entregue',
  7: 'cancelado',
} as {
  [key in TripStatus]: string;
};
