import { Navigate } from 'react-router';
import jwt, { JwtPayload } from 'jsonwebtoken';

import useAuth from '../hooks/useAuth';

export default function PrivateRoute({ children }: any) {
  const { accessToken } = useAuth();

  const isSectionActive = () => {
    if (!accessToken) {
      return false;
    }

    const tokenPayLoad = jwt.decode(accessToken) as JwtPayload;

    if (tokenPayLoad === null) {
      return false;
    }

    const { iat } = tokenPayLoad;

    if (!iat) {
      return false;
    }

    if (iat <= 0) {
      return false;
    }

    return true;
  };

  return isSectionActive() ? children : <Navigate to="/signIn" />;
}
